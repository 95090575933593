import React, { useContext, useEffect, useState } from "react";
import { GlobalState } from "../../Data/Context";
import { Container } from "reactstrap";
import { MainTransFinder } from "../wallets/find-transactions";

const UserRegistration = () => {
  let { setStateName, stat, getDataTransactionStat } = useContext(GlobalState);
  useEffect(() => {
    setStateName("User Registration Stat");
    getDataTransactionStat(null, "reg");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let [daily, setDaily] = useState(null),
    [weekly, setWeekly] = useState(null),
    [monthly, setMonthly] = useState(null);

  useEffect(() => {
    setDaily(stat?.registration?.daily);
    setWeekly(stat?.registration?.weekly);
    setMonthly(stat?.registration?.monthly);
  }, [stat?.registration]);

  return (
    <div className="bg-white aboutScreen">
      <Container className="py-3 py-md-5">
        <MainTransFinder
          weekly={weekly}
          daily={daily}
          monthly={monthly}
          notype
          dataEnd
          hideData={"user"}
        />
      </Container>{" "}
    </div>
  );
};

export default UserRegistration;
