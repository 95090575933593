import React, { useContext, useState, useEffect } from "react";
import { Container } from "reactstrap";
import { MainPaginate, MainRanger } from "../../../Components/Transactions";
import { BiCopy, BiDotsHorizontalRounded } from "react-icons/bi";
import moment from "moment";
import { GlobalState } from "../../../Data/Context";
import LoadMore, { BottomTab } from "../../../Components/LoadMore";
import { ModalComponents } from "../../../Components";
// import { EmptyComponent } from "../../../Utils";
// import { RiShieldStarFill } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
// import { colorArr } from "../../../Components/Wallets";
import { toast } from "react-toastify";
import ExportButtons from "../../../Utils/Export Functions/ExportButtons";

const Transactions = () => {
  let [thisData, setThisData] = useState(null),
    { manageCGWalletHistory, auth, permission } = useContext(GlobalState),
    navigate = useNavigate();

  useEffect(() => {
    if (auth?.user?.privilege !== "superadmin") {
      let findTitle = "viewcgwallethistory";

      let findPermit = permission?.data?.find(
        (item) =>
          item?.slug === findTitle && item?.user?.includes(auth?.user?._id)
      );
      if (!findPermit) {
        navigate(-1);
      }
    }
  }, [auth?.user, permission?.data, navigate]);

  useEffect(() => {
    manageCGWalletHistory("debit");
    manageCGWalletHistory("credit");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-white aboutScreen">
      <Container className="py-3 py-md-5">
        <TransferList setThisData={setThisData} />
        <WalletDetails thisData={thisData} setThisData={setThisData} />
      </Container>
    </div>
  );
};

export default Transactions;

export const TransferList = ({ setThisData }) => {
  const { cgwalletHistory, manageCGWalletHistory, getReload, setStateName } =
    useContext(GlobalState);
  let [loading, setLoading] = useState(false),
    [search, setSearch] = useState(""),
    [state, setState] = useState(null),
    { step } = useParams();

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await manageCGWalletHistory(step, {
          search,
        });
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, step]);

  useEffect(() => {
    if (cgwalletHistory.isFound) {
      setState(cgwalletHistory.mainSearch);
    } else
      setState(
        step === "credit" ? cgwalletHistory.purchase : cgwalletHistory?.usage
      );
  }, [
    cgwalletHistory.purchase,
    cgwalletHistory.isFound,
    cgwalletHistory.mainSearch,
    step,
    cgwalletHistory?.usage,
  ]);

  useEffect(() => {
    getReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    setStateName(
      `CG ${step === "credit" ? "purchase" : "usage"} Wallet History`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  let handleLoadMore = async () => {
    setLoading(true);

    if (search) {
      await manageCGWalletHistory(step, {
        limit: Number(
          cgwalletHistory?.[
            `${step === "credit" ? "purchase" : "usage"}_paginate`
          ]?.nextPage *
            cgwalletHistory?.[
              `${step === "credit" ? "purchase" : "usage"}_paginate`
            ]?.limit
        ),
        search,
      });
    } else {
      await manageCGWalletHistory(step, {
        limit: Number(
          cgwalletHistory?.[
            `${step === "credit" ? "purchase" : "usage"}_paginate`
          ]?.nextPage *
            cgwalletHistory?.[
              `${step === "credit" ? "purchase" : "usage"}_paginate`
            ]?.limit
        ),
      });
    }
    setLoading(false);
  };

  return (
    <>
      <HistoryData
        search={search}
        setSearch={setSearch}
        setThisData={setThisData}
        state={state}
      />
      <BottomTab
        state={state}
        paginate={
          search
            ? cgwalletHistory?.search_paginate
            : cgwalletHistory?.[
                `${step === "credit" ? "purchase" : "usage"}_paginate`
              ]
        }
      />
      <LoadMore
        next={
          search
            ? cgwalletHistory?.search_paginate?.next
            : cgwalletHistory?.[
                `${step === "credit" ? "purchase" : "usage"}_paginate`
              ]?.next
        }
        handleLoadMore={handleLoadMore}
        loading={loading}
      />
    </>
  );
};

export const WalletDetails = ({ thisData, setThisData }) => {
  let { numberWithCommas, auth, nairaSign } = useContext(GlobalState);
  return (
    <>
      <ModalComponents
        isOpen={thisData ? true : false}
        toggle={() => setThisData(false)}
        title="Wallet details"
      >
        <div className="downH2 d-flex flex-column">
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>Id: </span>
            <span
              className="fontInherit Lexend force-d-flex myCursor"
              onClick={
                thisData?.item_id
                  ? () => {
                      navigator.clipboard.writeText(thisData?.item_id).then(
                        () => {
                          toast.info("Copied", { autoClose: 2000 });
                        },
                        (err) => {
                          toast.warn(`Could not copy: ${err}`, {
                            autoClose: 2000,
                          });
                        }
                      );
                    }
                  : null
              }
            >
              {thisData?.item_id}
              <BiCopy />
            </span>{" "}
          </p>
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>type: </span>
            <span
              className={`fontInherit Lexend ${
                thisData?.type === "credit"
                  ? "text-success2 text-success-2 text-success"
                  : "text-danger2"
              }`}
            >
              {thisData?.type}
            </span>{" "}
          </p>
          {thisData?.channel && thisData?.channel !== "auth" && (
            <p className="text-capitalize border-bottom d-flex justify-content-between">
              <span>Channel: </span>
              <span className="fontInherit Lexend">
                {thisData?.channel}
              </span>{" "}
            </p>
          )}
          {auth?.user?.isAdmin && (
            <p className="border-bottom d-flex justify-content-between">
              <span className="text-capitalize">
                User{thisData?.user?.initEmail && <>(Deleted)</>}:{" "}
              </span>
              <span>
                <span className="fontInherit Lexend d-block text-capitalize">
                  {thisData?.user?.lastName} {thisData?.user?.firstName}
                </span>{" "}
                <span className="fontInherit Lexend d-block">
                  {thisData?.user?.initTelephone || thisData?.user?.telephone}
                </span>{" "}
                <span className="fontInherit Lexend d-block">
                  {thisData?.user?.initEmail || thisData?.user?.email}
                </span>{" "}
                {thisData?.user?.privilege && (
                  <span className="fontInherit Lexend d-block tw-uppercase">
                    {thisData?.user?.privilege}
                  </span>
                )}
              </span>
            </p>
          )}
          {thisData?.type === "credit" && (
            <p className="text-capitalize border-bottom d-flex justify-content-between">
              <span>Amount: </span>
              <span className="fontInherit Lexend">
                {nairaSign}{" "}
                {thisData?.price
                  ? numberWithCommas(Number(thisData?.price).toFixed(2))
                  : 0}
              </span>{" "}
            </p>
          )}
          {thisData?.type === "debit" &&
            thisData?.transaction?.properties?.phone && (
              <p className="text-capitalize border-bottom d-flex justify-content-between">
                <span>recipient: </span>
                <span
                  className="fontInherit Lexend force-d-flex myCursor"
                  onClick={
                    thisData?.transaction?.properties
                      ? () => {
                          navigator.clipboard
                            .writeText(thisData?.transaction?.properties?.phone)
                            .then(
                              () => {
                                toast.info("Copied", { autoClose: 2000 });
                              },
                              (err) => {
                                toast.warn(`Could not copy: ${err}`, {
                                  autoClose: 2000,
                                });
                              }
                            );
                        }
                      : null
                  }
                >
                  {thisData?.transaction?.properties?.phone}
                  <BiCopy />
                </span>{" "}
              </p>
            )}
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>Volume: </span>
            <span className="fontInherit Lexend">
              {thisData?.amount
                ? numberWithCommas(Number(thisData?.amount).toFixed(2))
                : 0}{" "}
              GB
            </span>{" "}
          </p>
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>{thisData?.status ? "Previous " : "Initial "} Balance: </span>
            <span className="fontInherit Lexend">
              {thisData?.prevBalance
                ? numberWithCommas(Number(thisData?.prevBalance).toFixed(2))
                : 0}{" "}
              GB
            </span>{" "}
          </p>
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>{thisData?.status ? "Current " : "Expected "}Balance: </span>
            <span className="fontInherit Lexend">
              {thisData?.balance
                ? numberWithCommas(Number(thisData?.balance).toFixed(2))
                : 0}{" "}
              GB
            </span>{" "}
          </p>
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>Description: </span>
            <span className="fontInherit Lexend">
              {thisData?.description}
            </span>{" "}
          </p>
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>date&time: </span>
            <span className="fontInherit Lexend">
              {moment(thisData?.createdAt).format("DD/MM/YYYY hh:mm A")}
            </span>{" "}
          </p>
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>Status: </span>
            <span
              className={`fontInherit Lexend ${
                thisData?.status
                  ? "text-success2 text-success-2 text-success"
                  : "text-danger2"
              }`}
            >
              {thisData?.statusText}
            </span>{" "}
          </p>
          {auth?.user?.isAdmin && thisData?.authorizer && (
            <>
              <p className="border-bottom d-flex justify-content-between">
                <span className="text-capitalize">Admin: </span>
                <span>
                  <span className="fontInherit Lexend d-block text-capitalize">
                    {thisData?.authorizer?.lastName}{" "}
                    {thisData?.authorizer?.firstName}
                  </span>{" "}
                  <span className="fontInherit Lexend d-block">
                    {thisData?.authorizer?.telephone}
                  </span>{" "}
                  <span className="fontInherit Lexend d-block">
                    {thisData?.authorizer?.email}
                  </span>{" "}
                  {thisData?.authorizer?.privilege && (
                    <span className="fontInherit Lexend d-block tw-uppercase">
                      {thisData?.authorizer?.privilege}
                    </span>
                  )}
                </span>
              </p>
            </>
          )}
          <div className="ms-auto d-flex align-items-center">
            <button
              className="btn btn-danger-2 btn-danger2 d-block ms-auto me-2"
              onClick={() => setThisData(false)}
            >
              Close
            </button>
          </div>
        </div>
      </ModalComponents>
    </>
  );
};

export const HistoryData = ({ state, search, setSearch, setThisData }) => {
  const { numberWithCommas } = useContext(GlobalState);

  let [range, setRange] = useState(10);
  const params = useParams();

  const [itemOffset, setItemOffset] = useState(0);
  if (!state) return;

  const endOffset = itemOffset + range;

  const currentItems = state.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(state.length / range);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * range) % state.length;
    setItemOffset(newOffset);
  };
  return (
    <>
      <div className="w-50 w50 mb-3">
        <input
          type="search"
          name="search"
          id="Search"
          className="form-control w-100 py-3 borderColor2"
          placeholder="Type here to search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="tw-relative tw-overflow-x-auto tw-overflow-y-visible tw-min-h-52">
        <div className="tw-flex tw-justify-between tw-items-center">
          <div className="tw-flex-1">
            <MainRanger range={range} setRange={setRange} />
          </div>
          <ExportButtons
            tableId="mch-credit"
            currentItems={currentItems}
            fileName={`${params?.page} ${params?.id ? `(${params?.id})` : ""}`}
          />
        </div>
        <table id="mch-credit" className="tw-w-full tw-text-sm tw-text-left">
          <thead className="tw-text-xs tw-text-gray-700 tw-captalize bland tw-px-1">
            <tr>
              <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-uppercase">
                S/N
              </th>
              {/* <th
								scope="col"
								className="tw-px-6 tw-py-6 Lexend tw-uppercase"></th> */}
              <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-uppercase">
                description
              </th>
              <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-uppercase">
                amount
              </th>
              <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-uppercase">
                previous amount
              </th>
              <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-uppercase">
                balance
              </th>
              <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-uppercase">
                date&time
              </th>
              <th
                scope="col"
                className="tw-px-6 tw-py-6 Lexend tw-uppercase"
              ></th>
            </tr>
          </thead>
          <tbody>
            {currentItems?.map((it, i) => (
              <tr
                onClick={() => setThisData(it)}
                className={`bg-white tw-border-b tw-text-xs px-1 py-3 myCursor`}
                key={i}
              >
                <td className="tw-w-4 tw-p-4">{i + 1}</td>
                {/* <td className="tw-px-6 tw-py-6">
									<div className="d-flex">
										<div
											className="p-3 d-flex rounded10 align-items-center justify-content-center"
											style={{
												background: `${colorArr[i % colorArr?.length]}`,
											}}>
											<RiShieldStarFill
												size={24}
												color={`${
													colorArr[i % colorArr?.length] === "#000000"
														? "#fff"
														: "#000"
												}`}
											/>
										</div>
									</div>
								</td> */}
                <td className="tw-px-6 tw-py-6">{it?.description}</td>
                <td className="tw-px-6 tw-py-6">
                  {it?.amount
                    ? numberWithCommas(Number(it?.amount).toFixed(2))
                    : 0}{" "}
                  GB
                </td>
                <td className="tw-px-6 tw-py-6 tw-uppercase">
                  {it?.prevBalance
                    ? numberWithCommas(Number(it?.prevBalance).toFixed(2))
                    : 0}{" "}
                  GB
                </td>
                <td className="tw-px-6 tw-py-6">
                  {it?.balance
                    ? numberWithCommas(Number(it?.balance).toFixed(2))
                    : 0}{" "}
                  GB
                </td>
                <td className="tw-px-6 tw-py-6">
                  {moment(it?.createdAt).format("DD/MM/YYYY hh:mm A")}
                </td>
                <td className="tw-px-6 tw-py-6">
                  <div className="d-flex">
                    <div
                      className={`p-3 d-flex rounded10 align-items-center justify-content-center shadow2 myCursor horizHover ${
                        it?.type === "credit"
                          ? "list-group-item-success"
                          : "list-group-item-danger"
                      }`}
                    >
                      <BiDotsHorizontalRounded size={24} />
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <MainPaginate handlePageClick={handlePageClick} pageCount={pageCount} />
      </div>
    </>
  );
};
