import React, { useContext, useEffect } from "react";
import { Container } from "reactstrap";
import { GlobalState } from "../../Data/Context";
import { AdminDetails, PrivilegeTopper } from "../../Components/Users";

const Reseller = () => {
	let { setStateName,  } = useContext(GlobalState);
	useEffect(() => {
		setStateName("manage Reseller");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="bg-white aboutScreen">
			<Container className="py-3">
				<PrivilegeTopper privilege={"reseller"} />
			</Container>
			<Container className="py-5">
				<h4>Resellers</h4> <AdminDetails privilege="reseller" />
			</Container>{" "}
		</div>
	);
};

export default Reseller;
