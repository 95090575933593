import React, { useContext, useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Container } from "reactstrap";
import { GlobalState } from "../../../Data/Context";
import {
	TransactionDetails,
	NewPaginate,
} from "../../../Components/Transactions";
import { Loader } from "../../../Utils";

const UserPurchase = () => {
	const { allUsers, setStateName, manageUserActiveness, getReload } =
			useContext(GlobalState),
		[state, setState] = useState(null),
		params = useParams(),
		[getSearch] = useSearchParams(),
		[thisData, setThisData] = useState(null),
		[search, setSearch] = useState("");

	useEffect(() => {
		setStateName(
			`${
				getSearch?.get("name")
					? getSearch?.get("name")?.split("_")?.join(" ")
					: ""
			}'s purchase history`
		);
		getReload();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		let getUserDetails = async () => {
			await manageUserActiveness(params?.step, "purchase", "", "get");
		};
		getUserDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params?.step]);

	useEffect(() => {
		if (search) {
			document.getElementById("Search").addEventListener("search", () => {
				getReload();
			});
			let handleSubmit = async () => {
				if (!search) return;

				await manageUserActiveness(
					params?.step,
					"purchase",
					"",
					"get",
					null,
					search
				);
			};
			handleSubmit();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search]);

	useEffect(() => {
		if (allUsers.user_isFound) {
			setState(allUsers.user_mainSearch);
		} else if (allUsers?.isFoundPurchase) setState(allUsers?.purchaseHistory);
	}, [
		allUsers?.purchaseHistory,
		params?.step,
		allUsers?.isFoundPurchase,
		allUsers?.user_isFound,
		allUsers?.user_mainSearch,
	]);

	if (!state) return;

	return (
		<div className="py-4 bg-white aboutScreen">
			{allUsers?.isFoundPurchaseLoading ? (
				<Loader />
			) : (
				<Container className="py-5">
					<div className="w-50 w50 mb-3">
						<input
							type="search"
							name="search"
							id="Search"
							className="form-control w-100 py-3 borderColor2"
							placeholder="Type here to search"
							value={search}
							onChange={e => setSearch(e.target.value)}
						/>
					</div>
					<NewPaginate
						state={state}
						setState={setState}
						setThisData={setThisData}
						type={"purchase"}
						criteria={{
							id: params?.step,
						}}
					/>
					<TransactionDetails
						thisData={thisData}
						setThisData={setThisData}
						type={"purchase"}
						criteria={{
							id: params?.step,
						}}
					/>
				</Container>
			)}
		</div>
	);
};

export default UserPurchase;
