import React, { useContext, useEffect, useState, useRef } from "react";
import { Container } from "reactstrap";
import { GlobalState } from "../Data/Context";
// import user from "../Assets/avatar3.png";
import { toast } from "react-toastify";
// import { BsImage } from "react-icons/bs";
import { Buttons, EyeToggle, OtpComponent } from "../Utils";
import { ModalComponents } from "./DefaultHeader";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Switch from "react-switch";
import { NumericFormat } from "react-number-format";
import { TransactionPinBox } from "./Products/AutoBuy";
import { imageUpload } from "../Data/Actions/AuthActions";
import { useDispatch } from "react-redux";
import axios from "axios";
import { UPDATE_USER } from "../Data/Actions/ActionTypes";
import { Enable2FAComponent } from "../Screens/login";

export let metricArr = [
	{ value: "day", title: "day" },
	{ value: "week", title: "Week" },
	{ value: "month", title: "Month" },
	{ value: "year", title: "Year" },
];

const MainSettings = () => {
	const { auth, updateUser, setStateName } = useContext(GlobalState);

	useEffect(() => {
		setStateName("settings");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	let [state, setState] = useState(null),
		[logo, setLogo] = useState(false),
		[isOpen, setIsOpen] = useState(false),
		[loading, setLoading] = useState(false),
		[submit, setSubmit] = useState(false),
		toggle = () => {
			setIsOpen(!isOpen);
		},
		[active, setActive] = useState(0),
		navigate = useNavigate();

	useEffect(() => {
		setState(auth?.user);
	}, [auth?.user]);

	let handleChangeImage = e => {
			const file = e.target.files[0];
			let err = "";

			if (!file) return (err = `File, ${file?.name} does not exist`);
			if (!file.type.includes("image"))
				return (err = `File, ${file?.name} format not supported`);

			if (err) {
				return toast.error(err);
			} else {
				setLogo(file);
			}
		},
		handleSubmit = type => async e => {
			if (e) e.preventDefault();
			if (type === "profile-image") {
				if (!logo) return toast.info("Image required", { auth: 10000 });
				setLoading(true);
				await updateUser({ logo }, "profile-image");
				setLoading(false);
			} else {
				if (!state?.telephone)
					return toast.info("Bio required", { auth: 10000 });
				setLoading(true);
				await updateUser(state);
				setLoading(false);
			}
			setSubmit(true);
		},
		{ permission } = useContext(GlobalState),
		[set, setSet] = useState(false),
		[enabler, setEnabler] = useState(false);

	useEffect(() => {
		if (auth?.isUpdated && submit) {
			setIsOpen(false);
			setSubmit(false);
		}
	}, [auth?.isUpdated, submit]);

	useEffect(() => {
		if (auth?.user?.privilege === "superadmin") {
			setEnabler(true);
			setSet(true);
		} else {
			let findTitle = "viewenabler";

			let findPermit = permission?.data?.find(
				item =>
					item?.slug === findTitle && item?.user?.includes(auth?.user?._id)
			);
			if (findPermit) {
				setEnabler(true);
			} else {
				setActive(0);
			}

			let findTitle2 = "viewsettings";

			let findPermit2 = permission?.data?.find(
				item =>
					item?.slug === findTitle2 && item?.user?.includes(auth?.user?._id)
			);
			if (findPermit2) {
				setSet(true);
			} else {
				setActive(0);
			}
		}
		return () => {
			setEnabler(false);
			setSet(false);
		};
	}, [auth?.user, permission?.data]);

	let textChange =
		name =>
		({ target: { value } }) => {
			setState({ ...state, [name]: value });
		};

	// if (!state) return;

	return (
		<div className="py-4 bg-white aboutScreen">
			<Container className="py-5">
				<div className="d-flex justify-content-between align-items-center mb-3 px-md-4 px-2">
					<div>
						<h4 className="Lexend fw-600 fontReduceBig">My Account Settings</h4>
						<div>
							<button
								onClick={() => setActive(0)}
								className={`btn ${
									active !== 0 ? "btn-light" : "btn-outline-primary1"
								}
								text-capitalize px-md-4 px-2 mx-1 mx-md-3 py-1 py-md-2 fontReduce2 text-dark`}>
								profile
							</button>
							{set && (
								<button
									onClick={() => setActive(1)}
									className={`btn ${
										active !== 1 ? "btn-light" : "btn-outline-primary1"
									}
								text-capitalize px-md-4 px-2 mx-1 mx-md-3 py-1 py-md-2 fontReduce2 text-dark`}>
									settings
								</button>
							)}
							{enabler && (
								<button
									onClick={() => setActive(2)}
									className={`btn ${
										active !== 2 ? "btn-light" : "btn-outline-primary1"
									}
								text-capitalize px-md-4 px-2 mx-1 mx-md-3 py-1 py-md-2 fontReduce2 text-dark`}>
									enabler
								</button>
							)}
							<button
								onClick={() => navigate("/settings/app")}
								className={`btn ${
									active !== 3 ? "btn-light" : "btn-outline-primary1"
								}
								text-capitalize px-md-4 px-2 mx-1 mx-md-3 py-1 py-md-2 fontReduce2 text-dark`}>
								App Settings
							</button>
						</div>
					</div>
					<div className="d-flex align-items-center">
						{/* <img
							src={auth?.user?.avatar?.url ? auth?.user?.avatar?.url : user}
							alt={`img`}
							style={{
								height: "7rem",
								width: "7rem",
								objectFit: "cover",
								objectPosition: "center 15%",
							}}
							className="rounded-circle img-fluid mx-3"
						/> */}
						{/* <AvatarImg
							user={auth?.user}
							style={{
								height: "7rem",
								width: "7rem",
								objectFit: "cover",
								objectPosition: "center 15%",
							}}
						/> */}
						<div className="d-none d-md-block">
							<h5 className="Lexend">
								{auth?.user?.firstName} {auth?.user?.lastName}
							</h5>
							<small className="text-uppercase">{auth?.user?.privilege}</small>
						</div>
					</div>
				</div>
				{active === 2 ? (
					<EnablerSettings />
				) : active === 1 ? (
					<GeneralSettings />
				) : (
					<ProfileSetup
						state={state}
						textChange={textChange}
						handleChangeImage={handleChangeImage}
						handleSubmit={handleSubmit}
						toggle={toggle}
						logo={logo}
						loading={loading}
						isOpen={isOpen}
						setState={setState}
					/>
				)}
			</Container>
		</div>
	);
};

export default MainSettings;

const ProfileSetup = ({
	state,
	textChange,
	isOpen,
	toggle,
	logo,
	handleChangeImage,
	handleSubmit,
	loading,
	setState,
}) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const { auth, usecase } = useContext(GlobalState);

	let [isRequest, setIsRequest] = useState(""),
		closeRequest = () => {
			setIsRequest("");
		};

	return (
		<>
			<div className="px-md-4 px-2 mb-5">
				<h5 className="Lexend fw-600 fontReduceBig">Profile</h5>
				<p className="fontReduce2">Update personal details here</p>
				{/* <h5 className="mt-3 Lexend fw-600 fontReduceBig">Photos</h5>
				<div className="d-flex align-items-center">
					<img
						src={state?.avatar?.url ? state?.avatar?.url : user}
						alt={`img`}
						style={{
							height: "4rem",
							width: "4rem",
							objectFit: "cover",
							objectPosition: "center 15%",
						}}
						className="rounded-circle img-fluid mx-3"
					/>
					<div className="d-flex align-items-center">
						<button
							onClick={toggle}
							className={`btn btn-light text-capitalize px-md-4 px-2 mx-1 mx-md-3 py-1 py-md-2 fontReduce2 text-dark`}>
							change
						</button>
						<ModalComponents
							isOpen={isOpen}
							back={toggle}
							title="Update profile image">
							<div className="d-flex">
								<div className="mx-auto position-relative">
									<img
										src={
											logo
												? URL.createObjectURL(logo)
												: state?.avatar?.url
												? state?.avatar.url
												: user
										}
										alt={state?.firstName}
										style={{
											height: "15rem",
											width: "15rem",
										}}
										className="rounded-circle img-fluid mx-auto"
									/>
									<div className="file_upload d-flex myCursor mt-auto ms-auto justify-content-end">
										<BsImage
											size={22}
											title="Upload image"
											className="mx-2 myCursor statusIcon"
										/>
										<input
											title="Upload file"
											type="file"
											name="file"
											id="file"
											multiple
											className="myCursor"
											accept="image/*"
											onChange={handleChangeImage}
										/>
									</div>
									{logo && (
										<Buttons
											onClick={handleSubmit("profile-image")}
											loading={logo && loading}
											css="btn btn-primary1 text-capitalize py-3 my-4"
											title={"Update profile image"}
										/>
									)}
								</div>
							</div>
						</ModalComponents>
					</div>
				</div> */}
			</div>
			<form onSubmit={handleSubmit("update")}>
				<ProfileForm
					state={state}
					textChange={textChange}
					setState={setState}
				/>
				<div className="d-flex justify-content-end my-3">
					<div className="d-flex align-items-center">
						<Buttons
							onClick={handleSubmit("update")}
							loading={!logo && loading}
							width=""
							css="btn-primary1 text-capitalize px-md-4 px-2 mx-1 mx-md-3 py-1 py-md-2 fontReduce2 text-white"
							title={"save"}
						/>
						<button
							className={`btn btn-outline-primary1 text-capitalize px-md-4 px-2 mx-1 mx-md-3 py-1 py-md-2 fontReduce2 text-primary1`}>
							cancel
						</button>
					</div>
				</div>
			</form>
			{!auth?.user?.isAdmin && (
				<div className="py-3 py-md-5">
					{auth?.user?.privilege !== "reseller" &&
						usecase?.usecase?.resellerUpgrade === "enable" && (
							<button
								onClick={() => setIsRequest("reseller")}
								className="btn btn-primary1
								text-capitalize px-md-4 px-2 mx-1 mx-md-3 py-1 py-md-3 fontReduce2 text-white">
								become a reseller
							</button>
						)}
					{auth?.user?.privilege !== "topuser" &&
						usecase?.usecase?.topuserUpgrade === "enable" && (
							<button
								onClick={() => setIsRequest("topuser")}
								className="btn btn-primary1
								text-capitalize px-md-4 px-2 mx-1 mx-md-3 py-1 py-md-3 fontReduce2 text-white">
								become a topuser
							</button>
						)}
				</div>
			)}
			<WalletPinBox />
			<PasswordBox />
			<Enable2FABox />
			<BecomeAgent isOpen={isRequest} back={closeRequest} />
		</>
	);
};

const BecomeAgent = ({ isOpen, back }) => {
	let {
		usecase,
		upgrade,
		numberWithCommas,
		manageUpgrade,
		returnErrors,
		nairaSignNeutral,
	} = useContext(GlobalState);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	let init = {
			privilege: isOpen,
			websiteURL: "",
			businessName: "",
			stateOfResidence: "",
			dateOfBirth: "",
			ninNumber: "",
		},
		[state, setState] = useState(init),
		textChange =
			name =>
			({ target: { value } }) => {
				setState({ ...state, [name]: value });
			},
		[loading, setLoading] = useState(false),
		[submit, setSubmit] = useState(false),
		handleSubmit = async () => {
			let errArr = [];

			if (!state?.businessName)
				errArr.push({
					msg: "Business name is required",
					param: "businessName",
				});
			if (!state?.stateOfResidence)
				errArr.push({
					msg: "State of residence is required",
					param: "stateOfResidence",
				});
			if (!state?.dateOfBirth)
				errArr.push({ msg: "Date of birth is required", param: "dateOfBirth" });
			if (!state?.ninNumber)
				errArr.push({ msg: "NIN number is required", param: "ninNumber" });
			if (errArr?.length > 0)
				return returnErrors({
					error: errArr,
				});

			setLoading(true);
			await manageUpgrade({ ...state, privilege: isOpen });
			setLoading(false);
			setSubmit(true);
		};

	useEffect(() => {
		if (submit && upgrade?.isAdded) {
			back();
			setSubmit(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [submit, upgrade]);

	return (
		<>
			<ModalComponents
				isOpen={isOpen ? true : false}
				back={back}
				title={`Become a ${isOpen}`}>
				<div>
					<div className="mb-3">
						<label htmlFor="businessName">Business name</label>
						<input
							type="text"
							name="businessName"
							value={state?.businessName}
							onChange={textChange("businessName")}
							placeholder="Your Business Name"
							className="form-control py-3"
						/>
					</div>
					<div className="mb-3">
						<label htmlFor="websiteURL">Website URL</label>
						<input
							type="url"
							name="websiteURL"
							value={state?.websiteURL}
							onChange={textChange("websiteURL")}
							placeholder="Your Website URL"
							className="form-control py-3"
						/>
					</div>
					<div className="mb-3">
						<label htmlFor="ninNumber">NIN</label>
						<input
							type="text"
							name="ninNumber"
							value={state?.ninNumber}
							onChange={textChange("ninNumber")}
							placeholder="Your NIN number"
							className="form-control py-3"
						/>
					</div>
					<div className="mb-3">
						<label htmlFor="stateOfResidence">State of Residence</label>
						<input
							type="text"
							name="stateOfResidence"
							value={state?.stateOfResidence}
							onChange={textChange("stateOfResidence")}
							placeholder="Your State of Residence"
							className="form-control py-3"
						/>
					</div>
					<div className="mb-3">
						<label htmlFor="dateOfBirth">Date of birth</label>
						<input
							type="date"
							name="dateOfBirth"
							value={state?.dateOfBirth}
							onChange={textChange("dateOfBirth")}
							placeholder="Your Date of Birth"
							className="form-control py-3"
							max={moment().format("YYYY-MM-DD")}
						/>
					</div>
					<Buttons
						title={`Proceed ${nairaSignNeutral} ${
							isOpen === "reseller"
								? numberWithCommas(
										Number(usecase?.usecase?.resellerUpgradeFee).toFixed()
								  )
								: numberWithCommas(
										Number(usecase?.usecase?.topuserUpgradeFee).toFixed(2)
								  )
						} `}
						css="btn-primary1 text-capitalize py-3 w-50 my-4 mx-auto"
						width={"w-50"}
						style={{ borderRadius: "30px" }}
						loading={loading}
						onClick={handleSubmit}
					/>
				</div>
			</ModalComponents>
		</>
	);
};

const GeneralSettings = () => {
	const { settings, getSettings, nairaSign, numberWithCommas } =
		useContext(GlobalState);

	let [mtnCommission, setMTNCommission] = useState(""),
		[gloCommission, setGLOCommission] = useState(""),
		[airtelCommission, setAIRTELCommission] = useState(""),
		[mobile9Commission, set9MobileCommission] = useState(""),
		[cablesCommission, setCablesCommission] = useState(""),
		[educationCommission, setEducationCommission] = useState(""),
		[electricityCommission, setElectricityCommission] = useState(""),
		[minimumWithdrawal, setminimumWithdrawal] = useState(""),
		[maximumWithdrawal, setmaximumWithdrawal] = useState(""),
		[mtnResellerCommission, setMTNResellerCommission] = useState(""),
		[gloResellerCommission, setGLOResellerCommission] = useState(""),
		[airtelResellerCommission, setAIRTELResellerCommission] = useState(""),
		[mobile9ResellerCommission, set9MobileResellerCommission] = useState(""),
		[cablesResellerCommission, setCablesResellerCommission] = useState(""),
		[educationResellerCommission, setEducationResellerCommission] =
			useState(""),
		[electricityResellerCommission, setElectricityResellerCommission] =
			useState(""),
		[mtnAgentCommission, setMTNAgentCommission] = useState(""),
		[gloAgentCommission, setGLOAgentCommission] = useState(""),
		[airtelAgentCommission, setAIRTELAgentCommission] = useState(""),
		[mobile9AgentCommission, set9MobileAgentCommission] = useState(""),
		[cablesAgentCommission, setCablesAgentCommission] = useState(""),
		[educationAgentCommission, setEducationAgentCommission] = useState(""),
		[electricityAgentCommission, setElectricityAgentCommission] = useState(""),
		[appUpdateVersion, setAppUpdateVersion] = useState(""),
		[accountEmail, setAccountEmail] = useState(""),
		[accountWhatsApp, setAccountWhatsApp] = useState(""),
		[accountTelephone, setAccountTelephone] = useState(""),
		[homepageMarquee, sethomepageMarquee] = useState(""),
		[stateData, setStateData] = useState(null),
		[loading, setLoading] = useState(false),
		[loadingType, setLoadingType] = useState(false),
		[submit, setSubmit] = useState(false);

	useEffect(() => {
		setStateData(settings?.settings);
	}, [settings?.settings]);
	useEffect(() => {
		if (stateData) {
			setMTNCommission(stateData?.mtnCommission);
			setGLOCommission(stateData?.gloCommission);
			setAIRTELCommission(stateData?.airtelCommission);
			set9MobileCommission(stateData?.mobile9Commission);
			setCablesCommission(stateData?.cablesCommission);
			setEducationCommission(stateData?.educationCommission);
			setElectricityCommission(stateData?.electricityCommission);
			setminimumWithdrawal(stateData?.minimumWithdrawal);
			setmaximumWithdrawal(stateData?.maximumWithdrawal);
			setMTNResellerCommission(stateData?.mtnResellerCommission);
			setGLOResellerCommission(stateData?.gloResellerCommission);
			setAIRTELResellerCommission(stateData?.airtelResellerCommission);
			set9MobileResellerCommission(stateData?.mobile9ResellerCommission);
			setCablesResellerCommission(stateData?.cablesResellerCommission);
			setEducationResellerCommission(stateData?.educationResellerCommission);
			setElectricityResellerCommission(
				stateData?.electricityResellerCommission
			);
			setMTNAgentCommission(stateData?.mtnAgentCommission);
			setGLOAgentCommission(stateData?.gloAgentCommission);
			setAIRTELAgentCommission(stateData?.airtelAgentCommission);
			set9MobileAgentCommission(stateData?.mobile9AgentCommission);
			setCablesAgentCommission(stateData?.cablesAgentCommission);
			setEducationAgentCommission(stateData?.educationAgentCommission);
			setElectricityAgentCommission(stateData?.electricityAgentCommission);
			setAppUpdateVersion(stateData?.appUpdateVersion);
			setAccountEmail(stateData?.accountEmail);
			setAccountWhatsApp(stateData?.accountWhatsApp);
			setAccountTelephone(stateData?.accountTelephone);
			sethomepageMarquee(stateData?.homepageMarquee);
		}
	}, [stateData]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	// accountEmail,
	// 	accountWhatsApp,
	// 	accountTelephone,
	useEffect(() => {
		if (submit && settings?.isUpdated) {
			setSubmit(false);
		}
	}, [settings?.isUpdated, submit]);

	// console.log({ settings });

	let handleSubmit = type => async () => {
		if (type === "mtnCommission") if (!mtnCommission) return;
		if (type === "gloCommission") if (!gloCommission) return;
		if (type === "airtelCommission") if (!airtelCommission) return;
		if (type === "mobile9Commission") if (!mobile9Commission) return;
		if (type === "cablesCommission") if (!cablesCommission) return;
		if (type === "electricityCommission") if (!electricityCommission) return;
		if (type === "minimumWithdrawal") if (!minimumWithdrawal) return;
		if (type === "maximumWithdrawal") if (!maximumWithdrawal) return;
		if (type === "educationCommission") if (!educationCommission) return;
		if (type === "mtnResellerCommission") if (!mtnResellerCommission) return;
		if (type === "gloResellerCommission") if (!gloResellerCommission) return;
		if (type === "airtelResellerCommission")
			if (!airtelResellerCommission) return;
		if (type === "mobile9ResellerCommission")
			if (!mobile9ResellerCommission) return;
		if (type === "cablesResellerCommission")
			if (!cablesResellerCommission) return;
		if (type === "electricityResellerCommission")
			if (!electricityResellerCommission) return;
		// if (type === "airtimeToCashCommission")
		// 	if (!airtimeToCashCommission) return;
		if (type === "educationResellerCommission")
			if (!educationResellerCommission) return;
		if (type === "mtnAgentCommission") if (!mtnAgentCommission) return;
		if (type === "gloAgentCommission") if (!gloAgentCommission) return;
		if (type === "airtelAgentCommission") if (!airtelAgentCommission) return;
		if (type === "mobile9AgentCommission") if (!mobile9AgentCommission) return;
		if (type === "cablesAgentCommission") if (!cablesAgentCommission) return;
		if (type === "electricityAgentCommission")
			if (!electricityAgentCommission) return;
		// if (type === "airtimeToCashCommission")
		// 	if (!airtimeToCashCommission) return;
		if (type === "educationAgentCommission")
			if (!educationAgentCommission) return;
		if (type === "accountEmail") if (!accountEmail) return;
		if (type === "accountWhatsApp") if (!accountWhatsApp) return;
		if (type === "accountTelephone") if (!accountTelephone) return;
		if (type === "homepageMarquee") if (!homepageMarquee) return;

		let data;
		if (type === "mtnCommission")
			data = {
				mtnCommission: mtnCommission ? mtnCommission : stateData?.mtnCommission,
			};
		if (type === "gloCommission")
			data = {
				gloCommission: gloCommission ? gloCommission : stateData?.gloCommission,
			};
		if (type === "airtelCommission")
			data = {
				airtelCommission: airtelCommission
					? airtelCommission
					: stateData?.airtelCommission,
			};
		if (type === "mobile9Commission")
			data = {
				mobile9Commission: mobile9Commission
					? mobile9Commission
					: stateData?.mobile9Commission,
			};
		if (type === "cablesCommission")
			data = {
				cablesCommission: cablesCommission
					? cablesCommission
					: stateData?.cablesCommission,
			};
		if (type === "educationCommission")
			data = {
				educationCommission: educationCommission
					? educationCommission
					: stateData?.educationCommission,
			};
		if (type === "electricityCommission")
			data = {
				electricityCommission: electricityCommission
					? electricityCommission
					: stateData?.electricityCommission,
			};
		if (type === "maximumWithdrawal")
			data = {
				maximumWithdrawal: maximumWithdrawal
					? maximumWithdrawal
					: stateData?.maximumWithdrawal,
			};
		if (type === "minimumWithdrawal")
			data = {
				minimumWithdrawal: minimumWithdrawal
					? minimumWithdrawal
					: stateData?.minimumWithdrawal,
			};
		if (type === "mtnResellerCommission")
			data = {
				mtnResellerCommission: mtnResellerCommission
					? mtnResellerCommission
					: stateData?.mtnResellerCommission,
			};
		if (type === "gloResellerCommission")
			data = {
				gloResellerCommission: gloResellerCommission
					? gloResellerCommission
					: stateData?.gloResellerCommission,
			};
		if (type === "airtelResellerCommission")
			data = {
				airtelResellerCommission: airtelResellerCommission
					? airtelResellerCommission
					: stateData?.airtelResellerCommission,
			};
		if (type === "mobile9ResellerCommission")
			data = {
				mobile9ResellerCommission: mobile9ResellerCommission
					? mobile9ResellerCommission
					: stateData?.mobile9ResellerCommission,
			};
		if (type === "cablesResellerCommission")
			data = {
				cablesResellerCommission: cablesResellerCommission
					? cablesResellerCommission
					: stateData?.cablesResellerCommission,
			};
		if (type === "educationResellerCommission")
			data = {
				educationResellerCommission: educationResellerCommission
					? educationResellerCommission
					: stateData?.educationResellerCommission,
			};
		if (type === "electricityResellerCommission")
			data = {
				electricityResellerCommission: electricityResellerCommission
					? electricityResellerCommission
					: stateData?.electricityResellerCommission,
			};
		if (type === "mtnAgentCommission")
			data = {
				mtnAgentCommission: mtnAgentCommission
					? mtnAgentCommission
					: stateData?.mtnAgentCommission,
			};
		if (type === "gloAgentCommission")
			data = {
				gloAgentCommission: gloAgentCommission
					? gloAgentCommission
					: stateData?.gloAgentCommission,
			};
		if (type === "airtelAgentCommission")
			data = {
				airtelAgentCommission: airtelAgentCommission
					? airtelAgentCommission
					: stateData?.airtelAgentCommission,
			};
		if (type === "mobile9AgentCommission")
			data = {
				mobile9AgentCommission: mobile9AgentCommission
					? mobile9AgentCommission
					: stateData?.mobile9AgentCommission,
			};
		if (type === "cablesAgentCommission")
			data = {
				cablesAgentCommission: cablesAgentCommission
					? cablesAgentCommission
					: stateData?.cablesAgentCommission,
			};
		if (type === "educationAgentCommission")
			data = {
				educationAgentCommission: educationAgentCommission
					? educationAgentCommission
					: stateData?.educationAgentCommission,
			};
		if (type === "electricityAgentCommission")
			data = {
				electricityAgentCommission: electricityAgentCommission
					? electricityAgentCommission
					: stateData?.electricityAgentCommission,
			};
		if (type === "appUpdateVersion")
			data = {
				appUpdateVersion: appUpdateVersion || stateData?.appUpdateVersion,
			};
		if (type === "accountEmail")
			data = {
				accountEmail: accountEmail || stateData?.accountEmail,
			};
		if (type === "accountTelephone")
			data = {
				accountTelephone: accountTelephone || stateData?.accountTelephone,
			};
		if (type === "accountWhatsApp")
			data = {
				accountWhatsApp: accountWhatsApp || stateData?.accountWhatsApp,
			};
		if (type === "homepageMarquee")
			data = {
				homepageMarquee: homepageMarquee || stateData?.homepageMarquee,
			};
		// console.log({ data, state });
		setLoadingType(type);
		setLoading(true);
		await getSettings(data);
		setLoading(false);
		setLoadingType(false);
		setSubmit(false);
	};

	return (
		<Container className="px-lg-5  pt-3 pt-lg-0">
			<div className="mb-5">
				<h5 className="Lexend fw-600 fontReduceBig">General Settings</h5>
			</div>
			<div className="row mx-0 g-3 g-md-4">
				<p className="fontReduce col-md-6">
					MTN Airtime Commission: {stateData?.mtnCommission}%
				</p>
				<p className="fontReduce col-md-6">
					GLO Airtime Commission: {stateData?.gloCommission}%
				</p>
				<p className="fontReduce col-md-6">
					AIRTEL Airtime Commission: {stateData?.airtelCommission}%
				</p>
				<p className="fontReduce col-md-6">
					9MOBILE Airtime Commission: {stateData?.mobile9Commission}%
				</p>
				<p className="fontReduce col-md-6">
					Electricity Commission: {stateData?.electricityCommission}%
				</p>
				<p className="fontReduce col-md-6">
					Cables Commission: {stateData?.cablesCommission}%
				</p>
				{/* <p className="fontReduce col-md-6">
					Education Commission: {stateData?.educationCommission}%
				</p> */}
				{/* <p className="fontReduce col-md-6">
					Airtime to cash return: {stateData?.airtimeToCashCommission}%
				</p> */}
				<p className="fontReduce col-md-6">
					MTN Reseller Commission: {stateData?.mtnResellerCommission}%
				</p>
				<p className="fontReduce col-md-6">
					GLO Reseller Commission: {stateData?.gloResellerCommission}%
				</p>
				<p className="fontReduce col-md-6">
					AIRTEL Reseller Commission: {stateData?.airtelResellerCommission}%
				</p>
				<p className="fontReduce col-md-6">
					9MOBILE Reseller Commission: {stateData?.mobile9ResellerCommission}%
				</p>
				<p className="fontReduce col-md-6">
					Electricity Reseller Commission:{" "}
					{stateData?.electricityResellerCommission}%
				</p>
				<p className="fontReduce col-md-6">
					Cables Reseller Commission: {stateData?.cablesResellerCommission}%
				</p>
				{/* <p className="fontReduce col-md-6">
					Education Reseller Commission:{" "}
					{stateData?.educationResellerCommission}%
				</p> */}
				<p className="fontReduce col-md-6">
					MTN Agent Commission: {stateData?.mtnAgentCommission}%
				</p>
				<p className="fontReduce col-md-6">
					GLO Agent Commission: {stateData?.gloAgentCommission}%
				</p>
				<p className="fontReduce col-md-6">
					AIRTEL Agent Commission: {stateData?.airtelAgentCommission}%
				</p>
				<p className="fontReduce col-md-6">
					9MOBILE Agent Commission: {stateData?.mobile9AgentCommission}%
				</p>
				<p className="fontReduce col-md-6">
					Electricity Agent Commission: {stateData?.electricityAgentCommission}%
				</p>
				<p className="fontReduce col-md-6">
					Cables Agent Commission: {stateData?.cablesAgentCommission}%
				</p>
				{/* <p className="fontReduce col-md-6">
					Education Agent Commission: {stateData?.educationAgentCommission}%
				</p> */}
				<p className="fontReduce col-md-6">
					Minimum Withdrawal: {nairaSign}{" "}
					{stateData?.minimumWithdrawal &&
						numberWithCommas(stateData?.minimumWithdrawal)}
				</p>
				<p className="fontReduce col-md-6">
					Maximum Withdrawal: {nairaSign}{" "}
					{stateData?.maximumWithdrawal &&
						numberWithCommas(stateData?.maximumWithdrawal)}
				</p>
				<p className="fontReduce col-md-6">
					App Version: {stateData?.appUpdateVersion}
				</p>
				<p className="fontReduce col-md-6">
					Account Email: {stateData?.accountEmail}
				</p>
				<p className="fontReduce col-md-6">
					Account Telephone: {stateData?.accountTelephone}
				</p>
				<p className="fontReduce col-md-6">
					Account WhatsApp: {stateData?.accountWhatsApp}
				</p>
				<p className="fontReduce col-md-6">
					Marquee Text: {stateData?.homepageMarquee}
				</p>
			</div>
			<div className="row mx-0 g-4 pt-5">
				<GenCheck
					state={mtnCommission}
					setState={setMTNCommission}
					type={"mtnCommission"}
					label="MTN commission"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					percentage
				/>
				<GenCheck
					state={gloCommission}
					setState={setGLOCommission}
					type={"gloCommission"}
					label="GLO commission"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					percentage
				/>
				<GenCheck
					state={airtelCommission}
					setState={setAIRTELCommission}
					type={"airtelCommission"}
					label="AIRTEL commission"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					percentage
				/>
				<GenCheck
					state={mobile9Commission}
					setState={set9MobileCommission}
					type={"mobile9Commission"}
					label="9MOBILE commission"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					percentage
				/>
				<GenCheck
					state={cablesCommission}
					setState={setCablesCommission}
					type={"cablesCommission"}
					label="CABLES commission"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					percentage
				/>
				<GenCheck
					state={electricityCommission}
					setState={setElectricityCommission}
					type={"electricityCommission"}
					label="ELECTRICITY commission"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					percentage
				/>
				{/* <GenCheck
					state={educationCommission}
					setState={setEducationCommission}
					type={"educationCommission"}
					label="Education commission"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					percentage
				/> */}
				<GenCheck
					state={mtnResellerCommission}
					setState={setMTNResellerCommission}
					type={"mtnResellerCommission"}
					label="MTN Reseller commission"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					percentage
				/>
				<GenCheck
					state={gloResellerCommission}
					setState={setGLOResellerCommission}
					type={"gloResellerCommission"}
					label="GLO Reseller commission"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					percentage
				/>
				<GenCheck
					state={airtelResellerCommission}
					setState={setAIRTELResellerCommission}
					type={"airtelResellerCommission"}
					label="AIRTEL Reseller commission"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					percentage
				/>
				<GenCheck
					state={mobile9ResellerCommission}
					setState={set9MobileResellerCommission}
					type={"mobile9ResellerCommission"}
					label="9MOBILE Reseller commission"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					percentage
				/>
				<GenCheck
					state={cablesResellerCommission}
					setState={setCablesResellerCommission}
					type={"cablesResellerCommission"}
					label="CABLES Reseller commission"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					percentage
				/>
				<GenCheck
					state={electricityResellerCommission}
					setState={setElectricityResellerCommission}
					type={"electricityResellerCommission"}
					label="ELECTRICITY Reseller commission"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					percentage
				/>
				{/* <GenCheck
					state={educationResellerCommission}
					setState={setEducationResellerCommission}
					type={"educationResellerCommission"}
					label="Education Reseller commission"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					percentage
				/> */}
				<GenCheck
					state={mtnAgentCommission}
					setState={setMTNAgentCommission}
					type={"mtnAgentCommission"}
					label="MTN Agent commission"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					percentage
				/>
				<GenCheck
					state={gloAgentCommission}
					setState={setGLOAgentCommission}
					type={"gloAgentCommission"}
					label="GLO Agent commission"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					percentage
				/>
				<GenCheck
					state={airtelAgentCommission}
					setState={setAIRTELAgentCommission}
					type={"airtelAgentCommission"}
					label="AIRTEL Agent commission"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					percentage
				/>
				<GenCheck
					state={mobile9AgentCommission}
					setState={set9MobileAgentCommission}
					type={"mobile9AgentCommission"}
					label="9MOBILE Agent commission"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					percentage
				/>
				<GenCheck
					state={cablesAgentCommission}
					setState={setCablesAgentCommission}
					type={"cablesAgentCommission"}
					label="CABLES Agent commission"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					percentage
				/>
				<GenCheck
					state={electricityAgentCommission}
					setState={setElectricityAgentCommission}
					type={"electricityAgentCommission"}
					label="ELECTRICITY Agent commission"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					percentage
				/>
				{/* <GenCheck
					state={educationAgentCommission}
					setState={setEducationAgentCommission}
					type={"educationAgentCommission"}
					label="Education Agent commission"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					percentage
				/> */}
				<GenCheck
					state={minimumWithdrawal}
					setState={setminimumWithdrawal}
					type={"minimumWithdrawal"}
					label="minimum Withdrawal"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
				/>
				<GenCheck
					state={maximumWithdrawal}
					setState={setmaximumWithdrawal}
					type={"maximumWithdrawal"}
					label="maximum Withdrawal"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
				/>
				<GenCheck
					state={appUpdateVersion}
					setState={setAppUpdateVersion}
					type={"appUpdateVersion"}
					label="App Update Version"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					noFormat
					text
				/>
				<GenCheck
					state={accountEmail}
					setState={setAccountEmail}
					type={"accountEmail"}
					label="Account Email"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					noFormat
					text
				/>
				<GenCheck
					state={accountWhatsApp}
					setState={setAccountWhatsApp}
					type={"accountWhatsApp"}
					label="Account WhatsApp"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					noFormat
					text
				/>
				<GenCheck
					state={accountTelephone}
					setState={setAccountTelephone}
					type={"accountTelephone"}
					label="Account Telephone"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					noFormat
					text
				/>
				<GenCheck
					state={homepageMarquee}
					setState={sethomepageMarquee}
					type={"homepageMarquee"}
					label="Marquee Text"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					noFormat
					text
				/>
			</div>
		</Container>
	);
};

export const EnablerSettings = () => {
	const { usecase, getUseCase, numberWithCommas, nairaSign, auth } =
		useContext(GlobalState);
	// console.log({ usecase });

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	let [cables, setCables] = useState(""),
		[education, setEducation] = useState(""),
		[electricity, setElectricity] = useState(""),
		[data, setData] = useState(""),
		[registration, setRegistration] = useState(""),
		[login, setLogin] = useState(""),
		[otpRegistration, setOtpRegistration] = useState(""),
		[fundWallet, setFundWallet] = useState(""),
		[transferFund, setTransferFund] = useState(""),
		[transferFundLimit, setTransferFundLimit] = useState(""),
		[withdrawal, setWithdrawal] = useState(""),
		[autobuy, setAutobuy] = useState(""),
		[trackingMinimum, setTrackingMinimum] = useState(""),
		[autoFund, setAutoFund] = useState(""),
		[airtimeToCash, setAirtimeToCash] = useState(""),
		[airtime, setAirtime] = useState(""),
		[airtimeMini, setAirtimeMini] = useState(""),
		[airtimeMax, setAirtimeMax] = useState(""),
		[electricityMini, setElectricityMini] = useState(""),
		[electricityMax, setElectricityMax] = useState(""),
		[airtimeToCashMini, setAirtimeToCashMini] = useState(""),
		[airtimeToCashMax, setAirtimeToCashMax] = useState(""),
		[cardFundingMini, setcardFundingMini] = useState(""),
		[cardFundingMax, setcardFundingMax] = useState(""),
		[transferFundingMini, settransferFundingMini] = useState(""),
		[transferFundingMax, settransferFundingMax] = useState(""),
		[stateData, setStateData] = useState(null),
		[loading, setLoading] = useState(false),
		[loadingType, setLoadingType] = useState(false),
		[resellerUpgrade, setResellerUpgrade] = useState(""),
		[resellerUpgradeFee, setResellerUpgradeFee] = useState(""),
		[agentUpgrade, setAgentUpgrade] = useState(""),
		[agentUpgradeFee, setAgentUpgradeFee] = useState(""),
		[topuserUpgrade, setTopUserUpgrade] = useState(""),
		[topuserUpgradeFee, setTopUserUpgradeFee] = useState(""),
		[fundWalletFlutterwave, setfundWalletFlutterwave] = useState(""),
		[fundWalletPaystack, setfundWalletPaystack] = useState(""),
		[fundWalletBudpay, setfundWalletBudpay] = useState(""),
		[apiStatus, setapiStatus] = useState(""),
		[ninBvnRestriction, setninBvnRestriction] = useState(""),
		[cgPurchaseStatus, setcgPurchaseStatus] = useState(""),
		[cgStatus, setcgStatus] = useState(""),
		[appStatus, setappStatus] = useState(""),
		[webStatus, setwebStatus] = useState(""),
		[adminStatus, setadminStatus] = useState(""),
		[fundWalletMonnify, setfundWalletMonnify] = useState(""),
		[referralBonus, setreferralBonus] = useState(""),
		[referralBonusType, setreferralBonusType] = useState(""),
		[referralBonusLimit, setreferralBonusLimit] = useState(""),
		[referralBonusValue, setreferralBonusValue] = useState(""),
		[referralBonusFixed, setreferralBonusFixed] = useState(""),
		[bizReseller, setBizReseller] = useState(""),
		[bizAgent, setBizAgent] = useState(""),
		[bizUser, setBizUser] = useState(""),
		[verifyReseller, setverifyReseller] = useState(""),
		[verifyAgent, setverifyAgent] = useState(""),
		[verifyUser, setverifyUser] = useState(""),
		[inactiveAccountMetricsType, setinactiveAccountMetricsType] = useState(""),
		[inactiveAccountMetricsValue, setinactiveAccountMetricsValue] =
			useState(""),
		[dormantAccountMetricsType, setdormantAccountMetricsType] = useState(""),
		[dormantAccountMetricsValue, setdormantAccountMetricsValue] = useState(""),
		[duplicateTransactionTimer, setduplicateTransactionTimer] = useState(""),
		[duplicateTransactionTimerStatus, setduplicateTransactionTimerStatus] =
			useState(""),
		[deactivationDuration, setdeactivationDuration] = useState(""),
		[fundWalletCharges, setfundWalletCharges] = useState(""),
		[bvnCharge, setbvnCharge] = useState(""),
		[ninCharge, setninCharge] = useState(""),
		[submit, setSubmit] = useState(false);

	useEffect(() => {
		setStateData(usecase?.usecase);
	}, [usecase?.usecase]);

	useEffect(() => {
		if (stateData) {
			setCables(stateData?.cables);
			setEducation(stateData?.education);
			setElectricity(stateData?.electricity);
			setData(stateData?.data);
			setRegistration(stateData?.registration);
			setOtpRegistration(stateData?.otpRegistration);
			setLogin(stateData?.login);
			setFundWallet(stateData?.fundWallet);
			setTransferFund(stateData?.transferFund);
			setTransferFundLimit(stateData?.transferFundLimit);
			setAutoFund(stateData?.autoFund);
			setAirtimeToCash(stateData?.airtimeToCash);
			setAirtime(stateData?.airtime);
			setAirtimeMini(stateData?.airtimeMini);
			setAirtimeMax(stateData?.airtimeMax);
			setElectricityMini(stateData?.electricityMini);
			setElectricityMax(stateData?.electricityMax);
			setAirtimeToCashMini(stateData?.airtimeToCashMini);
			setAirtimeToCashMax(stateData?.airtimeToCashMax);
			setcardFundingMini(stateData?.cardFundingMini);
			setcardFundingMax(stateData?.cardFundingMax);
			settransferFundingMini(stateData?.transferFundingMini);
			settransferFundingMax(stateData?.transferFundingMax);
			setResellerUpgrade(stateData?.resellerUpgrade);
			setResellerUpgradeFee(stateData?.resellerUpgradeFee);
			setAgentUpgrade(stateData?.agentUpgrade);
			setAgentUpgradeFee(stateData?.agentUpgradeFee);
			setTopUserUpgrade(stateData?.topuserUpgrade);
			setTopUserUpgradeFee(stateData?.topuserUpgradeFee);
			setfundWalletFlutterwave(stateData?.fundWalletFlutterwave);
			setfundWalletPaystack(stateData?.fundWalletPaystack);
			setBizReseller(stateData?.bizReseller);
			setBizAgent(stateData?.bizAgent);
			setBizUser(stateData?.bizUser);
			setverifyReseller(stateData?.verifyReseller);
			setverifyAgent(stateData?.verifyAgent);
			setverifyUser(stateData?.verifyUser);
			setfundWalletBudpay(stateData?.fundWalletBudpay);
			setfundWalletMonnify(stateData?.fundWalletMonnify);
			setreferralBonus(stateData?.referralBonus);
			setreferralBonusType(stateData?.referralBonusType);
			setreferralBonusFixed(stateData?.referralBonusFixed);
			setreferralBonusValue(stateData?.referralBonusValue);
			setreferralBonusLimit(stateData?.referralBonusLimit);
			setWithdrawal(stateData?.withdrawal);
			setAutobuy(stateData?.autobuy);
			setTrackingMinimum(stateData?.trackingMinimum);
			setdormantAccountMetricsType(stateData?.dormantAccountMetricsType);
			setdormantAccountMetricsValue(stateData?.dormantAccountMetricsValue);
			setinactiveAccountMetricsType(stateData?.inactiveAccountMetricsType);
			setinactiveAccountMetricsValue(stateData?.inactiveAccountMetricsValue);
			setduplicateTransactionTimer(stateData?.duplicateTransactionTimer);
			setduplicateTransactionTimerStatus(
				stateData?.duplicateTransactionTimerStatus
			);
			setdeactivationDuration(stateData?.deactivationDuration);
			setfundWalletCharges(stateData?.fundWalletCharges);
			setbvnCharge(stateData?.bvnCharge);
			setninCharge(stateData?.ninCharge);
			setapiStatus(stateData?.apiStatus);
			setninBvnRestriction(stateData?.ninBvnRestriction);
			setcgPurchaseStatus(stateData?.cgPurchaseStatus);
			setcgStatus(stateData?.cgStatus);
			setappStatus(stateData?.appStatus);
			setwebStatus(stateData?.webStatus);
			setadminStatus(stateData?.adminStatus);
		}
	}, [stateData]);

	useEffect(() => {
		if (submit && usecase?.isUpdated) {
			setSubmit(false);
		}
	}, [usecase?.isUpdated, submit]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// console.log({ usecase });

	let handleSubmit = type => async () => {
		if (type === "cables") if (!cables) return;
		if (type === "education") if (!education) return;
		if (type === "electricity") if (!electricity) return;
		if (type === "airtime") if (!airtime) return;
		if (type === "data") if (!data) return;
		if (type === "registration") if (!registration) return;
		if (type === "login") if (!login) return;
		if (type === "otpRegistration") if (!otpRegistration) return;
		if (type === "transferFund") if (!transferFund) return;
		if (type === "transferFundLimit") if (!transferFundLimit) return;
		if (type === "airtimeToCash") if (!airtimeToCash) return;
		if (type === "fundWallet") if (!fundWallet) return;
		if (type === "airtimeMini") if (!airtimeMini) return;
		if (type === "airtimeMax") if (!airtimeMax) return;
		if (type === "electricityMini") if (!electricityMini) return;
		if (type === "electricityMax") if (!electricityMax) return;
		if (type === "airtimeToCashMini") if (!airtimeToCashMini) return;
		if (type === "withdrawal") if (!withdrawal) return;
		if (type === "autobuy") if (!autobuy) return;
		if (type === "trackingMinimum") if (!trackingMinimum) return;
		if (type === "airtimeToCashMax") if (!airtimeToCashMax) return;
		if (type === "cardFundingMini") if (!cardFundingMini) return;
		if (type === "cardFundingMax") if (!cardFundingMax) return;
		if (type === "transferFundingMini") if (!transferFundingMini) return;
		if (type === "transferFundingMax") if (!transferFundingMax) return;
		if (type === "autoFund") if (!autoFund) return;
		if (type === "resellerUpgrade") if (!resellerUpgrade) return;
		if (type === "resellerUpgradeFee") if (!resellerUpgradeFee) return;
		if (type === "agentUpgrade") if (!agentUpgrade) return;
		if (type === "agentUpgradeFee") if (!agentUpgradeFee) return;
		if (type === "topuserUpgrade") if (!topuserUpgrade) return;
		if (type === "topuserUpgradeFee") if (!topuserUpgradeFee) return;
		if (type === "fundWalletFlutterwave") if (!fundWalletFlutterwave) return;
		if (type === "fundWalletPaystack") if (!fundWalletPaystack) return;
		if (type === "bizReseller") if (!bizReseller) return;
		if (type === "bizAgent") if (!bizAgent) return;
		if (type === "bizUser") if (!bizUser) return;
		if (type === "verifyReseller") if (!verifyReseller) return;
		if (type === "verifyAgent") if (!verifyAgent) return;
		if (type === "verifyUser") if (!verifyUser) return;
		if (type === "fundWalletBudpay") if (!fundWalletBudpay) return;
		if (type === "apiStatus") if (!apiStatus) return;
		if (type === "ninBvnRestriction") if (!ninBvnRestriction) return;
		if (type === "cgPurchaseStatus") if (!cgPurchaseStatus) return;
		if (type === "cgStatus") if (!cgStatus) return;
		if (type === "appStatus") if (!appStatus) return;
		if (type === "webStatus") if (!webStatus) return;
		if (type === "adminStatus") if (!adminStatus) return;
		if (type === "fundWalletMonnify") if (!fundWalletMonnify) return;
		if (type === "referralBonus") if (!referralBonus) return;
		if (type === "referralBonusType") if (!referralBonusType) return;
		if (type === "referralBonusFixed") if (!referralBonusFixed) return;
		if (type === "referralBonusLimit") if (!referralBonusLimit) return;
		if (type === "referralBonusValue") if (!referralBonusValue) return;
		if (type === "inactiveAccountMetricsType")
			if (!inactiveAccountMetricsType) return;
		if (type === "inactiveAccountMetricsValue")
			if (!inactiveAccountMetricsValue) return;
		if (type === "dormantAccountMetricsType")
			if (!dormantAccountMetricsType) return;
		if (type === "dormantAccountMetricsValue")
			if (!dormantAccountMetricsValue) return;
		if (type === "duplicateTransactionTimer")
			if (!duplicateTransactionTimer) return;
		if (type === "duplicateTransactionTimerStatus")
			if (!duplicateTransactionTimerStatus) return;
		if (type === "deactivationDuration") if (!deactivationDuration) return;
		if (type === "fundWalletCharges") if (!fundWalletCharges) return;
		if (type === "bvnCharge") if (!bvnCharge) return;
		if (type === "ninCharge") if (!ninCharge) return;

		let datum;
		if (type === "cables")
			datum = {
				cables: cables ? cables : stateData?.cables,
			};
		if (type === "resellerUpgrade")
			datum = {
				resellerUpgrade: resellerUpgrade
					? resellerUpgrade
					: stateData?.resellerUpgrade,
			};
		if (type === "resellerUpgradeFee")
			datum = {
				resellerUpgradeFee: resellerUpgradeFee
					? resellerUpgradeFee
					: stateData?.resellerUpgradeFee,
			};
		if (type === "agentUpgrade")
			datum = {
				agentUpgrade: agentUpgrade ? agentUpgrade : stateData?.agentUpgrade,
			};
		if (type === "agentUpgradeFee")
			datum = {
				agentUpgradeFee: agentUpgradeFee
					? agentUpgradeFee
					: stateData?.agentUpgradeFee,
			};
		if (type === "topuserUpgrade")
			datum = {
				topuserUpgrade: topuserUpgrade
					? topuserUpgrade
					: stateData?.topuserUpgrade,
			};
		if (type === "topuserUpgradeFee")
			datum = {
				topuserUpgradeFee: topuserUpgradeFee
					? topuserUpgradeFee
					: stateData?.topuserUpgradeFee,
			};
		if (type === "airtime")
			datum = {
				airtime: airtime ? airtime : stateData?.airtime,
			};
		if (type === "education")
			datum = {
				education: education ? education : stateData?.education,
			};
		if (type === "electricity")
			datum = {
				electricity: electricity ? electricity : stateData?.electricity,
			};
		if (type === "data")
			datum = {
				data: data ? data : stateData?.data,
			};
		if (type === "otpRegistration")
			datum = {
				otpRegistration: otpRegistration
					? otpRegistration
					: stateData?.otpRegistration,
			};
		if (type === "registration")
			datum = {
				registration: registration ? registration : stateData?.registration,
			};
		if (type === "login")
			datum = {
				login: login ? login : stateData?.login,
			};
		if (type === "fundWallet")
			datum = {
				fundWallet: fundWallet ? fundWallet : stateData?.fundWallet,
			};
		if (type === "transferFund")
			datum = {
				transferFund: transferFund ? transferFund : stateData?.transferFund,
			};
		if (type === "transferFundLimit")
			datum = {
				transferFundLimit: transferFundLimit
					? transferFundLimit
					: stateData?.transferFundLimit,
			};
		if (type === "airtimeMini")
			datum = {
				airtimeMini: airtimeMini || stateData?.airtimeMini,
			};
		if (type === "airtimeMax")
			datum = {
				airtimeMax: airtimeMax || stateData?.airtimeMax,
			};
		if (type === "electricityMini")
			datum = {
				electricityMini: electricityMini || stateData?.electricityMini,
			};
		if (type === "electricityMax")
			datum = {
				electricityMax: electricityMax || stateData?.electricityMax,
			};
		if (type === "airtimeToCashMini")
			datum = {
				airtimeToCashMini: airtimeToCashMini
					? airtimeToCashMini
					: stateData?.airtimeToCashMini,
			};
		if (type === "airtimeToCashMax")
			datum = {
				airtimeToCashMax: airtimeToCashMax
					? airtimeToCashMax
					: stateData?.airtimeToCashMax,
			};
		if (type === "cardFundingMini")
			datum = {
				cardFundingMini: cardFundingMini
					? cardFundingMini
					: stateData?.cardFundingMini,
			};
		if (type === "cardFundingMax")
			datum = {
				cardFundingMax: cardFundingMax
					? cardFundingMax
					: stateData?.cardFundingMax,
			};
		if (type === "transferFundingMini")
			datum = {
				transferFundingMini: transferFundingMini
					? transferFundingMini
					: stateData?.transferFundingMini,
			};
		if (type === "transferFundingMax")
			datum = {
				transferFundingMax: transferFundingMax
					? transferFundingMax
					: stateData?.transferFundingMax,
			};
		if (type === "airtimeToCash")
			datum = {
				airtimeToCash: airtimeToCash ? airtimeToCash : stateData?.airtimeToCash,
			};
		if (type === "autoFund")
			datum = {
				autoFund: autoFund ? autoFund : stateData?.autoFund,
			};
		if (type === "fundWalletFlutterwave")
			datum = {
				fundWalletFlutterwave: fundWalletFlutterwave
					? fundWalletFlutterwave
					: stateData?.fundWalletFlutterwave,
			};
		if (type === "fundWalletPaystack")
			datum = {
				fundWalletPaystack: fundWalletPaystack
					? fundWalletPaystack
					: stateData?.fundWalletPaystack,
			};
		if (type === "bizReseller")
			datum = {
				bizReseller: bizReseller || stateData?.bizReseller,
			};
		if (type === "bizAgent")
			datum = {
				bizAgent: bizAgent || stateData?.bizAgent,
			};
		if (type === "bizUser")
			datum = {
				bizUser: bizUser || stateData?.bizUser,
			};
		if (type === "verifyReseller")
			datum = {
				verifyReseller: verifyReseller || stateData?.verifyReseller,
			};
		if (type === "verifyAgent")
			datum = {
				verifyAgent: verifyAgent || stateData?.verifyAgent,
			};
		if (type === "verifyUser")
			datum = {
				verifyUser: verifyUser || stateData?.verifyUser,
			};
		if (type === "fundWalletBudpay")
			datum = {
				fundWalletBudpay: fundWalletBudpay
					? fundWalletBudpay
					: stateData?.fundWalletBudpay,
			};
		if (type === "apiStatus")
			datum = {
				apiStatus: apiStatus || stateData?.apiStatus,
			};
		if (type === "ninBvnRestriction")
			datum = {
				ninBvnRestriction: ninBvnRestriction || stateData?.ninBvnRestriction,
			};
		if (type === "cgPurchaseStatus")
			datum = {
				cgPurchaseStatus: cgPurchaseStatus || stateData?.cgPurchaseStatus,
			};
		if (type === "cgStatus")
			datum = {
				cgStatus: cgStatus || stateData?.cgStatus,
			};
		if (type === "appStatus")
			datum = {
				appStatus: appStatus || stateData?.appStatus,
			};
		if (type === "webStatus")
			datum = {
				webStatus: webStatus || stateData?.webStatus,
			};
		if (type === "adminStatus")
			datum = {
				adminStatus: adminStatus || stateData?.adminStatus,
			};
		if (type === "fundWalletMonnify")
			datum = {
				fundWalletMonnify: fundWalletMonnify
					? fundWalletMonnify
					: stateData?.fundWalletMonnify,
			};
		if (type === "referralBonus")
			datum = {
				referralBonus: referralBonus ? referralBonus : stateData?.referralBonus,
			};
		if (type === "referralBonusType")
			datum = {
				referralBonusType: referralBonusType
					? referralBonusType
					: stateData?.referralBonusType,
			};
		if (type === "referralBonusFixed")
			datum = {
				referralBonusFixed: referralBonusFixed
					? referralBonusFixed
					: stateData?.referralBonusFixed,
			};
		if (type === "referralBonusLimit")
			datum = {
				referralBonusLimit: referralBonusLimit
					? referralBonusLimit
					: stateData?.referralBonusLimit,
			};
		if (type === "referralBonusValue")
			datum = {
				referralBonusValue: referralBonusValue
					? referralBonusValue
					: stateData?.referralBonusValue,
			};
		if (type === "withdrawal")
			datum = {
				withdrawal: withdrawal || stateData?.withdrawal,
			};
		if (type === "autobuy")
			datum = {
				autobuy: autobuy || stateData?.autobuy,
			};
		if (type === "trackingMinimum")
			datum = {
				trackingMinimum: trackingMinimum || stateData?.trackingMinimum,
			};
		if (type === "inactiveAccountMetricsType")
			datum = {
				inactiveAccountMetricsType:
					inactiveAccountMetricsType || stateData?.inactiveAccountMetricsType,
			};
		if (type === "inactiveAccountMetricsValue")
			datum = {
				inactiveAccountMetricsValue:
					inactiveAccountMetricsValue || stateData?.inactiveAccountMetricsValue,
			};
		if (type === "dormantAccountMetricsType")
			datum = {
				dormantAccountMetricsType:
					dormantAccountMetricsType || stateData?.dormantAccountMetricsType,
			};
		if (type === "dormantAccountMetricsValue")
			datum = {
				dormantAccountMetricsValue:
					dormantAccountMetricsValue || stateData?.dormantAccountMetricsValue,
			};
		if (type === "duplicateTransactionTimer")
			datum = {
				duplicateTransactionTimer:
					duplicateTransactionTimer || stateData?.duplicateTransactionTimer,
			};
		if (type === "duplicateTransactionTimerStatus")
			datum = {
				duplicateTransactionTimerStatus:
					duplicateTransactionTimerStatus ||
					stateData?.duplicateTransactionTimerStatus,
			};
		if (type === "deactivationDuration")
			datum = {
				deactivationDuration:
					deactivationDuration || stateData?.deactivationDuration,
			};
		if (type === "fundWalletCharges")
			datum = {
				fundWalletCharges: fundWalletCharges || stateData?.fundWalletCharges,
			};
		if (type === "bvnCharge")
			datum = {
				bvnCharge: bvnCharge || stateData?.bvnCharge,
			};
		if (type === "ninCharge")
			datum = {
				ninCharge: ninCharge || stateData?.ninCharge,
			};
		if (type === "bizLogo") {
			if (stateData?.bizLogo && stateData?.bizLogo?._id) return;
			setLoadingType(type);
			setLoading(true);
			let media = await imageUpload([stateData.bizLogo]);
			datum = {
				bizLogo: media?.[0],
			};
		}
		if (type === "verifyLogo") {
			if (stateData?.verifyLogo && stateData?.verifyLogo?._id) return;
			setLoadingType(type);
			setLoading(true);
			let media = await imageUpload([stateData.verifyLogo]);
			datum = {
				verifyLogo: media?.[0],
			};
		}
		console.log({ datum, type });
		setLoadingType(type);
		setLoading(true);
		await getUseCase(datum);
		setLoading(false);
		setLoadingType(false);
		setSubmit(false);
	};

	return (
		<Container className="px-lg-5  pt-3 pt-lg-0">
			<div className="mb-5">
				<h5 className="Lexend fw-600 fontReduceBig">General Usecase</h5>
			</div>
			{stateData && (
				<div className="row mx-0 g-3 g-md-4">
					<div
						className={`col-md-6 fontReduce text-capitalize ${
							stateData?.airtime === "enable"
								? "text-success2 text-success-2 text-success"
								: "text-danger2"
						} d-flex align-items-center justify-content-between px-3 px-md-5`}>
						<span>Airtime: {`${stateData?.airtime}d`}</span>
						<GenCheckSwitch state={airtime} type={"airtime"} />
					</div>
					<div
						className={`col-md-6 fontReduce text-capitalize ${
							stateData?.cables === "enable"
								? "text-success2 text-success-2 text-success"
								: "text-danger2"
						} d-flex align-items-center justify-content-between px-3 px-md-5`}>
						<span>Cables: {`${stateData?.cables}d`}</span>
						<GenCheckSwitch state={cables} type={"cables"} />
					</div>
					<div
						className={`col-md-6 fontReduce text-capitalize ${
							stateData?.education === "enable"
								? "text-success2 text-success-2 text-success"
								: "text-danger2"
						} d-flex align-items-center justify-content-between px-3 px-md-5`}>
						<span>Education: {`${stateData?.education}d`}</span>
						<GenCheckSwitch state={education} type={"education"} />
					</div>
					<div
						className={`col-md-6 fontReduce text-capitalize ${
							stateData?.electricity === "enable"
								? "text-success2 text-success-2 text-success"
								: "text-danger2"
						} d-flex align-items-center justify-content-between px-3 px-md-5`}>
						<span>Electricity: {`${stateData?.electricity}d`}</span>
						<GenCheckSwitch state={electricity} type={"electricity"} />
					</div>
					<div
						className={`col-md-6 fontReduce text-capitalize ${
							stateData?.data === "enable"
								? "text-success2 text-success-2 text-success"
								: "text-danger2"
						} d-flex align-items-center justify-content-between px-3 px-md-5`}>
						<span>Data: {`${stateData?.data}d`}</span>
						<GenCheckSwitch state={data} type={"data"} />
					</div>
					<div
						className={`col-md-6 fontReduce text-capitalize ${
							stateData?.transferFund === "enable"
								? "text-success2 text-success-2 text-success"
								: "text-danger2"
						} d-flex align-items-center justify-content-between px-3 px-md-5`}>
						<span>
							wallet to wallet Transfer fund: {`${stateData?.transferFund}d`}
						</span>
						<GenCheckSwitch state={transferFund} type={"transferFund"} />
					</div>
					<div
						className={`col-md-6 fontReduce text-capitalize ${
							stateData?.login === "enable"
								? "text-success2 text-success-2 text-success"
								: "text-danger2"
						} d-flex align-items-center justify-content-between px-3 px-md-5`}>
						<span>Login: {`${stateData?.login}d`}</span>
						<GenCheckSwitch state={login} type={"login"} />
					</div>
					<div
						className={`col-md-6 fontReduce text-capitalize ${
							stateData?.registration === "enable"
								? "text-success2 text-success-2 text-success"
								: "text-danger2"
						} d-flex align-items-center justify-content-between px-3 px-md-5`}>
						<span>Registration: {`${stateData?.registration}d`}</span>
						<GenCheckSwitch state={registration} type={"registration"} />
					</div>
					<div
						className={`col-md-6 fontReduce text-capitalize ${
							stateData?.otpRegistration === "enable"
								? "text-success2 text-success-2 text-success"
								: "text-danger2"
						} d-flex align-items-center justify-content-between px-3 px-md-5`}>
						<span>
							Use OTP for Registration: {`${stateData?.otpRegistration}d`}
						</span>
						<GenCheckSwitch state={otpRegistration} type={"otpRegistration"} />
					</div>
					<div
						className={`col-md-6 fontReduce text-capitalize ${
							stateData?.fundWallet === "enable"
								? "text-success2 text-success-2 text-success"
								: "text-danger2"
						} d-flex align-items-center justify-content-between px-3 px-md-5`}>
						<span>card Fund wallet: {`${stateData?.fundWallet}d`}</span>
						<GenCheckSwitch state={fundWallet} type={"fundWallet"} />
					</div>
					{/* <div
						className={`col-md-6 fontReduce text-capitalize ${
							stateData?.autoFund === "enable"
								? "text-success2 text-success-2 text-success"
								: "text-danger2"
						} d-flex align-items-center justify-content-between px-3 px-md-5`}>
						<span>Automatic wallet funding: {`${stateData?.autoFund}d`}</span>
						<GenCheckSwitch state={autoFund} type={"autoFund"} />
					</div> */}
					<div
						className={`col-md-6 fontReduce text-capitalize ${
							stateData?.airtimeToCash === "enable"
								? "text-success2 text-success-2 text-success"
								: "text-danger2"
						} d-flex align-items-center justify-content-between px-3 px-md-5`}>
						<span>Airtime to cash: {`${stateData?.airtimeToCash}d`}</span>
						<GenCheckSwitch state={airtimeToCash} type={"airtimeToCash"} />
					</div>
					<div
						className={`col-md-6 fontReduce text-capitalize ${
							stateData?.withdrawal === "enable"
								? "text-success2 text-success-2 text-success"
								: "text-danger2"
						} d-flex align-items-center justify-content-between px-3 px-md-5`}>
						<span>Withdrawal: {`${stateData?.withdrawal}d`}</span>
						<GenCheckSwitch state={withdrawal} type={"withdrawal"} />
					</div>
					<div
						className={`col-md-6 fontReduce text-capitalize ${
							stateData?.autobuy === "enable"
								? "text-success2 text-success-2 text-success"
								: "text-danger2"
						} d-flex align-items-center justify-content-between px-3 px-md-5`}>
						<span>Auto Buy: {`${stateData?.autobuy}d`}</span>
						<GenCheckSwitch state={autobuy} type={"autobuy"} />
					</div>
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>Minimum Wallet Tracking Amount:</span>
						<span>
							{nairaSign}{" "}
							{numberWithCommas(Number(stateData?.trackingMinimum).toFixed(2))}
						</span>
					</div>
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>Minimum airtime purchase:</span>
						<span>
							{nairaSign}{" "}
							{numberWithCommas(Number(stateData?.airtimeMini).toFixed(2))}
						</span>
					</div>
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>Maximum airtime purchase:</span>
						<span>
							{nairaSign}{" "}
							{numberWithCommas(Number(stateData?.airtimeMax).toFixed(2))}
						</span>
					</div>
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>Minimum electricity purchase:</span>
						<span>
							{nairaSign}{" "}
							{numberWithCommas(Number(stateData?.electricityMini).toFixed(2))}
						</span>
					</div>
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>Maximum electricity purchase:</span>
						<span>
							{nairaSign}{" "}
							{numberWithCommas(Number(stateData?.electricityMax).toFixed(2))}
						</span>
					</div>
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>Minimum Card funding:</span>
						<span>
							{nairaSign}{" "}
							{numberWithCommas(Number(stateData?.cardFundingMini).toFixed(2))}
						</span>
					</div>
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>Maximum Card funding:</span>
						<span>
							{nairaSign}{" "}
							{numberWithCommas(Number(stateData?.cardFundingMax).toFixed(2))}
						</span>
					</div>
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>Minimum Transfer funding:</span>
						<span>
							{nairaSign}{" "}
							{numberWithCommas(
								Number(stateData?.transferFundingMini).toFixed(2)
							)}
						</span>
					</div>
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>Maximum Transfer funding:</span>
						<span>
							{nairaSign}{" "}
							{numberWithCommas(
								Number(stateData?.transferFundingMax).toFixed(2)
							)}
						</span>
					</div>
					<p className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						Transfer Fund limit:{" "}
						{numberWithCommas(Number(stateData?.transferFundLimit))}
					</p>
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>Minimum airtime to cash conversion:</span>
						<span>
							{nairaSign}{" "}
							{numberWithCommas(
								Number(stateData?.airtimeToCashMini).toFixed(2)
							)}
						</span>
					</div>
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>Maximum airtime to cash conversion:</span>
						<span>
							{nairaSign}{" "}
							{numberWithCommas(Number(stateData?.airtimeToCashMax).toFixed(2))}
						</span>
					</div>
					<div
						className={`col-md-6 fontReduce text-capitalize ${
							stateData?.resellerUpgrade === "enable"
								? "text-success2 text-success-2 text-success"
								: "text-danger2"
						} d-flex align-items-center justify-content-between px-3 px-md-5`}>
						<span>Reseller upgrade: {`${stateData?.resellerUpgrade}d`}</span>
						<GenCheckSwitch state={resellerUpgrade} type={"resellerUpgrade"} />
					</div>
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>Reseller upgrade fee:</span>
						<span>
							{nairaSign}{" "}
							{numberWithCommas(
								Number(stateData?.resellerUpgradeFee).toFixed(2)
							)}
						</span>
					</div>
					<div
						className={`col-md-6 fontReduce text-capitalize ${
							stateData?.agentUpgrade === "enable"
								? "text-success2 text-success-2 text-success"
								: "text-danger2"
						} d-flex align-items-center justify-content-between px-3 px-md-5`}>
						<span>Agent upgrade: {`${stateData?.agentUpgrade}d`}</span>
						<GenCheckSwitch state={agentUpgrade} type={"agentUpgrade"} />
					</div>
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>Agent upgrade fee:</span>
						<span>
							{nairaSign}{" "}
							{numberWithCommas(Number(stateData?.agentUpgradeFee).toFixed(2))}
						</span>
					</div>
					{/* <div
						className={`col-md-6 fontReduce text-capitalize ${
							stateData?.topuserUpgrade === "enable"
								? "text-success2 text-success-2 text-success"
								: "text-danger2"
						} d-flex align-items-center justify-content-between px-3 px-md-5`}>
						<span>Topuser upgrade: {`${stateData?.topuserUpgrade}d`}</span>
						<GenCheckSwitch state={topuserUpgrade} type={"topuserUpgrade"} />
					</div> */}
					{/* <div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>Topuser upgrade fee:</span>
						<span>
							{nairaSign}{" "}
							{numberWithCommas(
								Number(stateData?.topuserUpgradeFee).toFixed(2)
							)}
						</span>
					</div> */}
					<div
						className={`col-md-6 fontReduce text-capitalize ${
							stateData?.fundWalletFlutterwave === "enable"
								? "text-success2 text-success-2 text-success"
								: "text-danger2"
						} d-flex align-items-center justify-content-between px-3 px-md-5`}>
						<span>
							Fund wallet through Flutterwave:{" "}
							{`${stateData?.fundWalletFlutterwave}d`}
						</span>
						<GenCheckSwitch
							state={fundWalletFlutterwave}
							type={"fundWalletFlutterwave"}
						/>
					</div>
					<div
						className={`col-md-6 fontReduce text-capitalize ${
							stateData?.fundWalletPaystack === "enable"
								? "text-success2 text-success-2 text-success"
								: "text-danger2"
						} d-flex align-items-center justify-content-between px-3 px-md-5`}>
						<span>
							Fund wallet through Paystack:{" "}
							{`${stateData?.fundWalletPaystack}d`}
						</span>
						<GenCheckSwitch
							state={fundWalletPaystack}
							type={"fundWalletPaystack"}
						/>
					</div>
					<div
						className={`col-md-6 fontReduce text-capitalize ${
							stateData?.fundWalletBudpay === "enable"
								? "text-success2 text-success-2 text-success"
								: "text-danger2"
						} d-flex align-items-center justify-content-between px-3 px-md-5`}>
						<span>
							Fund wallet through Budpay: {`${stateData?.fundWalletBudpay}d`}
						</span>
						<GenCheckSwitch
							state={fundWalletBudpay}
							type={"fundWalletBudpay"}
						/>
					</div>
					<div
						className={`col-md-6 fontReduce text-capitalize ${
							stateData?.ninBvnRestriction === "enable"
								? "text-success2 text-success-2 text-success"
								: "text-danger2"
						} d-flex align-items-center justify-content-between px-3 px-md-5`}>
						<span>
							NIN/BVN Restriction: {`${stateData?.ninBvnRestriction}d`}
						</span>
						<GenCheckSwitch
							state={ninBvnRestriction}
							type={"ninBvnRestriction"}
						/>
					</div>
					<div
						className={`col-md-6 fontReduce text-capitalize ${
							stateData?.cgStatus === "enable"
								? "text-success2 text-success-2 text-success"
								: "text-danger2"
						} d-flex align-items-center justify-content-between px-3 px-md-5`}>
						<span>CG Wallet Usage: {`${stateData?.cgStatus}d`}</span>
						<GenCheckSwitch state={cgStatus} type={"cgStatus"} />
					</div>
					<div
						className={`col-md-6 fontReduce text-capitalize ${
							stateData?.cgPurchaseStatus === "enable"
								? "text-success2 text-success-2 text-success"
								: "text-danger2"
						} d-flex align-items-center justify-content-between px-3 px-md-5`}>
						<span>CG Wallet Purchase: {`${stateData?.cgPurchaseStatus}d`}</span>
						<GenCheckSwitch
							state={cgPurchaseStatus}
							type={"cgPurchaseStatus"}
						/>
					</div>
					<div
						className={`col-md-6 fontReduce text-capitalize ${
							stateData?.apiStatus === "enable"
								? "text-success2 text-success-2 text-success"
								: "text-danger2"
						} d-flex align-items-center justify-content-between px-3 px-md-5`}>
						<span>API Request: {`${stateData?.apiStatus}d`}</span>
						<GenCheckSwitch state={apiStatus} type={"apiStatus"} />
					</div>
					<div
						className={`col-md-6 fontReduce text-capitalize ${
							stateData?.appStatus === "enable"
								? "text-success2 text-success-2 text-success"
								: "text-danger2"
						} d-flex align-items-center justify-content-between px-3 px-md-5`}>
						<span>APP Request: {`${stateData?.appStatus}d`}</span>
						<GenCheckSwitch state={appStatus} type={"appStatus"} />
					</div>
					<div
						className={`col-md-6 fontReduce text-capitalize ${
							stateData?.webStatus === "enable"
								? "text-success2 text-success-2 text-success"
								: "text-danger2"
						} d-flex align-items-center justify-content-between px-3 px-md-5`}>
						<span>WEB Request: {`${stateData?.webStatus}d`}</span>
						<GenCheckSwitch state={webStatus} type={"webStatus"} />
					</div>
					{auth?.user?.privilege === "superadmin" && (
						<div
							className={`col-md-6 fontReduce text-capitalize ${
								stateData?.adminStatus === "enable"
									? "text-success2 text-success-2 text-success"
									: "text-danger2"
							} d-flex align-items-center justify-content-between px-3 px-md-5`}>
							<span>ADMIN Request: {`${stateData?.adminStatus}d`}</span>
							<GenCheckSwitch state={adminStatus} type={"adminStatus"} />
						</div>
					)}
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>Budpay Charges: </span>
						<span>
							{nairaSign}{" "}
							{numberWithCommas(
								Number(stateData?.fundWalletCharges).toFixed(2)
							)}{" "}
						</span>
					</div>
					<div
						className={`col-md-6 fontReduce text-capitalize ${
							stateData?.fundWalletMonnify === "enable"
								? "text-success2 text-success-2 text-success"
								: "text-danger2"
						} d-flex align-items-center justify-content-between px-3 px-md-5`}>
						<span>
							Monnify Visibility: {`${stateData?.fundWalletMonnify}d`}
						</span>
						<GenCheckSwitch
							state={fundWalletMonnify}
							type={"fundWalletMonnify"}
						/>
					</div>
					<div
						className={`col-md-6 fontReduce text-capitalize ${
							stateData?.referralBonus === "enable"
								? "text-success2 text-success-2 text-success"
								: "text-danger2"
						} d-flex align-items-center justify-content-between px-3 px-md-5`}>
						<span>Referral Bonus: {`${stateData?.referralBonus}d`}</span>
						<GenCheckSwitch state={referralBonus} type={"referralBonus"} />
					</div>
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>Referral bonus means:</span>
						<span>{stateData?.referralBonusType}</span>
					</div>
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>Referral bonus type:</span>
						<span>{stateData?.referralBonusFixed}</span>
					</div>
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>Referral bonus amount: </span>
						<span>
							{stateData?.referralBonusFixed === "fixed" ? nairaSign : null}{" "}
							{numberWithCommas(
								Number(stateData?.referralBonusValue).toFixed(2)
							)}{" "}
							{stateData?.referralBonusFixed === "percentage" ? "%" : null}
						</span>
					</div>
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>Referral bonus limit: </span>
						<span>
							{numberWithCommas(Number(stateData?.referralBonusLimit))}
						</span>
					</div>
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>Verify Biz User Fee:</span>
						<span>
							{nairaSign}{" "}
							{numberWithCommas(Number(stateData?.bizUser).toFixed(2))}
						</span>
					</div>
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>Verify Biz Reseller Fee:</span>
						<span>
							{nairaSign}{" "}
							{numberWithCommas(Number(stateData?.bizReseller).toFixed(2))}
						</span>
					</div>
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>Verify Biz Agent Fee:</span>
						<span>
							{nairaSign}{" "}
							{numberWithCommas(Number(stateData?.bizAgent).toFixed(2))}
						</span>
					</div>
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>Verify NIN User Fee:</span>
						<span>
							{nairaSign}{" "}
							{numberWithCommas(Number(stateData?.verifyUser).toFixed(2))}
						</span>
					</div>
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>Verify NIN Reseller Fee:</span>
						<span>
							{nairaSign}{" "}
							{numberWithCommas(Number(stateData?.verifyReseller).toFixed(2))}
						</span>
					</div>
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>Verify NIN Agent Fee:</span>
						<span>
							{nairaSign}{" "}
							{numberWithCommas(Number(stateData?.verifyAgent).toFixed(2))}
						</span>
					</div>
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>Inactive Account Metrics type:</span>
						<span>{stateData?.inactiveAccountMetricsType}</span>
					</div>
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>Inactive Account Metrics Value:</span>
						<span>
							{" "}
							{numberWithCommas(
								Number(stateData?.inactiveAccountMetricsValue).toFixed(0)
							)}
						</span>
					</div>
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>Dormant Account Metrics type:</span>
						<span>{stateData?.dormantAccountMetricsType}</span>
					</div>
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>Dormant Account Metrics Value:</span>
						<span>
							{" "}
							{numberWithCommas(
								Number(stateData?.dormantAccountMetricsValue).toFixed(0)
							)}
						</span>
					</div>
					<div
						className={`col-md-6 fontReduce text-capitalize ${
							stateData?.duplicateTransactionTimerStatus === "enable"
								? "text-success2 text-success-2 text-success"
								: "text-danger2"
						} d-flex align-items-center justify-content-between px-3 px-md-5`}>
						<span>
							Duplicate Transaction timer status:{" "}
							{`${stateData?.duplicateTransactionTimerStatus}d`}
						</span>
						<GenCheckSwitch
							state={duplicateTransactionTimerStatus}
							type={"duplicateTransactionTimerStatus"}
						/>
					</div>
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>Duplicate Transaction timer(mins):</span>
						<span>
							{" "}
							{numberWithCommas(
								Number(stateData?.duplicateTransactionTimer).toFixed(0)
							)}
						</span>
					</div>
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>Login deactivation duration(hours):</span>
						<span>
							{" "}
							{numberWithCommas(
								Number(stateData?.deactivationDuration).toFixed(0)
							)}
						</span>
					</div>
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>BVN Linking Charges: </span>
						<span>
							{nairaSign}{" "}
							{numberWithCommas(Number(stateData?.bvnCharge).toFixed(2))}{" "}
						</span>
					</div>
					<div className="col-md-6 fontReduce text-capitalize d-flex align-items-center justify-content-between px-3 px-md-5">
						<span>NIN Linking Charges: </span>
						<span>
							{nairaSign}{" "}
							{numberWithCommas(Number(stateData?.ninCharge).toFixed(2))}{" "}
						</span>
					</div>
				</div>
			)}
			<div className="row mx-0 g-4 pt-5">
				{/* <GenCheck
					state={airtime}
					setState={setAirtime}
					type={"airtime"}
					label="airtime"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
				/> */}
				{/* <GenCheck
					state={cables}
					setState={setCables}
					type={"cables"}
					label="cables"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
				/> */}
				{/* <GenCheck
					state={education}
					setState={setEducation}
					type={"education"}
					label="education"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
				/> */}
				{/* <GenCheck
					state={electricity}
					setState={setElectricity}
					type={"electricity"}
					label="electricity"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
				/> */}
				{/* <GenCheck
					state={data}
					setState={setData}
					type={"data"}
					label="data"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
				/> */}
				{/* <GenCheck
					state={registration}
					setState={setRegistration}
					type={"registration"}
					label="registration"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
				/> */}
				{/* <GenCheck
					state={login}
					setState={setLogin}
					type={"login"}
					label="login"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
				/> */}
				{/* <GenCheck
					state={otpRegistration}
					setState={setOtpRegistration}
					type={"otpRegistration"}
					label="Otp registration"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
				/> */}
				{/* <GenCheck
					state={fundWallet}
					setState={setFundWallet}
					type={"fundWallet"}
					label="Card Fund Wallet"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
				/> */}
				{/* <GenCheck
					state={transferFund}
					setState={setTransferFund}
					type={"transferFund"}
					label="wallet to wallet Transfer fund"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
				/> */}
				{/* <GenCheck
					state={autoFund}
					setState={setAutoFund}
					type={"autoFund"}
					label="automatic wallet funding"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
				/> */}
				{/* <GenCheck
					state={airtimeToCash}
					setState={setAirtimeToCash}
					type={"airtimeToCash"}
					label="Airtime to cash"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
				/> */}
				<GenCheck
					state={trackingMinimum}
					setState={setTrackingMinimum}
					type={"trackingMinimum"}
					label="Minimum Wallet Tracking Amount"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
				/>
				<GenCheck
					state={airtimeMini}
					setState={setAirtimeMini}
					type={"airtimeMini"}
					label="Minimum Airtime purchase"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
				/>
				<GenCheck
					state={airtimeMax}
					setState={setAirtimeMax}
					type={"airtimeMax"}
					label="Maximum Airtime purchase"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
				/>
				<GenCheck
					state={electricityMini}
					setState={setElectricityMini}
					type={"electricityMini"}
					label="Minimum Electricity purchase"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
				/>
				<GenCheck
					state={electricityMax}
					setState={setElectricityMax}
					type={"electricityMax"}
					label="Maximum Electricity purchase"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
				/>
				<GenCheck
					state={airtimeToCashMini}
					setState={setAirtimeToCashMini}
					type={"airtimeToCashMini"}
					label="Minimum Airtime to cash conversion"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
				/>
				<GenCheck
					state={airtimeToCashMax}
					setState={setAirtimeToCashMax}
					type={"airtimeToCashMax"}
					label="Maximum Airtime to cash conversion"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
				/>
				<GenCheck
					state={cardFundingMini}
					setState={setcardFundingMini}
					type={"cardFundingMini"}
					label="Minimum Card Funding"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
				/>
				<GenCheck
					state={cardFundingMax}
					setState={setcardFundingMax}
					type={"cardFundingMax"}
					label="Maximum Card Funding"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
				/>
				<GenCheck
					state={transferFundingMini}
					setState={settransferFundingMini}
					type={"transferFundingMini"}
					label="Minimum transfer Funding"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
				/>
				<GenCheck
					state={transferFundingMax}
					setState={settransferFundingMax}
					type={"transferFundingMax"}
					label="Maximum transfer Funding"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
				/>
				<GenCheck
					state={transferFundLimit}
					setState={setTransferFundLimit}
					type={"transferFundLimit"}
					label="transfer Fund Limit"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					noFormat
				/>
				{/* <GenCheck
					state={resellerUpgrade}
					setState={setResellerUpgrade}
					type={"resellerUpgrade"}
					label="Reseller upgrade"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
				/> */}
				<GenCheck
					state={resellerUpgradeFee}
					setState={setResellerUpgradeFee}
					type={"resellerUpgradeFee"}
					label="Reseller upgrade fee"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
				/>
				<GenCheck
					state={agentUpgradeFee}
					setState={setAgentUpgradeFee}
					type={"agentUpgradeFee"}
					label="Agent upgrade fee"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
				/>
				{/* <GenCheck
					state={topuserUpgrade}
					setState={setTopUserUpgrade}
					type={"topuserUpgrade"}
					label="Topuser upgrade"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
				/> */}
				{/* <GenCheck
					state={topuserUpgradeFee}
					setState={setTopUserUpgradeFee}
					type={"topuserUpgradeFee"}
					label="Topuser upgrade fee"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
				/> */}
				{/* <GenCheck
					state={fundWalletFlutterwave}
					setState={setfundWalletFlutterwave}
					type={"fundWalletFlutterwave"}
					label="Fund wallet through Flutterwave"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
				/> */}
				{/* <GenCheck
					state={fundWalletPaystack}
					setState={setfundWalletPaystack}
					type={"fundWalletPaystack"}
					label="Fund wallet through Paystack"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
				/> */}
				{/* <GenCheck
					state={fundWalletBudpay}
					setState={setfundWalletBudpay}
					type={"fundWalletBudpay"}
					label="Fund wallet through Budpay"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
				/> */}
				{/* <GenCheck
					state={fundWalletMonnify}
					setState={setfundWalletMonnify}
					type={"fundWalletMonnify"}
					label="Monnify Visibility"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
				/> */}
				{/* <GenCheck
					state={referralBonus}
					setState={setreferralBonus}
					type={"referralBonus"}
					label="Referral Bonus"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
				/> */}
				<GenCheck
					state={referralBonusType}
					setState={setreferralBonusType}
					type={"referralBonusType"}
					label="Referral Bonus Means"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					arr={[
						{ value: "wallet", title: "Wallet" },
						{ value: "transaction", title: "Transaction" },
					]}
				/>
				<GenCheck
					state={referralBonusFixed}
					setState={setreferralBonusFixed}
					type={"referralBonusFixed"}
					label="Referral Bonus Type"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					arr={[
						{ value: "fixed", title: "Fixed" },
						{ value: "percentage", title: "Percentage" },
					]}
				/>
				<GenCheck
					state={referralBonusValue}
					setState={setreferralBonusValue}
					type={"referralBonusValue"}
					label="Referral bonus Value"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					percentage={referralBonusFixed === "percentage"}
				/>
				<GenCheck
					state={referralBonusLimit}
					setState={setreferralBonusLimit}
					type={"referralBonusLimit"}
					label="Referral bonus limit"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					noFormat
				/>
				<GenCheck
					state={bizUser}
					setState={setBizUser}
					type={"bizUser"}
					label="Biz User fee"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
				/>
				<GenCheck
					state={bizReseller}
					setState={setBizReseller}
					type={"bizReseller"}
					label="Biz Reseller fee"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
				/>
				<GenCheck
					state={bizAgent}
					setState={setBizAgent}
					type={"bizAgent"}
					label="Biz Agent fee"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
				/>
				<GenCheck
					state={verifyUser}
					setState={setverifyUser}
					type={"verifyUser"}
					label="verify User fee"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
				/>
				<GenCheck
					state={verifyReseller}
					setState={setverifyReseller}
					type={"verifyReseller"}
					label="verify Reseller fee"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
				/>
				<GenCheck
					state={verifyAgent}
					setState={setverifyAgent}
					type={"verifyAgent"}
					label="verify Agent fee"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
				/>
				<GenCheck
					state={inactiveAccountMetricsType}
					setState={setinactiveAccountMetricsType}
					type={"inactiveAccountMetricsType"}
					label="Inactive Account Metrics Type"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					arr={metricArr}
				/>
				<GenCheck
					state={inactiveAccountMetricsValue}
					setState={setinactiveAccountMetricsValue}
					type={"inactiveAccountMetricsValue"}
					label="Inactive Account Metrics Value"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					noFormat
				/>
				<GenCheck
					state={dormantAccountMetricsType}
					setState={setdormantAccountMetricsType}
					type={"dormantAccountMetricsType"}
					label="Dormant Account Metrics Type"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					arr={metricArr}
				/>
				<GenCheck
					state={dormantAccountMetricsValue}
					setState={setdormantAccountMetricsValue}
					type={"dormantAccountMetricsValue"}
					label="Dormant Account Metrics Value"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					noFormat
				/>
				<GenCheck
					state={duplicateTransactionTimer}
					setState={setduplicateTransactionTimer}
					type={"duplicateTransactionTimer"}
					label="Duplicate Transaction Timer (mins)"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					noFormat
				/>
				<GenCheck
					state={deactivationDuration}
					setState={setdeactivationDuration}
					type={"deactivationDuration"}
					label="Login Deactivation Duration (hours)"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
					noFormat
				/>
				<GenCheck
					state={fundWalletCharges}
					setState={setfundWalletCharges}
					type={"fundWalletCharges"}
					label="Budpay Charges"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
				/>
				<GenCheck
					state={bvnCharge}
					setState={setbvnCharge}
					type={"bvnCharge"}
					label="BVN Linking Charges"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
				/>
				<GenCheck
					state={ninCharge}
					setState={setninCharge}
					type={"ninCharge"}
					label="NIN Linking Charges"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
					input
				/>
				<UpdateBizLogo
					state={stateData}
					setState={setStateData}
					type={"bizLogo"}
					label="Business Verification Logo"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
				/>
				<UpdateBizLogo
					state={stateData}
					setState={setStateData}
					type={"verifyLogo"}
					label="NIN Verification Logo"
					loading={loading}
					loadingType={loadingType}
					handleSubmit={handleSubmit}
				/>
			</div>
		</Container>
	);
};

let GenCheck = ({
	state,
	setState,
	type,
	loading,
	loadingType,
	label,
	handleSubmit,
	input,
	percentage,
	arr,
	noFormat,
	text,
}) => {
	let { nairaSignNeutral } = useContext(GlobalState);
	return (
		<div className="mb-3 d-flex justify-content-center col-md-6">
			<div className="w-75 w75">
				<label
					htmlFor="Price"
					className="mb-3 textColor2 text-capitalize fontReduce">
					{label}
				</label>
				{text ? (
					<input
						className="form-control w-100 py-3 borderColor"
						value={state}
						onChange={val => setState(val?.target.value)}
						placeholder="3.0.2"
					/>
				) : input ? (
					<>
						<NumericFormat
							prefix={noFormat ? "" : !percentage ? `${nairaSignNeutral} ` : ""}
							suffix={noFormat ? "" : percentage ? ` %` : ""}
							className="form-control w-100 py-3 borderColor"
							value={state}
							placeholder={noFormat ? "2" : percentage ? "2%" : "2,000"}
							displayType="input"
							thousandSeparator={true}
							onValueChange={val => setState(val?.floatValue)}
							min={0}
							inputMode={"decimal"}
							renderText={(value, props) => <span {...props}>{value}</span>}
							allowNegative={false}
						/>
					</>
				) : (
					<select
						type="number"
						name="Price"
						className="form-control w-100 py-3 borderColor form-select"
						placeholder="Enable"
						value={state}
						onChange={e => setState(e.target.value)}>
						<option value="">Select one</option>
						{arr ? (
							arr?.map((item, i) => (
								<option value={item?.value} key={i}>
									{item?.title}
								</option>
							))
						) : (
							<>
								<option value="enable">Enabled</option>
								<option value="disable">Disabled</option>
							</>
						)}
					</select>
				)}
				<Buttons
					loading={loadingType === type && loading}
					title="update"
					css="btn btn-primary1 text-capitalize py-3 w-75 w75 d-block mx-auto my-4"
					width={"w-75 w75"}
					onClick={handleSubmit(type)}
				/>
			</div>
		</div>
	);
};

export let GenCheckSwitch = ({ state, type }) => {
	let { getUseCase } = useContext(GlobalState);

	return (
		<>
			<Switch
				// onChange={handleSubmit(type)}
				onChange={async () => {
					await getUseCase({
						[type]: state === "enable" ? "disable" : "enable",
					});
				}}
				checked={state === "enable"}
				onColor="#28a745"
				offColor="#dc3545"
			/>
		</>
	);
};

export const ProfileForm = ({ state, textChange, setState }) => {
	let params = useParams(),
		{ auth, usecase, nairaSign, nairaSignNeutral } = useContext(GlobalState),
		[typePass, setTypePass] = useState(null),
		[typePass2, setTypePass2] = useState(null);

	return (
		<div className="row mx-0 g-3 g-md-5">
			<div className="col-md-6">
				<label className="dmMonoFont text-uppercase" htmlFor="firstName">
					First Name
				</label>
				<input
					type="text"
					className="form-control py-3 dmMonoFont"
					name="firstName"
					value={state?.firstName}
					onChange={textChange("firstName")}
					readOnly={params?.page === "users"}
				/>
			</div>
			<div className="col-md-6">
				<label className="dmMonoFont text-uppercase" htmlFor="lastName">
					Last Name
				</label>
				<input
					type="text"
					className="form-control py-3 dmMonoFont"
					name="lastName"
					value={state?.lastName}
					onChange={textChange("lastName")}
					readOnly={params?.page === "users"}
				/>
			</div>
			<div className="col-md-6">
				<label className="dmMonoFont text-uppercase" htmlFor="telephone">
					Telephone
				</label>
				<input
					type="tel"
					className="form-control py-3 dmMonoFont"
					name="telephone"
					value={state?.telephone}
					onChange={textChange("telephone")}
					placeholder="Telephone"
					readOnly
				/>
			</div>
			<div className="col-md-6">
				<label className="dmMonoFont text-uppercase" htmlFor="email">
					Email
				</label>
				<input
					type="email"
					className="form-control py-3 dmMonoFont"
					name="email"
					value={state?.email}
					onChange={textChange("email")}
					placeholder="Email"
					readOnly
				/>
			</div>
			<div className="col-md-6">
				<label htmlFor="ninNumber">
					NIN{" "}
					{!auth?.user?.ninNumber && (
						<>
							({nairaSign}
							{usecase?.usecase?.ninCharge})
						</>
					)}
				</label>
				{/* <input
							type="text"
							name="ninNumber"
							value={state?.ninNumber}
							onChange={textChange("ninNumber")}
							placeholder="Your NIN number"
							className="form-control py-3"
						/> */}
				{/* <NumericFormat
					className="form-control py-3"
					value={state?.ninNumber}
					allowLeadingZeros={true}
					allowNegative={false}
					displayType="input"
					name="ninNumber"
					onValueChange={val =>
						setState({
							...state,
							ninNumber: val?.value,
						})
					}
					maxLength={11}
					inputMode="tel"
					renderText={(value, props) => <span {...props}>{value}</span>}
					decimalScale={0}
					pattern="[0-9]"
					readOnly={auth?.user?.ninNumber}
				/> */}
				<div className="tw-relative">
					<div className="tw-flex tw-h-full tw-w-full tw-items-center">
						<input
							readOnly={auth?.user?.ninNumber}
							type={typePass ? "tel" : "password"}
							maxLength={11}
							required
							style={{
								border: "none !important",
							}}
							name="ninNumber"
							className="form-control py-3 dmMonoFont"
							value={state?.ninNumber}
							onChange={textChange("ninNumber")}
							placeholder={`You will be charged ${nairaSignNeutral}${usecase?.usecase?.ninCharge}`}
						/>
						{!auth?.user?.ninNumber && (
							<div className="tw-absolute tw-top-4 tw-right-4">
								<EyeToggle typePass={typePass} setTypePass={setTypePass} />
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="col-md-6">
				<label htmlFor="ninNumber">
					BVN{" "}
					{!auth?.user?.bvnNumber && (
						<>
							({nairaSign}
							{usecase?.usecase?.bvnCharge})
						</>
					)}
				</label>
				{/* <input
							type="text"
							name="ninNumber"
							value={state?.ninNumber}
							onChange={textChange("ninNumber")}
							placeholder="Your NIN number"
							className="form-control py-3"
						/> */}
				<div className="tw-relative">
					<div className="tw-flex tw-h-full tw-w-full tw-items-center">
						<input
							readOnly={auth?.user?.bvnNumber}
							type={typePass2 ? "tel" : "password"}
							maxLength={11}
							required
							style={{
								border: "none !important",
							}}
							name="bvnNumber"
							className="form-control py-3 dmMonoFont"
							value={state?.bvnNumber}
							onChange={textChange("bvnNumber")}
							placeholder={`You will be charged ${nairaSignNeutral}${usecase?.usecase?.bvnCharge}`}
						/>
						{!auth?.user?.bvnNumber && (
							<div className="tw-absolute tw-top-4 tw-right-4">
								<EyeToggle typePass={typePass2} setTypePass={setTypePass2} />
							</div>
						)}
					</div>
				</div>
			</div>
			{/* <div className="col-md-6">
				<label className="dmMonoFont text-uppercase" htmlFor="bio">
					Bio
				</label>
				<textarea
					style={{
						height: "10rem",
						resize: "none",
					}}
					className="form-control py-3 dmMonoFont"
					name="bio"
					placeholder="Brief description"
					value={state?.bio}
					onChange={textChange("bio")}
					readOnly={params?.page === "users"}
				/>
			</div> */}
		</div>
	);
};

export const PasswordBox = () => {
	let { updatePassword, auth } = useContext(GlobalState);
	let init = {
			oldPassword: "",
			newPassword: "",
		},
		[state, setState] = useState(init),
		[loading, setLoading] = useState(false),
		[submit, setSubmit] = useState(false),
		textChange =
			name =>
			({ target: { value } }) => {
				setState({ ...state, [name]: value });
			},
		handleSubmit = async e => {
			e.preventDefault();
			if (!state?.oldPassword || !state?.newPassword) return;
			setLoading(true);
			await updatePassword(state);
			setLoading(false);
			setSubmit(true);
		};

	useEffect(() => {
		if (submit && auth?.isPassword) {
			setState(init);
			setSubmit(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [submit, auth?.isPassword]);

	return (
		<form onSubmit={handleSubmit} className="py-4 py-md-5 border-bottom">
			<h6 className="Lexend fw-600 fontReduceBig text-uppercase mt-3 mt-md-5">
				change password
			</h6>
			<small className="">
				Please enter your current password to change your password.
			</small>
			<div className="row mx-0 g-5 col-md-8 mt-3">
				<div className="col-md-6">
					<label className="dmMonoFont text-uppercase" htmlFor="password">
						old Password
					</label>
					<input
						type="password"
						className="form-control py-3 dmMonoFont"
						name="password"
						placeholder="Old Password"
						value={state?.oldPassword}
						onChange={textChange("oldPassword")}
					/>
				</div>
				<div className="col-md-6">
					<label className="dmMonoFont text-uppercase" htmlFor="password">
						new Password
					</label>
					<input
						type="password"
						className="form-control py-3 dmMonoFont"
						name="password"
						placeholder="New Password"
						value={state?.newPassword}
						onChange={textChange("newPassword")}
					/>
				</div>
			</div>
			<div className="d-flex align-items-center my-3">
				<Buttons
					onClick={handleSubmit}
					loading={loading}
					css="btn-primary1 text-capitalize px-md-4 px-3 fontReduce mx-md-3 mx-2 py-2 text-white"
					title={"save"}
					width="w-auto"
				/>
				<button
					onClick={() => setState(init)}
					className={`btn btn-outline-primary1 text-capitalize px-md-4 px-3 fontReduce mx-md-3 mx-2 py-2 text-primary1`}>
					cancel
				</button>
			</div>
		</form>
	);
};

export const Enable2FABox = () => {
	let { auth } = useContext(GlobalState);
	let [step, setStep] = useState(""),
		[code, setCode] = useState(""),
		[qr, setQr] = useState(""),
		dispatch = useDispatch(),
		[loading, setLoading] = useState(false),
		handleSubmit = async e => {
			e.preventDefault();
			if (step !== "enable") if (!code) return;
			setLoading(true);
			try {
				let url = `/api/v2/user/is2fa-initiate`;
				if (step === "validate") url = `/api/v2/user/is2fa-validate`;
				if (step === "disable") url = `/api/v2/user/is2fa-disable`;
				var res;
				if (step === "enable") res = await axios.post(url);
				else res = await axios.put(url, { token: code });
				console.log({ res: res?.data }, step);
				toast.success(res.data.msg || res?.data?.message);
				setStep(step === "enable" ? "validate" : "");
				if (step !== "enable")
					dispatch({ type: UPDATE_USER, payload: res?.data });
				else {
					setQr(res?.data?.data);
				}
				setLoading(false);
			} catch (err) {
				console.log({ err });
				let error = err.response?.data?.error;
				if (error) {
					error.forEach(error => toast.error(error.msg || error?.message));
				}

				if (err?.response?.status === 429) toast.error(err?.response?.data);
				setLoading(false);
			}
			setLoading(false);
		};

	return (
		<form className="py-4 py-md-5">
			<h6 className="Lexend fw-600 fontReduceBig text-uppercase mt-3 mt-md-5">
				2FA Authentication Settings
			</h6>
			<small className="">
				{/* Please enter your current password to change your password. */}
			</small>
			<div className="d-flex align-items-center my-3">
				<Buttons
					onClick={() =>
						setStep(
							auth?.user?.is2FAEnabled === "enable" ? "disable" : "enable"
						)
					}
					loading={loading}
					css={`
						${auth?.user?.is2FAEnabled === "enable"
							? "btn-danger2"
							: "btn-primary1"} text-capitalize px-md-4 px-3 fontReduce mx-md-3 mx-2 py-2 text-white
					`}
					title={
						auth?.user?.is2FAEnabled === "enable" ? "Disable 2FA" : "Enable 2FA"
					}
					width="w-auto"
					type="button"
				/>
			</div>
			<ModalComponents
				isOpen={step}
				toggle={() => setStep(null)}
				title={`${
					auth?.user?.is2FAEnabled === "enable" ? "Disable" : "Enable"
				} 2FA Authentication`}>
				{step !== "enable" ? (
					<>
						{step === "validate" && qr ? (
							<>
								<small className="mb-4 tw-text-base tw-font-medium d-block">
									Please scan the qrcode and input the token in your
									Authenticator App
								</small>
								<img src={qr} alt="QRCode" className="img-fluid mx-auto" />
								<Buttons
									onClick={() => setQr("")}
									title={"Input Token"}
									css="btn btn-primary1 text-capitalize py-3 w-100 my-4"
									type="button"
								/>
							</>
						) : (
							<Enable2FAComponent
								handleSubmit={handleSubmit}
								loading={loading}
								code={code}
								setCode={setCode}
								subtext={"Enter the code generated in your Authenticator app"}
							/>
						)}
					</>
				) : (
					<div className="d-flex mx-auto">
						<div className="d-flex align-items-center my-3 mx-auto">
							<Buttons
								onClick={handleSubmit}
								loading={loading}
								css="btn-primary1 text-capitalize px-md-4 px-3 fontReduce mx-md-3 mx-2 py-2 text-white"
								title={"Enable"}
								width="w-auto"
							/>
							<button
								onClick={() => setStep("")}
								className={`btn btn-outline-primary1 text-capitalize px-md-4 px-3 fontReduce mx-md-3 mx-2 py-2 text-primary1`}>
								cancel
							</button>
						</div>
					</div>
				)}
			</ModalComponents>
		</form>
	);
};

export const WalletPinBox = () => {
	let { manageWalletPin, wallet } = useContext(GlobalState);
	let init = {
			pin: "",
			oldPin: "",
		},
		[state, setState] = useState(init),
		[loading, setLoading] = useState(false),
		[submit, setSubmit] = useState(false),
		[isDelete, setIsDelete] = useState(null),
		// textChange =
		// 	name =>
		// 	({ target: { value } }) => {
		// 		setState({ ...state, [name]: value });
		// 	},
		handleSubmit = async e => {
			e?.preventDefault();
			if (wallet?.balance?.wallet_pin && !isDelete)
				if (!state?.pin || !state?.oldPin) return;
				else if (!state?.pin) return;
			setLoading(isDelete || "load");
			await manageWalletPin(
				isDelete ? isDelete : wallet?.balance?.wallet_pin ? "put" : "post",
				state
			);
			setLoading(false);
			setSubmit(true);
		};

	useEffect(() => {
		if (submit && wallet?.isWalletPin) {
			setState(init);
			setSubmit(false);
			setIsDelete(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [submit, wallet?.isWalletPin]);

	return (
		<form
			onSubmit={handleSubmit}
			className="py-4 py-md-5 border-top border-bottom">
			<h6 className="Lexend fw-600 fontReduceBig text-uppercase mt-3 mt-md-5">
				manage transaction pin
			</h6>
			<small className="">
				{wallet?.balance?.wallet_pin
					? `Please enter your current pin to change your pin.`
					: `Add transaction pin`}
			</small>
			<div className="row mx-0 g-5 col-md-8 mt-3">
				{wallet?.balance?.wallet_pin && (
					<div className="col-md-4">
						<label className="dmMonoFont text-uppercase" htmlFor="password">
							old pin <small className="text-muted">4 digits</small>
						</label>
						{/* <input
							type="number"
							className="form-control py-3 dmMonoFont"
							name="password"
							placeholder="Old pin"
							value={state?.oldPin}
							onChange={textChange("oldPin")}
							maxLength={4}
						/> */}
						<OtpComponent
							stateData={state?.oldPin}
							textChange={data => {
								setState({ ...state, oldPin: data });
							}}
							css="borderColor"
							loading={loading}
							numInputs={4}
							isInputSecure={true}
							inputType={"tel"}
						/>
					</div>
				)}
				<div className="col-md-4">
					<label className="dmMonoFont text-uppercase" htmlFor="password">
						{wallet?.balance?.wallet_pin ? `new ` : ""} Pin
						<small className="text-muted">4 digits</small>
					</label>
					{/* <input
						type="number"
						className="form-control py-3 dmMonoFont"
						name="password"
						placeholder="Pin"
						value={state?.pin}
						onChange={textChange("pin")}
						maxLength={4}
					/> */}
					<OtpComponent
						isInputSecure={true}
						stateData={state?.pin}
						textChange={data => {
							setState({ ...state, pin: data });
						}}
						css="borderColor"
						loading={loading}
						numInputs={4}
						inputType={"tel"}
					/>
				</div>
			</div>
			<div className="d-flex align-items-center my-3">
				<Buttons
					onClick={handleSubmit}
					loading={loading === "load"}
					css="btn-primary1 text-capitalize px-md-4 px-3 fontReduce mx-md-3 mx-2 py-2 text-white"
					title={"save"}
					width="w-auto"
				/>
				<button
					type="button"
					onClick={() => setState(init)}
					className={`btn btn-outline-primary1 text-capitalize px-md-4 px-3 fontReduce mx-md-3 mx-2 py-2 text-primary1`}>
					cancel
				</button>
				{wallet?.balance?.wallet_pin && (
					<div className="col-md-4">
						<Buttons
							onClick={() => setIsDelete("delete")}
							loading={loading === "load"}
							css="btn-danger2 text-capitalize px-md-4 px-3 fontReduce mx-md-3 mx-2 py-2 text-white"
							title={"Disable pin"}
							width="w-auto"
							type={"button"}
						/>
					</div>
				)}
			</div>
			<ModalComponents
				isOpen={isDelete ? true : false}
				toggle={() => setIsDelete(null)}
				title={"Disable Transaction Pin"}>
				<TransactionPinBox
					state={state}
					setState={setState}
					handleSubmit={handleSubmit}
					loading={loading === "delete"}
					title="Disable"
				/>
			</ModalComponents>
		</form>
	);
};

export const UpdateBizLogo = ({
	handleSubmit,
	state,
	setState,
	loading,
	loadingType,
	type,
	label,
}) => {
	let fileInputRef = useRef();
	let handleChangeImage = e => {
		const file = e.target.files[0];
		let err = "";

		if (!file) return (err = `File, ${file?.name} does not exist`);
		if (!file.type.includes("image"))
			return (err = `File, ${file?.name} format not supported`);

		if (err) {
			return toast.error(err);
		} else {
			setState({ ...state, [type]: file });
		}
	};

	return (
		<div className="mb-3 d-flex justify-content-center col-md-6">
			<div className="w-75 w75">
				<label
					htmlFor="Price"
					className="mb-3 textColor2 text-capitalize fontReduce">
					{label}
				</label>
				<div className="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-mt-8">
					<img
						src={
							state?.[type]
								? !state?.[type]?._id
									? URL.createObjectURL(state?.[type])
									: state?.[type]?.url || null
								: null
						}
						alt=""
						className="tw-h-36 tw-w-36 tw-rounded-full tw-border"
					/>
					<div className="tw-flex tw-gap-6">
						<div>
							<input
								ref={fileInputRef}
								type="file"
								accept="image/*"
								style={{ display: "none" }}
								onChange={handleChangeImage}
								name="image"
							/>
							<button
								type="button"
								className="tw-h-8 tw-w-24 btn btn-primary1 tw-rounded-xl tw-text-white tw-barlow tw-text-base tw-font-normal"
								onClick={() => fileInputRef.current.click()}>
								Upload
							</button>
						</div>
					</div>
				</div>

				{state?.[type] && !state?.[type]?._id && (
					<Buttons
						loading={loadingType === type && loading}
						title="update"
						css="btn btn-primary1 text-capitalize py-3 w-75 w75 d-block mx-auto my-4"
						width={"w-75 w75"}
						onClick={handleSubmit(type)}
					/>
				)}
			</div>
		</div>
	);
};
