import React, { useContext, useEffect, useState } from "react";
import { GlobalState } from "../Data/Context";
import Calendar from "react-calendar";
import { Container } from "reactstrap";
import "react-calendar/dist/Calendar.css";
// import Charts, { LineMixedCharts } from "./Charts";
import { useNavigate } from "react-router-dom";
import TransactionsFolder from "./Transactions";
import { productArr } from "./Products";
import { AdminDetails } from "./Users";
import { Icon } from "@iconify/react";

const colors = ["#f1c40f", "#fd7e14", "#0d6efd", "#d63384", "#198754"];

const Dashboard = ({ usersArr }) => {
  let {
    setStateName,
    auth,
    getServicesHistoryPending,
    getWalletStatMonthDay,
    getUserPrivileges,
  } = useContext(GlobalState);
  useEffect(() => {
    setStateName("dashboard analysis");
    getServicesHistoryPending();
    getWalletStatMonthDay("day");
    getWalletStatMonthDay("month");
    getUserPrivileges("reseller");
    getUserPrivileges("agent");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-white aboutScreen">
      <Container className="py-5">
        <div className="row mx-0 w-100">
          <div className="">
            <FourBoxBar list={usersArr} css="col-md-4" />
            <ProductList />
            {/* <div className="row mx-0 w-100">
							<div className="h25 col-md-6">
								<Charts
									state={[
										{ month: "Jan", sales: "2000", expenses: "4000" },
										{ month: "Feb", sales: "5000", expenses: "3000" },
										{ month: "Mar", sales: "5000", expenses: "3000" },
										{ month: "Apr", sales: "6000", expenses: "7000" },
										{ month: "May", sales: "9000", expenses: "2000" },
										{ month: "Jun", sales: "1000", expenses: "9000" },
										{ month: "Jul", sales: "8000", expenses: "2000" },
										{ month: "Aug", sales: "4000", expenses: "7000" },
										{ month: "Sep", sales: "2000", expenses: "6000" },
										{ month: "Oct", sales: "8000", expenses: "9000" },
										{ month: "Nov", sales: "5000", expenses: "3000" },
										{ month: "Dec", sales: "10000", expenses: "9500" },
									]}
									dKey={"sales"}
									dKey2={"expenses"}
									dColor="#53f293"
									dColor2="#20afe5"
									xaxis={"month"}
								/>
							</div>
							<div className="h25 col-md-6">
								<LineMixedCharts
									state={[
										{ month: "Jan", sales: "2000", expenses: "4000" },
										{ month: "Feb", sales: "5000", expenses: "3000" },
										{ month: "Mar", sales: "5000", expenses: "3000" },
										{ month: "Apr", sales: "6000", expenses: "7000" },
										{ month: "May", sales: "9000", expenses: "2000" },
										{ month: "Jun", sales: "1000", expenses: "9000" },
										{ month: "Jul", sales: "8000", expenses: "2000" },
										{ month: "Aug", sales: "4000", expenses: "7000" },
										{ month: "Sep", sales: "2000", expenses: "6000" },
										{ month: "Oct", sales: "8000", expenses: "9000" },
										{ month: "Nov", sales: "5000", expenses: "3000" },
										{ month: "Dec", sales: "10000", expenses: "9500" },
									]}
									dKey={"sales"}
									dKey2={"expenses"}
									dColor="#FEC430"
									dColor2="#009688"
									xaxis={"month"}
								/>
							</div>
						</div> */}
          </div>
          {/* <MainCalenderComponent /> */}
        </div>
        {auth?.user?.isAdmin ? (
          <AdminDetails privilege={"user"} />
        ) : (
          <TransactionsFolder />
        )}
      </Container>
    </div>
  );
};

export default Dashboard;

export const MainCalenderComponent = () => {
  return (
    <>
      <div className="col-md-4 d-none d-md-flex flex-column">
        <CalenderComponent css="darkPurple rounded20 border-0 p-3 text-white w-100" />
      </div>
    </>
  );
};

export const CalenderComponent = ({ css }) => {
  return (
    <>
      <div className="d-flex w-100">
        <Calendar className={css} showWeekNumbers={true} calendarType="US" />
      </div>
    </>
  );
};

export const FourBoxBar = ({ list, css }) => {
  let navigate = useNavigate();
  return (
    <>
      <div className="tw-w-5/6 tw-mx-auto tw-grid lg:tw-grid-cols-3 md:tw-grid-cols-2 tw-gap-10">
        {list?.map((item, index) => (
          <div className={``} key={index}>
            <div
              style={{
                background: item?.color || "white",
              }}
              className="tw-flex tw-justify-between tw-px-6 tw-h-36 tw-cursor-pointer tw-items-center tw-shadow-xl tw-rounded-xl"
              onClick={() => (item?.link ? navigate(item?.link) : {})}
              //   style={{
              //     background: item?.color,
              //   }}
            >
              <div className="col-md my-auto d-flex">
                <img src={item?.icon} className="tw-h-16" alt="Icon" />
              </div>
              <div className="">
                <p className="tw-text-2xl tw-text-right tw-font-medium tw-text-white">
                  {item?.number}
                </p>
                <h6 className="tw-text-base tw-text-white tw-capitalize tw-font-medium">
                  {item?.name}
                </h6>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export const ProductList = () => {
  let { auth } = useContext(GlobalState);
  let [state, setState] = useState([]),
    navigate = useNavigate();

  useEffect(() => {
    setState(productArr);
  }, [auth?.user]);
  return (
    <div className="py-3 py-md-4">
      <div className="tw-grid lg:tw-w-3/4 tw-mt-8 tw-w-11/12 tw-mx-auto tw-grid-cols-3 tw-gap-10">
        {state?.map((item, i) => (
          <div
            className="tw-bg-white tw-h-24 tw-flex tw-items-center tw-justify-center tw-shadow-lg tw-cursor-pointer"
            key={i}
          >
            <div
              onClick={() =>
                navigate(
                  item?.link?.includes("converter")
                    ? item?.link
                    : `/products${item?.link}`
                )
              }
              className=""
            >
              <div className="">
                <div className="mb-3 tw-flex tw-justify-center tw-items-center">
                  <Icon
                    color={colors[i % colors.length]}
                    style={{
                      fontSize: "36px",
                    }}
                    icon={item?.icon}
                  />
                </div>
                <h6 className="Lexend textTrunc textTrunc2 fontReduce2 text-capitalize">
                  {item?.name}
                </h6>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
