import React, { useState, useContext, useEffect } from "react";
import { Container } from "reactstrap";
import { Buttons, EmptyComponent } from "../../Utils";
import { GlobalState } from "../../Data/Context";
import LoadMore, { BottomTab } from "../../Components/LoadMore";
import { ModalComponents } from "../../Components";
import moment from "moment";
import { MainPaginate, MainRanger } from "../../Components/Transactions";
import { useNavigate, useParams } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { ExportButtons2 } from "../../Utils/Export Functions/ExportButtons";

const Bonus = () => {
  let [isBonus, setIsBonus] = useState(false),
    [type, setType] = useState(""),
    toggleBonus = () => {
      setIsBonus(!isBonus);
      setType("");
    },
    navigate = useNavigate(),
    { auth, permission } = useContext(GlobalState);

  useEffect(() => {
    if (auth?.user?.privilege !== "superadmin") {
      let findTitle = "viewgivebonus";

      let findPermit = permission?.data?.find(
        (item) =>
          item?.slug === findTitle && item?.user?.includes(auth?.user?._id)
      );
      if (!findPermit) {
        navigate(-1);
      }
    }
  }, [auth?.user, permission?.data, navigate]);

  let { setStateName } = useContext(GlobalState);
  useEffect(() => {
    setStateName("give bonus history");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="bg-white aboutScreen">
        <Container className="py-5">
          <div className="btn-group w-25 w25">
            <Buttons
              title={"give bonus"}
              css="btn-primary1 text-capitalize py-3 px-4 px-lg-5"
              width={"w-50"}
              onClick={() => {
                setType("credit");
                setIsBonus(true);
              }}
              // style={{ borderRadius: "30px" }}
            />
            <Buttons
              title={"debit bonus"}
              css="btn-primary1 text-capitalize py-3 px-4 px-lg-5"
              width={"w-50"}
              // style={{ borderRadius: "30px" }}
              onClick={() => {
                setType("debit");
                setIsBonus(true);
              }}
            />
          </div>
          <BonusHistory />
        </Container>
      </div>
      <MakeBonus isOpen={isBonus} back={toggleBonus} debit={type === "debit"} />
    </>
  );
};

export default Bonus;

const BonusHistory = () => {
  let {
    bonus,
    getManualBonusHistory,
    numberWithCommas,
    nairaSign,
    getReload,
    wallet,
  } = useContext(GlobalState);

  let [data, setData] = useState(null),
    [type, setType] = useState("credit"),
    [search, setSearch] = useState("");

  useEffect(() => {
    getManualBonusHistory("manage-bonus", { type: "credit" });
    getManualBonusHistory("manage-bonus", { type: "debit" });
    getReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await getManualBonusHistory("manage-bonus", {
          search,
          type,
        });
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (wallet?.isFound) setData(wallet?.mainSearch);
    else setData(type === "debit" ? bonus?.debit_bonus : bonus?.give_bonus);
  }, [
    bonus?.give_bonus,
    wallet?.isFound,
    wallet?.mainSearch,
    type,
    bonus?.debit_bonus,
  ]);

  let [loading, setLoading] = useState(false);
  let handleLoadMore = async () => {
    setLoading(true);

    await getManualBonusHistory("manage-bonus", {
      page: Number(bonus?.paginate_bonus?.nextPage),
      // limit: Number(
      // 	bonus?.paginate_bonus?.nextPage * bonus?.paginate_bonus?.limit
      // ),
      search,
      type,
    });
    setLoading(false);
  };

  let [range, setRange] = useState(10),
    [thisData, setThisData] = useState(null);

  const params = useParams();

  const [itemOffset, setItemOffset] = useState(0);
  if (!data) return;

  const endOffset = itemOffset + range;

  const currentItems = data.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(data.length / range);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * range) % data.length;
    setItemOffset(newOffset);
  };

  const headers = [
    { title: "S/N", field: "serialNumber" },
    { title: "TITLE", field: "title" },
    { title: "DESCRIPTION", field: "description" },
    { title: "TARGET", field: "target" },
    { title: "TYPE", field: "type" },
    { title: "AMOUNT", field: "amount" },
    { title: "DATE & TIME", field: "createdAt" },
  ];

  const tableData = currentItems.map((item, index) => ({
    serialNumber: index + 1,
    title: `${item?.title}`,
    description: `${item?.description}`,
    target:
      item?.category === "all"
        ? "Everyone"
        : item?.category !== "single"
        ? `all ${item?.category}`
        : `${item?.recipients?.[0]?.firstName} ${item?.recipients?.[0]?.lastName}`,
    type: `${item?.type}`,
    amount: item?.amount
      ? `₦ ${numberWithCommas(Number(item?.amount).toFixed(2))}`
      : "₦ 0",
    createdAt: moment(item?.createdAt).format("DD/MM/YYYY hh:mm A"),
  }));

  return (
    <div className="py-5">
      <Buttons
        onClick={() => setType(type === "debit" ? "credit" : "debit")}
        title={`Show ${type === "debit" ? "credited" : "debited"} Bonus`}
        css="btn-primary1 text-capitalize py-3 px-4 px-lg-5 ms-auto mb-3"
        width={"w-25 w25"}
        style={{ borderRadius: "30px" }}
      />
      <div className="w-50 w50 mb-3">
        <input
          type="search"
          name="search"
          id="Search"
          className="form-control w-100 py-3 borderColor2"
          placeholder="Type here to search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="tw-flex tw-justify-between tw-items-center">
        <div className="tw-flex-1">
          <MainRanger range={range} setRange={setRange} />
        </div>
        <ExportButtons2
          headers={headers}
          data={tableData}
          fileName={`${params?.page} ${params?.id ? `(${params?.id})` : ""}`}
        />
      </div>
      ;
      <div className="row mx-0 my-2 py-3 bland">
        <div className="col my-auto text-uppercase fw-bold Lexend d-none d-md-flex fontReduce textTrunc">
          S/N
        </div>
        <div className="col my-auto text-uppercase fw-bold Lexend fontReduce textTrunc">
          title
        </div>
        <div className="col my-auto text-uppercase fw-bold Lexend fontReduce textTrunc">
          description
        </div>
        <div className="col my-auto text-uppercase fw-bold Lexend fontReduce textTrunc">
          target
        </div>
        <div className="col my-auto text-uppercase fw-bold Lexend fontReduce textTrunc">
          type
        </div>
        <div className="col my-auto text-uppercase fw-bold Lexend fontReduce textTrunc">
          amount
        </div>
        <div className="col my-auto text-uppercase fw-bold Lexend d-none d-md-flex fontReduce textTrunc">
          date&time
        </div>
      </div>
      {currentItems?.length === 0 ? (
        <EmptyComponent subtitle={"User bonus history is empty"} />
      ) : (
        <>
          {currentItems?.map((it, i) => (
            <div
              key={i}
              onClick={() => setThisData(it)}
              className="row mx-0 py-3 bland2 myCursor border-bottom"
            >
              <div className="col my-auto text-capitalize d-none d-md-flex fontReduce2 textTrunc">
                {i + 1}
              </div>
              <div className="col my-auto text-capitalize textTrunc textTrunc2 fontReduce2">
                {it?.title}
              </div>
              <div className="col my-auto text-capitalize textTrunc textTrunc2 fontReduce2">
                {it?.description}
              </div>
              <div className="col my-auto text-capitalize textTrunc textTrunc2 fontReduce2">
                {it?.category === "all"
                  ? "Everyone"
                  : it?.category !== "single"
                  ? `all ${it?.category}`
                  : `${it?.recipients?.[0]?.firstName} ${it?.recipients?.[0]?.lastName}`}
              </div>
              <div className="col my-auto text-capitalize textTrunc textTrunc2 fontReduce2">
                {it?.type}
              </div>
              <div className="col my-auto fontReduce2 textTrunc">
                {nairaSign}{" "}
                {it?.amount
                  ? numberWithCommas(Number(it?.amount).toFixed(2))
                  : 0}
              </div>
              <div className="col my-auto d-none d-md-flex fontReduce2 textTrunc">
                {moment(it?.createdAt).format("DD/MM/YYYY HH:mm A")}
              </div>
            </div>
          ))}
          <div className="py-3">
            <h2 className="Lexend">
              Total: {nairaSign}{" "}
              {numberWithCommas(
                Number(
                  type === "debit"
                    ? bonus?.bonusDebitTotal || 0
                    : bonus?.bonusCreditTotal || 0
                ).toFixed(2)
              )}
            </h2>
          </div>
          <MainPaginate
            handlePageClick={handlePageClick}
            pageCount={pageCount}
          />
        </>
      )}
      <BottomTab
        state={data}
        paginate={
          type === "debit" ? bonus?.paginate_debit_bonus : bonus?.paginate_bonus
        }
      />
      <LoadMore
        next={
          type === "debit"
            ? bonus?.paginate_debit_bonus?.next
            : bonus?.paginate_bonus?.next
        }
        handleLoadMore={handleLoadMore}
        loading={loading}
      />
      <BonusDetails thisData={thisData} setThisData={setThisData} />
    </div>
  );
};

export const MakeBonus = ({ isOpen, back, user, debit, amount }) => {
  let { manageWallet, bonus, nairaSignNeutral, nairaSign, numberWithCommas } =
    useContext(GlobalState);

  let init = {
      title: "",
      description: "",
      user: user ? user : "",
      amount: "",
      category: "",
    },
    [state, setState] = useState(init),
    [loading, setLoading] = useState(false),
    [submit, setSubmit] = useState(false),
    textChange =
      (name) =>
      ({ target: { value } }) => {
        setState({ ...state, [name]: value });
      },
    handleSubmit = async (e) => {
      e?.preventDefault();
      setLoading(true);
      await manageWallet(
        "bonus",
        { ...state, user: user || "" },
        debit ? "debit" : "give"
      );
      setLoading(false);
      setSubmit(true);
    };

  useEffect(() => {
    if (bonus?.isAdded && submit) {
      back();
      setSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bonus?.isAdded, submit]);

  return (
    <>
      <ModalComponents
        isOpen={isOpen}
        back={back}
        title={`${debit ? "Debit" : ""} Bonus`}
      >
        <form onSubmit={handleSubmit}>
          {amount && user ? (
            <>
              <h4 className="Lexend fw-600 fontReduceBig">
                Current Bonus Balance: {nairaSign}{" "}
                {numberWithCommas(Number(amount?.bonus).toFixed(2))}
              </h4>
            </>
          ) : null}
          {!user && (
            <UserTypeSelect
              state={state?.category}
              textChange={textChange}
              name="category"
            />
          )}
          <div className="form mb-3">
            <label htmlFor="wallet">Title</label>
            <input
              type="text"
              className="form-control rounded10 py-3"
              placeholder="Enter title"
              value={state?.title}
              onChange={textChange("title")}
            />
          </div>
          <div className="form mb-3">
            <label htmlFor="wallet">Description</label>
            <textarea
              value={state?.description}
              onChange={textChange("description")}
              className="form-control rounded10 py-3"
              placeholder="Enter description"
              style={{
                resize: "none",
                height: "7rem",
              }}
            />
          </div>
          <div className="form mb-3">
            <label htmlFor="amount">Amount</label>
            {/* <input
							type="number"
							className="form-control rounded10 py-3"
							placeholder="5000"
							value={state?.amount}
							onChange={textChange("amount")}
						/> */}
            <NumericFormat
              prefix={`${nairaSignNeutral} `}
              className="form-control py-3"
              value={state.amount}
              placeholder="500"
              displayType="input"
              thousandSeparator={true}
              onValueChange={(val) =>
                setState({ ...state, amount: val?.floatValue })
              }
              min={0}
              inputMode="decimal"
              renderText={(value, props) => <span {...props}>{value}</span>}
              allowNegative={false}
            />
          </div>
          {/* 
					<div className="form mb-3">
						<label htmlFor="wallet">Select wallet_id</label>
						<input
							type="text"
							className="form-control rounded10 py-3"
							placeholder="123456789"
						/>
					</div> */}
          <Buttons
            title={"send"}
            css="btn-primary1 text-capitalize py-3 w-50 my-4 mx-auto"
            width={"w-50"}
            style={{ borderRadius: "30px" }}
            loading={loading}
            onClick={handleSubmit}
          />
        </form>
      </ModalComponents>
    </>
  );
};

export let UserTypeSelect = ({ state, textChange, name }) => {
  let categories = [
    "all",
    // "admins",
    // "non-admins",
    "superadmin",
    "manager",
    "accountant",
    "developer",
    "reseller",
    "agent",
    // "topuser",
    "user",
    "marketer",
  ];
  return (
    <>
      <div className="form mb-3">
        <label htmlFor="wallet">Select target</label>
        <select
          value={state}
          onChange={textChange(name)}
          className="form-control rounded10 py-3 form-select text-capitalize"
        >
          <option value="">Select target</option>
          {categories?.map((item, i) => (
            <option value={item} key={i}>
              {item}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export const BonusDetails = ({ thisData, setThisData }) => {
  let { numberWithCommas, auth, nairaSign } = useContext(GlobalState);
  return (
    <>
      <ModalComponents
        isOpen={thisData ? true : false}
        toggle={() => setThisData(false)}
        title="Bonus details"
      >
        <div className="downH2 d-flex flex-column">
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>type: </span>
            <span
              className={`fontInherit Lexend ${
                thisData?.type === "credit"
                  ? "text-success2 text-success-2 text-success"
                  : "text-danger2"
              }`}
            >
              {thisData?.type}
            </span>{" "}
          </p>
          {auth?.user?.isAdmin && (
            <p className="border-bottom d-flex justify-content-between">
              <span className="text-capitalize">Admin: </span>
              <span>
                <span className="fontInherit Lexend d-block text-capitalize">
                  {thisData?.user?.lastName} {thisData?.user?.firstName}
                </span>{" "}
                <span className="fontInherit Lexend d-block">
                  {thisData?.user?.telephone}
                </span>{" "}
                <span className="fontInherit Lexend d-block">
                  {thisData?.user?.email}
                </span>{" "}
                {thisData?.user?.privilege && (
                  <span className="fontInherit Lexend d-block tw-uppercase">
                    {thisData?.user?.privilege}
                  </span>
                )}
              </span>
            </p>
          )}
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>Amount: </span>
            <span className="fontInherit Lexend">
              {nairaSign}{" "}
              {thisData?.amount
                ? numberWithCommas(Number(thisData?.amount).toFixed(2))
                : 0}
            </span>{" "}
          </p>
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>Target: </span>
            <span className="fontInherit Lexend">
              {thisData?.category}{" "}
            </span>{" "}
          </p>
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>Title: </span>
            <span className="fontInherit Lexend">{thisData?.title} </span>{" "}
          </p>
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>Description: </span>
            <span className="fontInherit Lexend">
              {thisData?.description}{" "}
              {thisData?.channel === "auto buy"
                ? ` via ${thisData?.channel}`
                : ""}
            </span>{" "}
          </p>
          <p className="text-capitalize border-bottom d-flex justify-content-between">
            <span>date&time: </span>
            <span className="fontInherit Lexend">
              {moment(thisData?.createdAt).format("DD/MM/YYYY hh:mm A")}
            </span>{" "}
          </p>
          {auth?.user?.isAdmin && thisData?.authorizer && (
            <>
              <p className="border-bottom d-flex justify-content-between">
                <span className="text-capitalize">Admin: </span>
                <span>
                  <span className="fontInherit Lexend d-block text-capitalize">
                    {thisData?.authorizer?.lastName}{" "}
                    {thisData?.authorizer?.firstName}
                  </span>{" "}
                  <span className="fontInherit Lexend d-block">
                    {thisData?.authorizer?.telephone}
                  </span>{" "}
                  <span className="fontInherit Lexend d-block">
                    {thisData?.authorizer?.email}
                  </span>{" "}
                  {thisData?.authorizer?.privilege && (
                    <span className="fontInherit Lexend d-block tw-uppercase">
                      {thisData?.authorizer?.privilege}
                    </span>
                  )}
                </span>
              </p>
            </>
          )}
          <div className="ms-auto d-flex align-items-center">
            <button
              className="btn btn-danger-2 btn-danger2 d-block ms-auto me-2"
              onClick={() => setThisData(false)}
            >
              Close
            </button>
          </div>
        </div>
      </ModalComponents>
    </>
  );
};
