import React, { useContext, useEffect } from "react";
import { GlobalState } from "../../../Data/Context";
import { useParams, useNavigate } from "react-router-dom";
import { Container } from "reactstrap";

const ManageCgWalletHistory = () => {
	let { setStateName, manageCGWalletHistory, auth, permission } =
		useContext(GlobalState);
	useEffect(() => {
		setStateName("CG Wallet History");
		manageCGWalletHistory("debit");
		manageCGWalletHistory("credit");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	let navigate = useNavigate(),
		params = useParams(),
		controlsTab = [
			{
				textColor: "white",
				name: "Purchase",
				type: "link",
				link: `/${params?.page}/${params?.id}/credit`,
				color: "linear-gradient(90deg, #E43369 16.14%, #C20E19 101.45%)",
			},
			{
				name: "Usage",
				type: "link",
				link: `/${params?.page}/${params?.id}/debit`,
				color: "linear-gradient(90.18deg, #6CB731 -52.19%, #0F5A16 81.92%)",
				textColor: "white",
			},
		];

	useEffect(() => {
		if (auth?.user?.privilege !== "superadmin") {
			let findTitle = "viewcgwallethistory";

			let findPermit = permission?.data?.find(
				item =>
					item?.slug === findTitle && item?.user?.includes(auth?.user?._id)
			);
			if (!findPermit) {
				navigate(-1);
			}
		}
	}, [auth?.user, permission?.data, navigate]);

	return (
		<div className="bg-white aboutScreen">
			<Container>
				<div className="tw-grid tw-w-11/12 tw-gap-10  tw-mx-auto md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-py-16">
					{controlsTab?.map((item, i) => (
						<div
							onClick={() => {
								if (item?.type === "link") {
									navigate(item?.link);
								} else if (item?.type === "button") {
								}
							}}
							className="tw-flex tw-justify-between tw-px-6 tw-h-48 tw-shadow-md tw-items-center myCursor"
							key={i}>
							<div></div>
							<span
								className="d-flex align-items-center justify-content-center text2  text-decoration-none h-100 eachProduct fontReduceBig textTrunc p-2 p-md-0 py-3 py-md-0 h-100"
								// style={{
								//   background: item?.color,
								//   borderRadius: "30px",
								//   color: item?.textColor ? item?.textColor : "#000",
								// }}
							>
								<span className="tw-text-xl tw-text-[#1b1b1b] tw-font-medium text-capitalize">
									{item?.name}
								</span>
							</span>
						</div>
					))}
				</div>
			</Container>
		</div>
	);
};

export default ManageCgWalletHistory;
