import React, { useContext, useEffect, useState } from "react";
import { GlobalState } from "../../Data/Context";
import { Container } from "reactstrap";
import { Buttons, EmptyComponent } from "../../Utils";
import { ModalComponents } from "../../Components";
import { BiTrashAlt } from "react-icons/bi";
import { BsPen } from "react-icons/bs";
import { HiThumbDown, HiThumbUp } from "react-icons/hi";
import { ClipLoader } from "react-spinners";
import { UploadPicture } from "../sliders";
import { useNavigate, useParams } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { ExportButtons2 } from "../../Utils/Export Functions/ExportButtons";

const Education = () => {
  let { setStateName, toggleAvailabilty, auth, permission } =
    useContext(GlobalState);
  useEffect(() => {
    setStateName("Education Controls");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let [isOpen, setIsOpen] = useState(false),
    [isOpenInit, setIsOpenInit] = useState(false),
    [data, setEducation] = useState(null),
    toggle = () => {
      if (data) setEducation(null);
      setIsOpen(!isOpen);
    },
    toggleInit = () => {
      setIsOpenInit(!isOpenInit);
    },
    [loading, setLoading] = useState(""),
    navigate = useNavigate();

  useEffect(() => {
    if (auth?.user?.privilege !== "superadmin") {
      let findTitle = "viewmanageeducation";

      let findPermit = permission?.data?.find(
        (item) =>
          item?.slug === findTitle && item?.user?.includes(auth?.user?._id)
      );
      if (!findPermit) {
        navigate(-1);
      }
    }
  }, [auth?.user, permission?.data, navigate]);

  useEffect(() => {
    if (data) {
      setIsOpen(true);
    }
  }, [data]);

  return (
    <div className="bg-white aboutScreen">
      <Container className="py-5">
        <h4 className="Lexend">All education plans</h4>{" "}
        <div className="btn-group">
          <Buttons
            title={"create new"}
            css="btn-primary1 text-capitalize py-md-3 py-2 px-4 px-lg-5 my-md-4 my-2 mb-0"
            width={"w-auto"}
            onClick={toggleInit}
            style={{ borderRadius: "30px" }}
          />
          <Buttons
            title={"disable all"}
            css="btn-primary1 text-capitalize py-md-3 py-2 px-4 px-lg-5 my-md-4 my-2 mb-0 mx-1 mx-md-2"
            width={"w-auto"}
            onClick={async () => {
              setLoading("disable");
              await toggleAvailabilty("education", "disable");
              setLoading("");
            }}
            loading={loading === "disable"}
            style={{ borderRadius: "30px" }}
          />
          <Buttons
            title={"enable all"}
            css="btn-primary1 text-capitalize py-md-3 py-2 px-4 px-lg-5 my-md-4 my-2 mb-0 mx-1 mx-md-2"
            width={"w-auto"}
            onClick={async () => {
              setLoading("enable");
              await toggleAvailabilty("education", "enable");
              setLoading("");
            }}
            loading={loading === "enable"}
            style={{ borderRadius: "30px" }}
          />
        </div>
        <EducationBundleDetails setEducation={setEducation} />
      </Container>{" "}
      <MakeEducationBundle isOpen={isOpen} back={toggle} datum={data} />
      <BillerCategorySelection
        isOpen={isOpenInit}
        back={toggleInit}
        setEducation={setEducation}
      />
    </div>
  );
};

export default Education;

const EducationBundleDetails = ({ setEducation }) => {
  let { educationBundle, manageEducationBundle, nairaSign, numberWithCommas } =
      useContext(GlobalState),
    [state, setState] = useState(null),
    [load, setLoad] = useState({ isLoad: false, loadEducation: "" }),
    [load2, setLoad2] = useState({ isLoad: false, loadEducation: "" });
  const params = useParams();

  useEffect(() => {
    if (educationBundle?.isFound) setState(educationBundle?.mainSearch);
    else setState(educationBundle?.data);
  }, [
    educationBundle?.mainSearch,
    educationBundle?.isFound,
    educationBundle?.data,
  ]);

  if (!state) return;

  const headers = [
    { title: "S/N", field: "serialNumber" },
    { title: "Type", field: "type" },
    { title: "Price", field: "price" },
    { title: "Reseller Price", field: "reseller_price" },
    { title: "AgentPrice", field: "agent_price" },
    { title: "Category", field: "category" },
    { title: "Status", field: "status" },
  ];

  const data = state.map((item, index) => ({
    serialNumber: index + 1,
    type: item?.type ? `${item?.type}` : "",
    price: item?.price
      ? `₦ ${numberWithCommas(Number(item?.price).toFixed(2))}`
      : "₦ 0",
    reseller_price: item?.resellerPrice
      ? `₦ ${numberWithCommas(Number(item?.resellerPrice).toFixed(2))}`
      : "₦ 0",
    agent_price: item?.agentPrice
      ? `₦ ${numberWithCommas(Number(item?.agentPrice).toFixed(2))}`
      : "₦ 0",
    category: item?.category?.name ? `${item?.category?.name}` : "",
    status: item?.enabler === "enable" ? "Available" : "Not available",
  }));

  return (
    <>
      <div className="pb-5 my-5">
        <div className="tw-flex tw-justify-between tw-items-center tw-pb-2">
          <div className="tw-flex-1"></div>
          <ExportButtons2
            headers={headers}
            data={data}
            fileName={`${params?.page} ${params?.id ? `(${params?.id})` : ""}`}
          />
        </div>
        <div className="bland row mx-0 py-3 px-0 text-capitalize Lexend fw-bold">
          <div className="col textTrunc d-none d-md-flex Lexend">S/N</div>
          <div className="col textTrunc Lexend">Image</div>
          <div className="col textTrunc Lexend">Type</div>
          <div className="col textTrunc Lexend">Price</div>
          <div className="col textTrunc Lexend">Reseller Price</div>
          <div className="col textTrunc Lexend">Agent Price</div>
          {/* <div className="col textTrunc Lexend">Topuser Price</div> */}
          <div className="col textTrunc Lexend">category</div>
          <div className="col textTrunc Lexend">status</div>
          <div className="col textTrunc Lexend">action </div>
        </div>
        <div className="bg-white row mx-0">
          {state?.length === 0 ? (
            <EmptyComponent subtitle={"EducationBundle state empty"} />
          ) : (
            state?.map((item, index) => (
              <div key={index} className="row mx-0 py-3 px-0 border-bottom">
                <div className="col d-none d-md-flex textTrunc my-auto">
                  {index + 1}
                </div>
                <div className="col my-auto">
                  <img
                    src={item?.image?.url}
                    alt={item?.image?.name}
                    className="img-fluid rounded imgFluid"
                    style={{
                      height: "10rem",
                      width: "100%",
                    }}
                  />
                </div>
                <div className="col textTrunc my-auto">{item?.type}</div>
                <div className="col d-none d-md-flex textTrunc my-auto">
                  {nairaSign}
                  {item?.price
                    ? numberWithCommas(Number(item?.price).toFixed(2))
                    : 0}
                </div>
                <div className="col textTrunc my-auto">
                  {nairaSign}
                  {item?.resellerPrice
                    ? numberWithCommas(Number(item?.resellerPrice).toFixed(2))
                    : 0}
                </div>
                <div className="col textTrunc my-auto">
                  {nairaSign}
                  {item?.agentPrice
                    ? numberWithCommas(Number(item?.agentPrice).toFixed(2))
                    : 0}
                </div>
                {/* <div className="col textTrunc my-auto">
									{nairaSign}
									{item?.topuserPrice
										? numberWithCommas(Number(item?.topuserPrice).toFixed(2))
										: 0}
								</div> */}
                <div className="col textTrunc my-auto">
                  {item?.category?.name}
                </div>
                <div
                  className={`col textTrunc my-auto ${
                    item?.enabler === "enable"
                      ? "text-success text-success2"
                      : "text-danger text-danger2"
                  }`}
                >
                  {item?.enabler === "enable" ? "Available" : "Not available"}
                </div>
                <div className="col textTrunc my-auto btn-group">
                  <button
                    title={`${
                      item?.enabler === "enable" ? "Disable" : "Enable"
                    } Education plan`}
                    onClick={async () => {
                      setLoad({ isLoad: true, loadEducation: item?._id });
                      await manageEducationBundle(
                        {
                          ...item,
                          enabler:
                            item?.enabler === "enable" ? "disable" : "enable",
                        },
                        item?._id,
                        "edit"
                      );
                      setLoad({ isLoad: false, loadEducation: "" });
                    }}
                    className="btn btn-primary1 text-capitalize p-1 p-md-2 w-100 fontReduce2"
                  >
                    {load?.isLoad && load?.loadEducation === item?._id ? (
                      <ClipLoader color="white" size={16} />
                    ) : item?.enabler === "enable" ? (
                      <HiThumbDown />
                    ) : (
                      <HiThumbUp />
                    )}
                  </button>
                  <button
                    title="Edit Education plan"
                    onClick={() => setEducation(item)}
                    className="btn btn-success2 text-capitalize p-1 p-md-2 w-100 fontReduce2"
                  >
                    <BsPen />
                  </button>
                  <button
                    title="Delete Education plan"
                    onClick={async () => {
                      setLoad2({ isLoad: true, loadEducation: item?._id });
                      await manageEducationBundle(item, item?._id, "delete");
                      setLoad2({ isLoad: false, loadEducation: "" });
                    }}
                    className="btn btn-danger2 text-capitalize p-1 p-md-2 w-100 fontReduce2"
                  >
                    {load2?.isLoad && load2?.loadEducation === item?._id ? (
                      <ClipLoader color="white" size={16} />
                    ) : (
                      <BiTrashAlt />
                    )}
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

const MakeEducationBundle = ({ isOpen, back, datum }) => {
  let { educationBundle, manageEducationBundle, nairaSignNeutral } =
    useContext(GlobalState);
  let init = {
      enabler: "",
      type: "",
      price: "",
      resellerPrice: "",
      topuserPrice: "",
      agentPrice: "",
    },
    [state, setState] = useState(init),
    textChange =
      (name) =>
      ({ target: { value } }) => {
        setState({ ...state, [name]: value });
      },
    [shouldEdit, setShouldEdit] = useState(false),
    [newLoad, setNewLoad] = useState(false),
    [submit, setSubmit] = useState(false),
    [image, setImages] = useState();

  useEffect(() => {
    if (datum) {
      setState(datum);
      if (datum?._id) setShouldEdit(true);
      setImages(datum?.image ? datum?.image : false);
    }
    return () => setShouldEdit(false);
  }, [datum]);

  let handleSubmit = async (e) => {
    e?.preventDefault();
    if (!state?.type) return;

    let datee = state;

    if (!datum) {
      // return toast.info("Pleae fill out all fields");
      // if (!image) return toast.warn("Tour image required");
      datee = { ...state, image };
    } else {
      if (image === datum?.image) {
        datee = { ...state };
      } else datee = { ...state, image };
    }

    setNewLoad(true);
    datum?._id
      ? await manageEducationBundle(datee, datum?._id, "edit")
      : await manageEducationBundle(datee);
    setNewLoad(false);
    setSubmit(true);
  };

  useEffect(() => {
    if (submit && educationBundle?.isAdded) {
      setSubmit(false);
      setState(init);
      back();
    }
    if (submit && educationBundle?.isUpdated) {
      setSubmit(false);
      setState(init);
      back();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit, educationBundle?.isAdded, educationBundle?.isUpdated]);

  return (
    <>
      <ModalComponents
        title={datum?._id ? `update ${datum?.type}` : "add education plan"}
        isOpen={isOpen}
        back={back}
      >
        <div className="downH2 d-flex">
          <form className="w-100">
            <UploadPicture img={image} setImages={setImages} />
            <div className="mb-4">
              <label htmlFor="value">Type name</label>
              <input
                type={"text"}
                placeholder="Type name"
                className="form-control py-3"
                value={state?.type}
                onChange={textChange("type")}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="value">Price</label>
              {/* <input
								type={"number"}
								placeholder="200"
								className="form-control py-3"
								value={state?.price}
								onChange={textChange("price")}
							/> */}
              <NumericFormat
                prefix={`${nairaSignNeutral} `}
                className="form-control py-3"
                value={state?.price}
                placeholder="500"
                displayType="input"
                thousandSeparator={true}
                onValueChange={(val) =>
                  setState({ ...state, price: val?.floatValue })
                }
                min={0}
                inputMode="decimal"
                renderText={(value, props) => <span {...props}>{value}</span>}
                allowNegative={false}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="value">Reseller price</label>
              {/* <input
								type={"number"}
								placeholder="200"
								className="form-control py-3"
								value={state?.resellerPrice}
								onChange={textChange("resellerPrice")}
							/> */}
              <NumericFormat
                prefix={`${nairaSignNeutral} `}
                className="form-control py-3"
                value={state?.resellerPrice}
                placeholder="500"
                displayType="input"
                thousandSeparator={true}
                onValueChange={(val) =>
                  setState({ ...state, resellerPrice: val?.floatValue })
                }
                min={0}
                inputMode="decimal"
                renderText={(value, props) => <span {...props}>{value}</span>}
                allowNegative={false}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="value">Agent price</label>
              {/* <input
								type={"number"}
								placeholder="200"
								className="form-control py-3"
								value={state?.agentPrice}
								onChange={textChange("agentPrice")}
							/> */}
              <NumericFormat
                prefix={`${nairaSignNeutral} `}
                className="form-control py-3"
                value={state?.agentPrice}
                placeholder="500"
                displayType="input"
                thousandSeparator={true}
                onValueChange={(val) =>
                  setState({ ...state, agentPrice: val?.floatValue })
                }
                min={0}
                inputMode="decimal"
                renderText={(value, props) => <span {...props}>{value}</span>}
                allowNegative={false}
              />
            </div>
            {/* <div className="mb-4">
							<label htmlFor="value">Topuser price</label>
							<input
								type={"number"}
								placeholder="200"
								className="form-control py-3"
								value={state?.topuserPrice}
								onChange={textChange("topuserPrice")}
							/>
						</div> */}

            {shouldEdit && (
              <>
                <div className="mb-4">
                  <label htmlFor="value">Status</label>
                  <select
                    name="Status"
                    className="form-control py-3"
                    value={state?.enabler}
                    onChange={textChange("enabler")}
                    id=""
                  >
                    <option value="">Select status</option>
                    <option value="enable">Enabled</option>
                    <option value="disable">Disabled</option>
                  </select>
                </div>
              </>
            )}
            <Buttons
              title={datum?._id ? "update" : "create"}
              css="btn-primary1 text-capitalize py-3 px-4 px-lg-5 mx-auto my-4"
              width={"w-50 w50"}
              onClick={handleSubmit}
              style={{ borderRadius: "30px" }}
              loading={newLoad}
            />
          </form>
        </div>
      </ModalComponents>
    </>
  );
};

const BillerCategorySelection = ({ isOpen, back, setEducation }) => {
  const { category } = useContext(GlobalState);
  let [data, setThisEducation] = useState({
    category: "",
  });

  return (
    <ModalComponents
      title={"Add education plan process"}
      isOpen={isOpen}
      toggle={back}
    >
      <>
        {
          <>
            <h3 className="Lexend">Select Category</h3>
            <div className="row mx-0 g-3 g-md-4">
              {category?.data
                ?.filter((item) => item?.product?.name === "education")
                ?.map((item, i) => (
                  <div
                    className="col-6 col-md-4 p-2"
                    onClick={() => {
                      setThisEducation({ ...data, category: item?._id });
                    }}
                    key={i}
                  >
                    <button
                      className={`btn ${
                        item?._id === data?.category
                          ? "btn-primary1"
                          : "btn-outline-primary1"
                      } w-100 h-100 text-uppercase py-3 py-md-5 rounded20`}
                    >
                      {item?.name}
                    </button>
                  </div>
                ))}
            </div>
            <Buttons
              style={{ borderRadius: "30px" }}
              title={"next"}
              css="btn-primary1 text-capitalize py-3 px-4 px-lg-5 mx-auto my-4"
              width={"w-50 w50"}
              onClick={() => {
                if (!data?.category) return;
                setEducation(data);
                back();
              }}
            />
          </>
        }
      </>
    </ModalComponents>
  );
};
