import React, { useContext, useEffect, useState } from "react";
import { GlobalState } from "../../../Data/Context";
import { Container } from "reactstrap";
import { Buttons, EmptyComponent } from "../../../Utils";
import moment from "moment";
import { AddAndDelete } from ".";
import { ModalComponents } from "../../../Components";
import { UploadPicture } from "../../sliders";
import { useDispatch } from "react-redux";
import { manageServiceIcon } from "../../../Data/Reducer/UseCaseReducer";
import { ReArrangeFiles } from "../../../Components/LoadMore";
import { DeleteModal } from "./privacy";

const ServiceIcon = () => {
	let { setStateName, usecase, auth, getUseCase } = useContext(GlobalState),
		[isOpen, setIsOpen] = useState(false),
		[datum, setDatum] = useState(null),
		toggle = () => {
			if (isOpen) if (datum) setDatum(false);
			setIsOpen(!isOpen);
		},
		[state, setState] = useState(null),
		[rearrange, setRearrange] = useState(false);

	useEffect(() => {
		if (datum?.type === "update") setIsOpen(true);
	}, [datum]);

	useEffect(() => {
		setState(usecase?.serviceIcon);
	}, [usecase?.serviceIcon]);

	useEffect(() => {
		setStateName("Manage Service Icons");
		getUseCase();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!state) return;

	return (
		<div className="bg-white aboutScreen">
			<Container className="py-5">
				{auth?.user?.isAdmin && (
					<div className="d-flex justify-content-between">
						{!rearrange ? (
							<Buttons
								title={"add new Service Icon"}
								css="btn-primary1 text-capitalize py-3 px-4 px-lg-5"
								width={"w-25 w25"}
								onClick={toggle}
								style={{ borderRadius: "30px" }}
							/>
						) : (
							<div className=""></div>
						)}
						{state?.length > 1 && (
							<Buttons
								title={"Rearrange"}
								css="btn-primary1 text-capitalize py-3 px-4 px-lg-5 ml-auto"
								width={"w-25 w25"}
								style={{ borderRadius: "30px" }}
								onClick={() => setRearrange(!rearrange)}
							/>
						)}
					</div>
				)}
				{rearrange ? (
					<ReArrangeFiles state={state} setRearrange={setRearrange} />
				) : (
					<ServiceIconTermsTable state={state} setDatum={setDatum} />
				)}
			</Container>
			<AddServiceIcon isOpen={isOpen} back={toggle} datum={datum?.data} />
			<DeleteModal
				setDatum={setDatum}
				type="service-icon"
				name="service icon"
				state={datum?.data}
				isOpen={datum?.type === "delete"}
			/>
		</div>
	);
};

export default ServiceIcon;

export const ServiceIconTermsTable = ({ state, setDatum }) => {
	return (
		<div className="py-3 py-md-5">
			<div className="bland row mx-0 py-3 px-0 text-capitalize Lexend fw-bold">
				<div className="col textTrunc Lexend d-none d-md-flex">S/N</div>
				<div className="col textTrunc Lexend">Title</div>
				<div className="col textTrunc Lexend">Image</div>
				<div className="col textTrunc Lexend">App Navigation Title</div>
				<div className="col textTrunc Lexend">Web Navigation Title</div>
				<div className="col textTrunc Lexend">Date&time</div>
				<div className="col textTrunc Lexend">Actions</div>
			</div>
			{state?.length === 0 ? (
				<EmptyComponent subtitle={"Activity is empty"} />
			) : (
				state?.map((it, i) => (
					<div key={i} className="row mx-0 py-3 border-bottom myCursor">
						<div className="col my-auto text-capitalize fontReduce2 textTrunc py-3 py-md-4 d-none d-md-flex">
							{i + 1}
						</div>
						<div className="col my-auto text-capitalize fontReduce2 textTrunc py-3 py-md-4 textTrunc2">
							{it?.title}
						</div>
						<div className="col my-auto">
							<img
								src={it?.image?.url}
								alt={it?.image?.name}
								className="img-fluid rounded imgFluid"
								style={{
									height: "10rem",
									width: "100%",
								}}
							/>
						</div>
						<div className="col my-auto fontReduce2 textTrunc py-3 py-md-4 textTrunc4 textTrunc5">
							{it?.navigationTitle}
						</div>
						<div className="col my-auto fontReduce2 textTrunc py-3 py-md-4 textTrunc4 textTrunc5">
							{it?.webNavigationTitle}
						</div>
						<div className="col my-auto fontReduce2 textTrunc py-3 py-md-4 textTrunc4">
							{moment(it?.createdAt).format("DD/MM/YYYY hh:mm:ss A")}
						</div>
						<div className="col my-auto fontReduce2 textTrunc py-3 py-md-4 textTrunc4">
							<AddAndDelete
								handleDelete={
									!setDatum
										? null
										: () => setDatum({ type: "delete", data: it })
								}
								handleEdit={
									!setDatum
										? null
										: () => setDatum({ type: "update", data: it })
								}
							/>
						</div>
					</div>
				))
			)}
		</div>
	);
};

export const AddServiceIcon = ({ isOpen, back, datum }) => {
	let { usecase } = useContext(GlobalState);
	let init = {
			enabler: "",
			title: "",
			navigationTitle: "",
			webNavigationTitle: "",
		},
		[state, setState] = useState(init),
		textChange =
			name =>
			({ target: { value } }) => {
				setState({ ...state, [name]: value });
			},
		[shouldEdit, setShouldEdit] = useState(false),
		[newLoad, setNewLoad] = useState(false),
		[submit, setSubmit] = useState(false),
		[image, setImages] = useState(),
		dispatch = useDispatch();

	useEffect(() => {
		if (datum) {
			setState(datum);
			if (datum?._id) setShouldEdit(true);
			setImages(datum?.image ? datum?.image : false);
		}
		return () => setShouldEdit(false);
	}, [datum]);

	let handleSubmit = async e => {
		e?.preventDefault();
		if (!state?.title || !state?.navigationTitle || !state?.webNavigationTitle)
			return;

		let datee = state;

		if (!datum) {
			// return toast.info("Pleae fill out all fields");
			// if (!image) return toast.warn("Tour image required");
			datee = { ...state, image };
		} else {
			if (image === datum?.image) {
				datee = { ...state };
			} else datee = { ...state, image };
		}

		setNewLoad(true);
		await dispatch(manageServiceIcon(datum?._id ? "put" : "post", datee));
		setNewLoad(false);
		setSubmit(true);
	};

	useEffect(() => {
		if (submit && usecase?.status) {
			setSubmit(false);
			setState(init);
			back();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [submit, usecase?.status]);

	return (
		<>
			<ModalComponents
				title={datum?._id ? `update ${datum?.title}` : "add service icon"}
				isOpen={isOpen}
				back={back}>
				<div className="downH2 d-flex">
					<form className="w-100">
						<UploadPicture img={image} setImages={setImages} />
						<div className="mb-4">
							<label htmlFor="value">Title</label>
							<input
								type={"text"}
								placeholder="Title"
								className="form-control py-3"
								value={state?.title}
								onChange={textChange("title")}
							/>
						</div>
						<div className="mb-4">
							<label htmlFor="value">App Navigation Title</label>
							<input
								type={"text"}
								placeholder="App Navigation Title"
								className="form-control py-3"
								value={state?.navigationTitle}
								onChange={textChange("navigationTitle")}
							/>
						</div>
						<div className="mb-4">
							<label htmlFor="value">Web Navigation Title</label>
							<input
								type={"text"}
								placeholder="Web Navigation Title"
								className="form-control py-3"
								value={state?.webNavigationTitle}
								onChange={textChange("webNavigationTitle")}
							/>
						</div>

						{shouldEdit && (
							<>
								<div className="mb-4">
									<label htmlFor="value">Status</label>
									<select
										name="Status"
										className="form-control py-3"
										value={state?.enabler}
										onChange={textChange("enabler")}
										id="">
										<option value="">Select status</option>
										<option value="enable">Enabled</option>
										<option value="disable">Disabled</option>
									</select>
								</div>
							</>
						)}
						<Buttons
							title={datum?._id ? "update" : "create"}
							css="btn-primary1 text-capitalize py-3 px-4 px-lg-5 mx-auto my-4"
							width={"w-50 w50"}
							onClick={handleSubmit}
							style={{ borderRadius: "30px" }}
							loading={newLoad}
						/>
					</form>
				</div>
			</ModalComponents>
		</>
	);
};
