import React, { useContext, useEffect, useState } from "react";
import { GlobalState } from "../../Data/Context";
import { Container } from "reactstrap";
import { Buttons, EmptyComponent } from "../../Utils";
import { ModalComponents } from "../../Components";
import { BiEditAlt, BiTrashAlt } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { ExportButtons2 } from "../../Utils/Export Functions/ExportButtons";

const MainStartimes = () => {
  let {
      setStateName,
      startimes,
      manageStartimes,
      nairaSign,
      numberWithCommas,
      auth,
      permission,
    } = useContext(GlobalState),
    [isOpen, setIsOpen] = useState(false),
    [datum, setDatum] = useState(null),
    toggle = () => {
      if (isOpen) if (datum) setDatum(false);
      setIsOpen(!isOpen);
    },
    [loading, setLoading] = useState(null),
    [state, setState] = useState(null),
    navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (auth?.user?.privilege !== "superadmin") {
      let findTitle = "viewmanagestartimes";

      let findPermit = permission?.data?.find(
        (item) =>
          item?.slug === findTitle && item?.user?.includes(auth?.user?._id)
      );
      if (!findPermit) {
        navigate(-1);
      }
    }
  }, [auth?.user, permission?.data, navigate]);

  useEffect(() => {
    if (datum) setIsOpen(true);
  }, [datum]);

  useEffect(() => {
    setState(startimes?.data);
  }, [startimes?.data]);

  useEffect(() => {
    setStateName("Manage Startimes");
    manageStartimes("get");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headers = [
    { title: "S/N", field: "serialNumber" },
    { title: "Name", field: "name" },
    { title: "Price", field: "price" },
  ];

  const data = state?.map((item, index) => ({
    serialNumber: index + 1,
    name: item?.name ? `${item?.name}` : "",
    price: item?.price
      ? `₦ ${item?.price && numberWithCommas(Number(item?.price).toFixed(2))} `
      : "",
  }));

  return (
    <>
      <div className="bg-white aboutScreen">
        <Container className="py-5">
          {auth?.user?.isAdmin && (
            <Buttons
              title={"add startimes"}
              css="btn-primary1 text-capitalize py-3 px-4 px-lg-5"
              width={"w-25 w25"}
              onClick={toggle}
              style={{ borderRadius: "30px" }}
            />
          )}

          <div className="tw-flex tw-justify-between tw-items-center">
            <div className="tw-flex-1"></div>
            <ExportButtons2
              headers={headers}
              data={data}
              fileName={`${params?.page} ${
                params?.id ? `(${params?.id})` : ""
              }`}
            />
          </div>
          <div className="py-3">
            <div className="row mx-0 p-3 bland">
              <div className="col my-auto textTrunc fontReduce fw-bold Lexend">
                S/N
              </div>
              <div className="col my-auto textTrunc fontReduce fw-bold Lexend">
                Name
              </div>
              <div className="col my-auto textTrunc fontReduce fw-bold Lexend">
                Price
              </div>
              <div className="col my-auto textTrunc fontReduce fw-bold Lexend">
                Actions
              </div>
            </div>
            {state?.length === 0 ? (
              <EmptyComponent />
            ) : (
              state?.map((item, i) => (
                <div className="py-3 row mx-0" key={i}>
                  <div className="col my-auto textTrunc fontReduce2">
                    {i + 1}
                  </div>
                  <div className="col my-auto textTrunc fontReduce2 text-capitalize">
                    {item?.package}
                    {item?.name}
                  </div>
                  <div className="col my-auto textTrunc fontReduce2">
                    {nairaSign}{" "}
                    {item?.price &&
                      numberWithCommas(Number(item?.price).toFixed(2))}
                    {item?.plan_amount &&
                      numberWithCommas(Number(item?.plan_amount).toFixed(2))}
                  </div>
                  {auth?.user?.isAdmin && (
                    <div className="d-flex align-items-center ms-auto col">
                      {loading !== item?._id && (
                        <>
                          <BiEditAlt
                            size={20}
                            color="blue"
                            className="myCursor mx-1"
                            onClick={() => setDatum(item)}
                          />
                          <BiTrashAlt
                            size={20}
                            color="red"
                            onClick={async () => {
                              if (
                                window.confirm("Do you want to delete this")
                              ) {
                                setLoading(item?._id);
                                await manageStartimes("delete", item);
                                setLoading(false);
                              }
                            }}
                            className="myCursor"
                          />
                        </>
                      )}
                    </div>
                  )}
                </div>
              ))
            )}
          </div>
        </Container>
      </div>
      <AddNewStartimes isOpen={isOpen} toggle={toggle} datum={datum} />
    </>
  );
};

export default MainStartimes;

let AddNewStartimes = ({ isOpen, toggle, datum }) => {
  let { startimes, manageStartimes, nairaSignNeutral } =
    useContext(GlobalState);

  let init = { name: "", price: "" },
    [loading, setLoading] = useState(false),
    [submit, setSubmit] = useState(false),
    [state, setState] = useState(init),
    handleSubmit = async (e) => {
      if (e) e.preventDefault();
      if (!state?.name && !state?.price) return;
      setLoading(true);
      await manageStartimes(datum ? "put" : "post", state);
      setLoading(false);
      setSubmit(true);
    };

  useEffect(() => {
    if (datum) setState(datum);
  }, [datum]);

  useEffect(() => {
    if (submit && startimes?.isAdded) {
      setSubmit(false);
      setState(init);
      toggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit, startimes?.isDeleted, startimes?.isAdded]);

  return (
    <>
      <ModalComponents
        isOpen={isOpen}
        back={toggle}
        title={datum ? `Edit startimes` : `Add startimes`}
      >
        <div>
          <div className="mb-3">
            <label htmlFor="Summary">Name</label>
            <input
              type="text"
              className="py-3 form-control"
              value={state?.name}
              onChange={(e) => setState({ ...state, name: e.target.value })}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="Summary">Price</label>
            {/* <input
							type="number"
							className="py-3 form-control"
							value={state?.price}
							onChange={e => setState({ ...state, price: e.target.value })}
							min={0}
						/> */}
            <NumericFormat
              prefix={`${nairaSignNeutral} `}
              className="form-control py-3"
              value={state.price}
              placeholder="500"
              displayType="input"
              thousandSeparator={true}
              onValueChange={(val) =>
                setState({ ...state, price: val?.floatValue })
              }
              min={0}
              inputMode="decimal"
              renderText={(value, props) => <span {...props}>{value}</span>}
              allowNegative={false}
            />
          </div>
          <Buttons
            title={datum ? "update" : "add"}
            css="btn-primary1 text-capitalize py-3 px-4 px-lg-5 mx-auto"
            width={"w-50 w50"}
            onClick={handleSubmit}
            loading={loading}
            style={{ borderRadius: "30px" }}
          />
        </div>
      </ModalComponents>
    </>
  );
};
