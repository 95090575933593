import React, { useContext, useEffect, useState, useRef } from "react";
import { GlobalState } from "./Data/Context";
import { io } from "socket.io-client";
import { useURL } from "./Data/Config";
import { useLocation } from "react-router-dom";
import audio2 from "./Assets/message-tone-checked-off.mp3";

const SocketClient = () => {
	const {
			auth,
			socketProfile,
			socketOnline,
			transactions,
			socketTransaction,
			getChoosenServices,
			getRpiData,
			getOgdams,
			getRingo,
			getMcd,
			getDataTransactionStat,
			getProviderStat,
			getDataHistory,
		} = useContext(GlobalState),
		location = useLocation(),
		audioRef = useRef();

	let [socketNew, setSocket] = useState(null);

	const setUpSocket = async () => {
		var newSocket = io(useURL, {
			query: {
				userId: auth?.user?._id,
			},
			transports: ["websocket"],
		});
		newSocket.on("disconnect", () => {
			setSocket(null);
			setTimeout(setUpSocket, 3000);
		});
		setSocket(newSocket);
		// console.log({ ss: newSocket });
	};

	useEffect(() => {
		setUpSocket();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// console.log({ s: socketNew });
	useEffect(() => {
		if (socketNew) {
			socketProfile(socketNew);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socketNew]);

	// console.log({ socket });
	// JoinUser
	useEffect(() => {
		socketNew?.emit("checkUserOnlineAdmin");
	}, [socketNew, auth?.user]);

	// New Message
	useEffect(() => {
		socketNew?.on("newTransaction", newTransaction => {
			let findTrans = transactions?.transactions.find(
				item => item?._id === newTransaction?._id
			);

			if (!findTrans) {
				audioRef.current.play();
				socketTransaction(newTransaction);
				getDataTransactionStat();
				getProviderStat({ route: "/manage-transactions" });
				if (newTransaction?.provider === "choosen") getChoosenServices();
				if (newTransaction?.provider === "rpi") getRpiData();
				if (newTransaction?.provider === "ringo") getRingo();
				if (newTransaction?.provider === "mcd") getMcd();
				if (newTransaction?.provider === "ringo") getOgdams();
				if (newTransaction?.type === "data") getDataHistory();
			}
		});
		// console.log({ socketNew });
		return () => {
			socketNew?.off("newTransaction");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socketNew, location.pathname]);

	useEffect(() => {
		socketNew?.on("get-online-users-admin", users => {
			socketOnline(users);
		});
		// console.log({ socketNew });
		return () => {
			socketNew?.off("get-online-users");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socketNew, location.pathname]);

	return (
		<>
			<audio
				controls
				ref={audioRef}
				className="notificationSound"
				style={{ display: "none" }}>
				<source src={audio2} type="audio/mp3" />
			</audio>
		</>
	);
};

export default SocketClient;
