import {
	ADD_ELECTRICITY,
	ADD_ELECTRICITY_BUNDLE,
	ADD_ELECTRICITY_BUNDLE_FAIL,
	ADD_ELECTRICITY_FAIL,
	ADD_ELECTRICITY_SOCKET,
	DELETE_ELECTRICITY_BUNDLE,
	DELETE_TRANSACTION,
	DELETE_TRANSACTION_FAIL,
	GET_ALL_ELECTRICITY,
	GET_ELECTRICITY,
	GET_ELECTRICITY_BUNDLE,
	GET_ELECTRICITY_BUNDLE_FAIL,
	GET_ELECTRICITY_DIRECT,
	GET_ELECTRICITY_FAIL,
	GET_ELECTRICITY_LOADING,
	GET_ELECTRICITY_TO_BUY,
	LOGOUT,
	SEARCH_ALL_ELECTRICITY,
	SEARCH_ALL_ELECTRICITY_FAIL,
	SEARCH_ALL_ELECTRICITY_LOADING,
	SEARCH_RELOAD,
	SEARCH_ELECTRICITY,
	SEARCH_ELECTRICITY_BUNDLE,
	SEARCH_ELECTRICITY_BUNDLE_FAIL,
	SEARCH_ELECTRICITY_BUNDLE_LOADING,
	SEARCH_ELECTRICITY_FAIL,
	SEARCH_ELECTRICITY_LOADING,
	UPDATE_ELECTRICITY_BUNDLE,
	GET_ALL_MY_ELECTRICITY,
	GET_ALL_ALL_ELECTRICITY,
} from "../Actions/ActionTypes";
import { DeleteData, EditData } from "./AuthReducer";

const initialState = {
	isLoading: false,
	electricity: [],
	electricity_direct: [],
	isAdded: false,
	isDeleted: false,
	isFound: null,
	searchLoading: null,
	mainSearch: [],
	search: "",
	search_paginate: null,
	electricityToBuy: null,
	all_electricity: [],
	all_paginate: null,
	all_isFound: null,
	all_searchLoading: null,
	all_mainSearch: [],
	all_search: "",
	all_search_paginate: null,
};

const ElectricityReducer = (state = initialState, action) => {
	const { type, payload } = action;
	let data = payload?.data ? payload?.data : payload;
	switch (type) {
		case SEARCH_ALL_ELECTRICITY:
			return {
				...state,
				all_isFound: true,
				all_searchLoading: false,
				all_mainSearch:
					action?.search === state?.all_search ? data : state?.all_mainSearch,
				all_search_paginate:
					action?.search === state?.all_search
						? payload?.paginate
						: state?.all_search_paginate,
			};
		case SEARCH_ALL_ELECTRICITY_FAIL:
			return {
				...state,
				all_searchLoading: false,
				all_mainSearch: state.mainSearch,
				all_search_paginate: state.search_paginate,
				all_isFound: false,
			};
		case SEARCH_ALL_ELECTRICITY_LOADING:
			return {
				...state,
				all_search: action.search,
				all_searchLoading: true,
			};
		case SEARCH_RELOAD:
			return {
				...state,
				all_isFound: false,
				all_searchLoading: false,
				isFound: false,
				searchLoading: false,
			};
		case GET_ELECTRICITY_TO_BUY:
			return {
				...state,
				electricityToBuy: data ? data : [],
			};
		case SEARCH_ELECTRICITY:
			return {
				...state,
				isFound: true,
				searchLoading: false,
				mainSearch: action?.search === state?.search ? data : state?.mainSearch,
				search_paginate:
					action?.search === state?.search
						? payload?.paginate
						: state?.search_paginate,
			};
		case SEARCH_ELECTRICITY_FAIL:
			return {
				...state,
				searchLoading: false,
				isFound: false,
				mainSearch: state.mainSearch,
				search_paginate: state.search_paginate,
			};
		case SEARCH_ELECTRICITY_LOADING:
			return {
				...state,
				search: action.search,
				searchLoading: true,
			};
		case ADD_ELECTRICITY:
			return {
				...state,
				isAdded: true,
				electricity: [data, ...state.electricity],
				all_electricity: [data, ...state.all_electricity],
				paginate: {
					...state?.paginate,
					result: state?.paginate?.result + 1,
					total: state?.paginate?.total + 1,
				},
			};
		case ADD_ELECTRICITY_SOCKET:
			return {
				...state,
				all_electricity: [data, ...state.all_electricity],
			};
		case ADD_ELECTRICITY_FAIL:
			return {
				...state,
				isAdded: false,
				isDeleted: false,
			};
		case GET_ELECTRICITY:
			return {
				...state,
				isLoading: false,
				electricity: data,
				paginate: payload?.paginate,
			};
		case GET_ALL_MY_ELECTRICITY:
			return {
				...state,
				myall: payload?.data,
			};
		case GET_ALL_ALL_ELECTRICITY:
			return {
				...state,
				all: payload?.data,
			};
		case GET_ALL_ELECTRICITY:
			return {
				...state,
				isLoading: false,
				all_electricity: data,
				all_paginate: payload?.paginate,
			};
		case DELETE_TRANSACTION:
			return {
				...state,
				mainSearch: DeleteData(state.mainSearch, payload),
				electricity: DeleteData(state.electricity, payload),
				all_mainSearch: DeleteData(state.all_mainSearch, payload),
				all_electricity: DeleteData(state.all_electricity, payload),
				isDeleted: true,
			};
		case DELETE_TRANSACTION_FAIL:
			return {
				...state,
				isDeleted: false,
			};
		case GET_ELECTRICITY_DIRECT:
			return {
				...state,
				electricity_direct: data,
			};
		case GET_ELECTRICITY_FAIL:
			return {
				...state,
				isLoading: false,
			};
		case GET_ELECTRICITY_LOADING:
			return {
				...state,
				isLoading: true,
			};
		case LOGOUT:
			return initialState;
		default:
			return state;
	}
};

export default ElectricityReducer;

const initialState3 = {
	isLoading: false,
	data: [],
	isAdded: false,
	isUpdated: false,
	paginate: null,
	isDeleted: null,
	isFound: null,
	searchLoading: null,
	mainSearch: [],
	search: "",
	search_paginate: null,
};

export const ElectricityBundleReducer = (state = initialState3, action) => {
	const { type, payload } = action;
	let data = payload?.data ? payload?.data : payload;

	switch (type) {
		case SEARCH_ELECTRICITY_BUNDLE:
			return {
				...state,
				isFound: true,
				searchLoading: false,
				mainSearch: action?.search === state?.search ? data : state?.mainSearch,
				search_paginate:
					action?.search === state?.search
						? payload?.paginate
						: state?.search_paginate,
			};
		case SEARCH_ELECTRICITY_BUNDLE_FAIL:
			return {
				...state,
				searchLoading: false,
				mainSearch: state.mainSearch,
				search_paginate: state.search_paginate,
			};
		case SEARCH_ELECTRICITY_BUNDLE_LOADING:
			return {
				...state,
				search: action.search,
				searchLoading: true,
			};
		case SEARCH_RELOAD:
			return {
				...state,
				isFound: false,
				searchLoading: false,
			};
		case GET_ELECTRICITY_BUNDLE:
			return {
				...state,
				isLoading: false,
				data: data ? data : [],
				paginate: payload?.paginate,
			};
		case GET_ELECTRICITY_BUNDLE_FAIL:
			return {
				...state,
				isLoading: false,
			};
		case ADD_ELECTRICITY_BUNDLE:
			return {
				...state,
				isAdded: true,
				data: [data, ...state?.data],
				paginate: {
					...state?.paginate,
					result: state?.paginate?.result + 1,
					total: state?.paginate?.total + 1,
				},
			};
		case DELETE_ELECTRICITY_BUNDLE:
			return {
				...state,
				isDeleted: true,
				data: DeleteData(state?.data, data),
				paginate: {
					...state?.paginate,
					result: state?.paginate?.result - 1,
					total: state?.paginate?.total - 1,
				},
			};
		case ADD_ELECTRICITY_BUNDLE_FAIL:
			return {
				...state,
				isLoading: false,
				isUpdated: false,
				isAdded: false,
				isDeleted: false,
			};
		case UPDATE_ELECTRICITY_BUNDLE:
			return {
				...state,
				isUpdated: true,
				data: EditData(state?.data, data),
				mainSearch: EditData(state?.mainSearch, data),
			};
		case LOGOUT:
			return initialState3;
		default:
			return state;
	}
};
