import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { Container } from "reactstrap";
import { GlobalState } from "../../../Data/Context";
// import userImg from "../../../Assets/avatar3.png";
import { Buttons } from "../../../Utils";
import { ModalComponents } from "../../../Components";
import { EditData } from "../../../Data/Reducer/AuthReducer";
import { CgBalanceComponent } from "../../../Components/Products/CgWallet";
import { AvatarImg } from "../../../Components/DefaultHeader";
import { toast } from "react-toastify";
import { BiCopy } from "react-icons/bi";
import { colorArr } from "../../../Components/Wallets";
import { RiShieldStarFill } from "react-icons/ri";
import axios from "axios";
import { NumericFormat } from "react-number-format";

const UserProfile = () => {
	const {
			allUsers,
			numberWithCommas,
			setStateName,
			nairaSign,
			manageUserActiveness,
			getPermission,
			permission,
			superadmin,
			manager,
			marketer,
			developer,
			topuser,
			agent,
			reseller,
			user,
			accountant,
		} = useContext(GlobalState),
		[state, setState] = useState(null),
		[loading, setLoading] = useState(""),
		[data, setData] = useState({
			email: state?.email,
			telephone: state?.telephone,
		}),
		params = useParams(),
		[getSearch] = useSearchParams(),
		[isOpen, setIsOpen] = useState(false),
		[permit, setPermit] = useState(null),
		[submit, setSubmit] = useState(false),
		[columnUse, setColumnUse] = useState(null),
		location = useLocation(),
		[virtual, setVirtual] = useState(null),
		[referral, setReferral] = useState(null);

	useEffect(() => {
		if (location?.state?.privilege === "superadmin") setColumnUse(superadmin);
		if (location?.state?.privilege === "manager") setColumnUse(manager);
		if (location?.state?.privilege === "marketer") setColumnUse(marketer);
		if (location?.state?.privilege === "accountant") setColumnUse(accountant);
		if (location?.state?.privilege === "developer") setColumnUse(developer);
		if (location?.state?.privilege === "topuser") setColumnUse(topuser);
		if (location?.state?.privilege === "reseller") setColumnUse(reseller);
		if (location?.state?.privilege === "agent") setColumnUse(agent);
		if (location?.state?.privilege === "user") setColumnUse(user);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		location?.state,
		superadmin,
		manager,
		marketer,
		accountant,
		developer,
		topuser,
		reseller,
		user,
		agent,
	]);

	useEffect(() => {
		setPermit(permission?.data);
	}, [permission?.data]);

	useEffect(() => {
		if (submit && allUsers?.isUpdated) {
			setState({
				email: state?.email,
				telephone: state?.telephone,
			});
			setIsOpen(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [submit, allUsers?.isUpdated]);

	useEffect(() => {
		setStateName(
			`${
				getSearch?.get("name")
					? getSearch?.get("name")?.split("_")?.join(" ")
					: ""
			}'s profile`
		);
		getPermission();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let findItem = null;
		if (columnUse?.all?.length > 0) {
			findItem = columnUse?.all?.find(item => item?._id === params?.step);
		}
		if (!findItem)
			if (columnUse?.mainSearch?.length > 0) {
				findItem = columnUse?.mainSearch?.find(
					item => item?._id === params?.step
				);
			}
		if (!findItem)
			if (columnUse?.data?.length > 0) {
				findItem = columnUse?.data?.find(item => item?._id === params?.step);
			}
		if (!findItem)
			findItem = allUsers?.data?.find(item => item?._id === params?.step);

		setState(findItem);
	}, [
		allUsers?.data,
		params?.step,
		columnUse?.all,
		columnUse?.data,
		columnUse?.mainSearch,
	]);

	useEffect(() => {
		if (params?.step) {
			let getUserDetails = async () => {
				try {
					let res = await axios.get(
						`/api/v2/user/manage-users-by-id?user=${params?.step}`
					);
					console.log({ user: res?.data, params: params?.step });
					if (res?.data?.data) setState(res?.data?.data);
				} catch (err) {
					if (err) console.log({ err });
					if (err)
						console.log(err?.response ? err?.response?.data : err?.message);
				}
			};
			let getReferral = async () => {
				try {
					let res = await axios.get(
						`/api/v2/user/manage-referral?type=all&referee=${params?.step}`
					);
					console.log({ referral: res?.data, params: params?.step });
					setReferral(res?.data);
				} catch (err) {
					if (err) console.log({ err });
					if (err)
						console.log(err?.response ? err?.response?.data : err?.message);
				}
			};
			getUserDetails();
			getReferral();
		}
	}, [params?.step]);

	if (!state) return;
	// console.log({ state });
	return (
		<div className="py-4 bg-white aboutScreen">
			<Container className="py-5">
				<div className="px-md-4 px-2 mb-5 col-lg-10">
					<div className="row mx-0 g-3 g-md-4">
						<div className="col-md order-2 order-md-1">
							<p>
								Contact:{" "}
								<strong>
									<span
										className="fontInherit Lexend force-d-flex myCursor"
										onClick={
											state?.telephone
												? () => {
														navigator.clipboard
															.writeText(state?.telephone)
															.then(
																() => {
																	toast.info("Copied", { autoClose: 2000 });
																},
																err => {
																	toast.warn(`Could not copy: ${err}`, {
																		autoClose: 2000,
																	});
																}
															);
												  }
												: null
										}>
										{state?.telephone}
										<BiCopy />
									</span>
								</strong>{" "}
							</p>
							<p>
								Email:{" "}
								<strong>
									<span
										className="fontInherit Lexend force-d-flex myCursor"
										onClick={
											state?.email
												? () => {
														navigator.clipboard.writeText(state?.email).then(
															() => {
																toast.info("Copied", { autoClose: 2000 });
															},
															err => {
																toast.warn(`Could not copy: ${err}`, {
																	autoClose: 2000,
																});
															}
														);
												  }
												: null
										}>
										{state?.email}
										<BiCopy />
									</span>
								</strong>{" "}
							</p>
							<p>
								Wallet ID:{" "}
								<strong>
									<span
										className="fontInherit Lexend force-d-flex myCursor"
										onClick={
											state?.wallet?.wallet_id
												? () => {
														navigator.clipboard
															.writeText(state?.wallet?.wallet_id)
															.then(
																() => {
																	toast.info("Copied", { autoClose: 2000 });
																},
																err => {
																	toast.warn(`Could not copy: ${err}`, {
																		autoClose: 2000,
																	});
																}
															);
												  }
												: null
										}>
										{state?.wallet?.wallet_id}
										<BiCopy />
									</span>
								</strong>{" "}
							</p>
							<p>
								Joined since{" "}
								<strong>
									{moment(state?.createdAt).format("Do, MMMM YYYY hh:mm A")}
								</strong>{" "}
							</p>
							<p>
								BVN Status:{" "}
								<strong
									className={`text-capitalize ${
										state?.bvnNumber ? "text-succcess2" : "text-danger2"
									}`}>
									{state?.bvnNumber ? "Linked" : "Not Linked"}
									{!state?.status && state?.bvnNumber ? (
										<>
											(
											<span
												className="fontInherit Lexend force-d-flex myCursor"
												onClick={
													state?.bvnNumber
														? () => {
																navigator.clipboard
																	.writeText(state?.bvnNumber)
																	.then(
																		() => {
																			toast.info("Copied", { autoClose: 2000 });
																		},
																		err => {
																			toast.warn(`Could not copy: ${err}`, {
																				autoClose: 2000,
																			});
																		}
																	);
														  }
														: null
												}>
												{state?.bvnNumber}
												<BiCopy />
											</span>
											)
										</>
									) : null}
								</strong>{" "}
							</p>
							<p>
								NIN Status:{" "}
								<strong
									className={`text-capitalize ${
										state?.ninNumber ? "text-succcess2" : "text-danger2"
									}`}>
									{state?.ninNumber ? "Linked" : "Not Linked"}
									{!state?.status && state?.ninNumber ? (
										<>
											(
											<span
												className="fontInherit Lexend force-d-flex myCursor"
												onClick={
													state?.ninNumber
														? () => {
																navigator.clipboard
																	.writeText(state?.ninNumber)
																	.then(
																		() => {
																			toast.info("Copied", { autoClose: 2000 });
																		},
																		err => {
																			toast.warn(`Could not copy: ${err}`, {
																				autoClose: 2000,
																			});
																		}
																	);
														  }
														: null
												}>
												{state?.ninNumber}
												<BiCopy />
											</span>
											)
										</>
									) : null}
								</strong>{" "}
							</p>
						</div>
						<div className="col-md order-3 order-md-2">
							<p className="text-capitalize">
								Account Type: <strong>{state?.privilege}</strong>{" "}
							</p>
							<p>
								Status:{" "}
								<strong
									className={`text-capitalize ${
										state?.statusText?.toLowerCase() === "activated"
											? "text-succcess2"
											: state?.statusText?.toLowerCase() === "suspended"
											? "text-danger2"
											: ""
									}`}>
									{state?.statusText}
								</strong>{" "}
							</p>
							<p>
								Account Usage Status:{" "}
								<strong
									className={`text-capitalize ${
										state?.activeStatus === "active"
											? "text-succcess2"
											: "text-danger2"
									}`}>
									{state?.activeStatus}
								</strong>{" "}
							</p>
							{state?.activeStatus !== "active" && state?.activeDate ? (
								<p>
									<span className="text-capitalize">
										{state?.activeStatus} since:{" "}
									</span>
									<strong>
										{moment(state?.activeDate).format("Do, MMMM YYYY hh:mm A")}
									</strong>{" "}
								</p>
							) : (
								<p></p>
							)}
							{state?.wallet?.wallet_pin && state?.wallet?.wallet_pin_date ? (
								<p>
									<span className="text-capitalize">
										Wallet Pin Enabled since:{" "}
									</span>
									<strong>
										{moment(state?.wallet?.wallet_pin_date).format(
											"Do, MMMM YYYY hh:mm A"
										)}
									</strong>{" "}
								</p>
							) : (
								<p></p>
							)}
							{!state?.wallet?.wallet_pin &&
							state?.wallet?.wallet_pin_date_disabled ? (
								<p>
									<span className="text-capitalize">
										Wallet Pin Disabled since:{" "}
									</span>
									<strong>
										{moment(state?.wallet?.wallet_pin_date_disabled).format(
											"Do, MMMM YYYY hh:mm A"
										)}
									</strong>{" "}
								</p>
							) : (
								<p></p>
							)}
						</div>
						<div className="col-md order-1 order-md-3">
							{/* <img
								src={state?.avatar?.url ? state?.avatar?.url : userImg}
								alt={`img`}
								style={{
									height: "10rem",
									width: "10rem",
									objectFit: "cover",
									objectPosition: "center 15%",
								}}
								className="rounded-circle img-fluid mx-auto d-block"
								/> */}
							<AvatarImg
								user={state}
								style={{
									height: "10rem",
									width: "10rem",
									objectFit: "cover",
									objectPosition: "center 15%",
									margin: "auto",
									display: "flex",
								}}
							/>

							<div className="my-1">
								<h5 className="Lexend text-center">
									{state?.firstName} {state?.lastName}
								</h5>
								{referral && (
									<small className="text-capitalize d-block text-center">
										<span className="font-bold">
											Referral
											<span className="text-lowercase">(s)</span>:{" "}
										</span>
										{referral?.paginate?.total}
									</small>
								)}
							</div>
						</div>
					</div>
					<div className="row g-3 py-4 mx-0 align-items-center">
						<div className="col-6 col-lg-3 px-2">
							<Buttons
								loading={loading === "password"}
								onClick={async e => {
									e.preventDefault();
									setLoading("password");
									await manageUserActiveness(
										state._id,
										"password",
										null,
										null,
										data
									);
									setLoading("");
								}}
								width="auto"
								css="btn-primary1 text-capitalize py-3 px-md-5"
								title={"reset password"}
							/>
						</div>
						<div className="col-6 col-lg-3 px-2">
							<Buttons
								loading={loading === "wallet"}
								onClick={async e => {
									e.preventDefault();
									setLoading("wallet");
									await manageUserActiveness(
										state._id,
										"wallet",
										null,
										null,
										data
									);
									setLoading("");
								}}
								width="auto"
								css="btn-primary1 text-capitalize py-3 px-md-5"
								title={"reset transaction pin"}
							/>
						</div>
						<div className="col-6 col-lg-3 px-2">
							<Buttons
								loading={loading === "login"}
								onClick={async e => {
									e.preventDefault();
									setLoading("login");
									await manageUserActiveness(
										state._id,
										"login",
										null,
										null,
										data
									);
									setLoading("");
								}}
								width="auto"
								css="btn-primary1 text-capitalize py-3 px-md-5"
								title={"reset login deactivation"}
							/>
						</div>
						<div className="col-6 col-lg-3 px-2">
							<Buttons
								onClick={async e => setIsOpen(true)}
								// onClick={async e => {
								// 	e.preventDefault();
								// 	setLoading("login");
								// 	await manageUserActiveness(
								// 		state._id,
								// 		"login",
								// 		null,
								// 		null,
								// 		data
								// 	);
								// 	setLoading("");
								// }}
								width="auto"
								css="btn-primary1 text-capitalize py-3 px-md-5"
								title={"Change Email/Telephone"}
							/>
						</div>
						<div className="col-6 col-lg-3 px-2">
							<Buttons
								onClick={async e => setVirtual(state)}
								// onClick={async e => {
								// 	e.preventDefault();
								// 	setLoading("login");
								// 	await manageUserActiveness(
								// 		state._id,
								// 		"login",
								// 		null,
								// 		null,
								// 		data
								// 	);
								// 	setLoading("");
								// }}
								width="auto"
								css="btn-primary1 text-capitalize py-3 px-md-5"
								title={"Virtual Account"}
							/>
						</div>
						<div className="col-6 col-lg-3 px-2">
							<Buttons
								loading={loading === "2fa"}
								onClick={async e => {
									e.preventDefault();
									setLoading("2fa");
									await manageUserActiveness(
										state._id,
										"2fa",
										null,
										null,
										data
									);
									setLoading("");
								}}
								width="auto"
								css="btn-primary1 text-capitalize py-3 px-md-5"
								title={"disable 2FA"}
							/>
						</div>
					</div>
					{/* <div
						className="rounded20 text-white p-4 my-3"
						style={{
							background: `linear-gradient(90.18deg, #3199B7 -52.19%, #144468 81.92%)`,
							minHeight: "179px",
						}}>
						<h4 className="text-uppercase mb-3 Lexend">About me</h4>
						<p>{state?.bio}</p>
					</div> */}
					<div className="row mx-0 g-3 g-md-5">
						<div className="col-6 col-md p-2 order-1">
							<button className="btn btn-outline-primary1 w-100 h-100 text-capitalize py-3 py-md-5 rounded20">
								<span className="d-block">Commission</span>
								<span>
									{nairaSign}{" "}
									{state?.wallet?.commission
										? numberWithCommas(
												Number(state?.wallet?.commission).toFixed(2)
										  )
										: 0}
								</span>
							</button>
						</div>
						<div className="col-6 col-md p-2 order-3 order-md-2">
							<button className="btn btn-outline-primary1 w-100 h-100 text-capitalize py-3 py-md-5 rounded20">
								<span className="d-block">Wallet</span>
								<span>
									{nairaSign}{" "}
									{state?.wallet?.available
										? numberWithCommas(
												Number(state?.wallet?.available).toFixed(2)
										  )
										: 0}
								</span>
							</button>
						</div>
						<div className="col-6 col-md p-2 order-2 order-md-3">
							<button className="btn btn-outline-primary1 w-100 h-100 text-capitalize py-3 py-md-5 rounded20">
								<span className="d-block">Bonus</span>
								<span>
									{nairaSign}{" "}
									{state?.wallet?.bonus
										? numberWithCommas(Number(state?.wallet?.bonus).toFixed(2))
										: 0}
								</span>
							</button>
						</div>
						<div className="col-6 col-md p-2 order-4">
							<button className="btn btn-outline-primary1 w-100 h-100 text-capitalize py-3 py-md-5 rounded20">
								<span className="d-block">Referral Bonus</span>
								<span>
									{nairaSign}{" "}
									{state?.wallet?.referral
										? numberWithCommas(
												Number(state?.wallet?.referral).toFixed(2)
										  )
										: 0}
								</span>
							</button>
						</div>
					</div>
					<CgBalanceComponent cgBalance={state?.wallet?.cgBalance} />
					{state?.isAdmin && state?.privilege !== "superadmin" && (
						<div className="py-5">
							<h3 className="Lexend text2">Permission Settings</h3>
							<div className="row mx-0 g-3">
								{permit
									?.sort((a, b) => a.name.localeCompare(b.name))
									?.map((item, i) => (
										<div
											className="col-6 col-md-4 p-2 d-flex align-items-center"
											key={i}>
											<PermissionCheck
												user={state?._id}
												item={item}
												setPermit={setPermit}
												permit={permit}
												i={i}
											/>
											<label
												htmlFor={`permission${i}`}
												className="text-capitalize Lexend my-0">
												{item?.name}
											</label>
										</div>
									))}
							</div>
							<Buttons
								loading={loading === "permission"}
								onClick={async e => {
									e.preventDefault();
									setLoading("permission");
									await getPermission({ permission: permit, user: state?._id });
									setLoading("");
								}}
								width="auto"
								css="btn-primary1 text-capitalize py-3 px-md-5 ms-auto mt-3 Lexend"
								title={"Update permission"}
							/>
						</div>
					)}
				</div>
			</Container>{" "}
			<ModalComponents
				isOpen={isOpen}
				toggle={() => {
					setIsOpen(false);
					setData({ email: state?.email, telephone: state?.telephone });
				}}
				title={"Change Email/Telephone"}>
				<div className="mb-3">
					<label htmlFor="email">Email</label>
					<input
						type="email"
						required
						name="email"
						className="form-control py-3"
						value={data.email}
						onChange={e => setData({ ...data, email: e.target.value })}
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="telephone">Phone Number</label>
					{/* <input
						type="tel"
						required
						name="telephone"
						className="form-control py-3"
						value={data.telephone}
						onChange={e => setData({ ...data, telephone: e.target.value })}
						maxLength={11}
					/> */}
					<NumericFormat
						className="form-control py-3"
						value={data.telephone}
						allowLeadingZeros={true}
						allowNegative={false}
						displayType="input"
						name="phone"
						onValueChange={val => setState({ ...data, telephone: val?.value })}
						maxLength={11}
						decimalScale={0}
						inputMode="tel"
						renderText={(value, props) => <span {...props}>{value}</span>}
					/>
				</div>
				<Buttons
					loading={loading === "details"}
					onClick={async e => {
						e.preventDefault();
						setLoading("details");
						await manageUserActiveness(state._id, "details", null, null, data);
						setLoading("");
						setSubmit(true);
					}}
					width="auto"
					css="btn-primary1 text-capitalize py-3 px-md-5 mx-auto"
					title={"Change Email/Telephone"}
				/>
			</ModalComponents>
			<MakeVirtual back={() => setVirtual(null)} isOpen={virtual} />
		</div>
	);
};

export default UserProfile;

const PermissionCheck = ({ user, item, permit, setPermit, i }) => {
	let [isChecked, setIsChecked] = useState(false);

	useEffect(() => {
		let findUser = item?.user?.find(item => item === user);
		if (findUser) setIsChecked(true);
		else setIsChecked(false);
	}, [user, item]);

	return (
		<span className="ms-3">
			<input
				type="checkbox"
				name={`permission${i}`}
				id={`permission${i}`}
				className="form-check-input form-check form-check-inline"
				checked={isChecked}
				onChange={e => {
					let newItem = item;
					if (e.target.checked)
						newItem = { ...newItem, user: [...newItem?.user, user] };
					else
						newItem = {
							...newItem,
							user: newItem?.user?.filter(item => item !== user),
						};
					setPermit(EditData(permit, newItem));
				}}
			/>
		</span>
	);
};

export const MakeVirtual = ({ isOpen, back }) => {
	return (
		<>
			<ModalComponents
				isOpen={isOpen ? true : false}
				back={back}
				title="virtual accounts">
				<form>
					{isOpen?.wallet?.monnifyAccount ? (
						<div>
							{isOpen?.wallet?.monnifyAccount?.accounts?.map((it, i) => (
								<div
									key={i}
									className="my-3 d-flex align-items-center rounded10 bg-light p-3">
									<div className="d-flex me-2">
										<div
											className="p-3 d-flex rounded10 align-items-center justify-content-center"
											style={{
												background: `${colorArr[i % colorArr.length]}`,
											}}>
											<RiShieldStarFill
												size={24}
												color={`${
													colorArr[i % colorArr.length] === "#000000"
														? "#fff"
														: "#000"
												}`}
											/>
										</div>
									</div>
									<div>
										<h6 className="fw-bold text-muted">{it?.bankName}</h6>
										<h6 className="fw-bold force-d-flex">
											{it?.accountNumber || it?.bankAccount}{" "}
											<BiCopy
												size={20}
												className="ms-3 myCursor"
												onClick={() => {
													navigator.clipboard
														.writeText(it?.accountNumber || it?.bankAccount)
														.then(
															() => {
																toast.info("Copied", { autoClose: 2000 });
															},
															err => {
																toast.warn(`Could not copy: ${err}`, {
																	autoClose: 2000,
																});
															}
														);
												}}
											/>{" "}
										</h6>
									</div>
								</div>
							))}
						</div>
					) : null}
					{isOpen?.wallet?.budpayAccount ? (
						<>
							<h3 className="Lexend">Honour World Linked Account</h3>
							<div className="my-3 d-flex align-items-center rounded10 bg-light p-3">
								<div className="d-flex me-2">
									<div
										className="p-3 d-flex rounded10 align-items-center justify-content-center"
										style={{
											background: `${colorArr[colorArr.length - 1]}`,
										}}>
										<RiShieldStarFill
											size={24}
											color={`${
												colorArr[colorArr.length - 1] === "#000000"
													? "#fff"
													: "#000"
											}`}
										/>
									</div>
								</div>
								<div>
									<h6 className="fw-bold text-muted">
										{isOpen?.wallet?.budpayAccount?.provider?.bank_name ||
											isOpen?.wallet?.budpayAccount?.bank?.name}
									</h6>
									<h6 className="fw-bold force-d-flex">
										{isOpen?.wallet?.budpayAccount?.dedicated_account
											?.account_number ||
											isOpen?.wallet?.budpayAccount?.account_number}{" "}
										<BiCopy
											size={20}
											className="ms-3 myCursor"
											onClick={() => {
												navigator.clipboard
													.writeText(
														isOpen?.wallet?.budpayAccount?.dedicated_account
															?.account_number ||
															isOpen?.wallet?.budpayAccount?.account_number
													)
													.then(
														() => {
															toast.info("Copied", { autoClose: 2000 });
														},
														err => {
															toast.warn(`Could not copy: ${err}`, {
																autoClose: 2000,
															});
														}
													);
											}}
										/>{" "}
									</h6>
								</div>
							</div>
						</>
					) : null}
				</form>
			</ModalComponents>
		</>
	);
};