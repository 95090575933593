import React, { useContext, useEffect, useState } from "react";
import { Container } from "reactstrap";
// import { MainCalenderComponent } from "../../Components/Dashboard";
import { GlobalState } from "../../Data/Context";
import moment from "moment";
import { MainPaginate, MainRanger } from "../../Components/Transactions";
import { useNavigate, useParams } from "react-router-dom";
import { YearBox } from "./provider";
import ExportButtons from "../../Utils/Export Functions/ExportButtons";

const Transactions = () => {
  let { setStateName, stat, getProviderStat } = useContext(GlobalState),
    navigate = useNavigate(),
    { auth, permission } = useContext(GlobalState);

  useEffect(() => {
    if (auth?.user?.privilege !== "superadmin") {
      let findTitle = "viewgeneraltransactionstat";

      let findPermit = permission?.data?.find(
        (item) =>
          item?.slug === findTitle && item?.user?.includes(auth?.user?._id)
      );
      if (!findPermit) {
        navigate(-1);
      }
    }
  }, [auth?.user, permission?.data, navigate]);

  useEffect(() => {
    setStateName("General Transactions Stat");
    getProviderStat({ route: "/manage-transactions" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let [active, setActive] = useState(0),
    [daily, setDaily] = useState(null),
    [weekly, setWeekly] = useState(null),
    [year, setYear] = useState(""),
    [loading, setLoading] = useState(null),
    [monthly, setMonthly] = useState(null);

  useEffect(() => {
    setDaily(stat?.transactions?.daily);
    setWeekly(stat?.transactions?.weekly);
    setMonthly(stat?.transactions?.monthly);
  }, [stat?.transactions]);

  useEffect(() => {
    if (year) {
      let getr = async () => {
        setLoading(true);
        await getProviderStat({ route: "/manage-transactions", year });
        setLoading(false);
      };
      getr();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  let tabControl = ["Daily", "weekly", "monthly"];
  return (
    <div className="bg-white aboutScreen">
      <Container className="py-3 py-md-5">
        {/* <div className="row mx-0 d-none d-md-flex">
					<div className="col-md-8"></div>
					<MainCalenderComponent />
				</div> */}
        <div>
          <YearBox year={year} setYear={setYear} loading={loading} />
          <div className="btn-group w-100 py-3">
            {tabControl?.map((item, i) => (
              <button
                key={i}
                className={`btn py-3 text-capitalize fw-bold ${
                  active === i ? "border-bottom textColor" : ""
                } rounded-0`}
                onClick={() => setActive(i)}
              >
                {item}
              </button>
            ))}
          </div>
          {active === 2 ? (
            <MainTransactionsStatData
              state={monthly}
              factor="month"
              year={loading ? "" : year}
            />
          ) : active === 1 ? (
            <MainTransactionsStatData
              state={weekly}
              factor="week"
              year={loading ? "" : year}
            />
          ) : (
            <MainTransactionsStatData
              state={daily}
              factor="day"
              year={loading ? "" : year}
            />
          )}
        </div>
      </Container>{" "}
    </div>
  );
};

export default Transactions;

let MainTransactionsStatData = ({ state, factor, year }) => {
  let { numberWithCommas, nairaSign } = useContext(GlobalState),
    [total, setTotal] = useState(0);

  useEffect(() => {
    if (state) {
      let dataTotal = state?.reduce((ac, i) => (ac += Number(i?.dataTotal)), 0);
      let airtimeTotal = state?.reduce(
        (ac, i) => (ac += Number(i?.airtimeTotal)),
        0
      );
      let cablesTotal = state?.reduce(
        (ac, i) => (ac += Number(i?.cablesTotal)),
        0
      );
      let bizTotal = state?.reduce((ac, i) => (ac += Number(i?.bizTotal)), 0);
      let electricityTotal = state?.reduce(
        (ac, i) => (ac += Number(i?.electricityTotal)),
        0
      );
      let educationTotal = state?.reduce(
        (ac, i) => (ac += Number(i?.educationTotal)),
        0
      );
      setTotal(
        Number(dataTotal) +
          Number(airtimeTotal) +
          Number(cablesTotal) +
          Number(electricityTotal) +
          Number(educationTotal) +
          Number(bizTotal)
      );
    }
  }, [state]);

  let [range, setRange] = useState(10);
  const params = useParams();

  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + range;
  if (!state) return;

  const currentItems = state.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(state.length / range);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * range) % state.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <div className="tw-flex tw-justify-between tw-items-center">
        <div className="tw-flex-1">
          <MainRanger range={range} setRange={setRange} />
        </div>
        <ExportButtons
          tableId="manage-transactions-table"
          currentItems={currentItems}
          fileName={`${params?.page} ${params?.id ? `(${params?.id})` : ""}`}
        />
      </div>
      <div className="py-5">
        <div className="tw-relative tw-overflow-x-auto tw-overflow-y-visible tw-min-h-52">
          <table
            id="manage-transactions-table"
            className="tw-w-full tw-text-sm tw-text-left"
          >
            <thead className="tw-text-xs tw-text-gray-700 tw-captalize bland tw-px-1">
              <tr>
                <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-uppercase">
                  S/N
                </th>
                <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-uppercase">
                  Data
                </th>
                <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-uppercase">
                  Airtime
                </th>
                <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-uppercase">
                  Cables
                </th>
                <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-uppercase">
                  Electricity
                </th>
                <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-uppercase">
                  Biz
                </th>
                <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-uppercase">
                  Education
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems?.map((item, i) => (
                <tr
                  className={`bg-white tw-border-b tw-text-xs px-1 py-3 myCursor`}
                  key={i}
                >
                  <td className="tw-px-6 tw-py-6">
                    <div className="col text-capitalize textTrunc fontReduce3 textTrunc2 my-auto">
                      {factor === "day"
                        ? moment().dayOfYear(item?.[factor])?.format("Do MMM")
                        : factor === "week"
                        ? moment().week(item?.[factor])?.format("Do MMMM")
                        : moment()
                            .month(item?.[factor] - 1)
                            ?.format("MMMM")}
                      , {/* {factor} {item?.[factor]} */}
                      {year || moment().format("YYYY")}
                    </div>
                  </td>
                  <td className="tw-px-6 tw-py-6">
                    <div className="">
                      {nairaSign}{" "}
                      {numberWithCommas(Number(item?.dataTotal).toFixed(2))}
                      <span className="d-none d-md-inline ps-md-2 textTrunc">
                        ({numberWithCommas(item?.dataCount)} time
                        {item?.dataCount !== 1 ? "s" : ""})
                      </span>
                    </div>
                  </td>
                  <td className="tw-px-6 tw-py-6">
                    <div className="">
                      {nairaSign}{" "}
                      {numberWithCommas(Number(item?.airtimeTotal).toFixed(2))}
                      <span className="d-none d-md-inline ps-md-2 textTrunc">
                        ({numberWithCommas(item?.airtimeCount)} time
                        {item?.airtimeCount !== 1 ? "s" : ""})
                      </span>
                    </div>
                  </td>
                  <td className="tw-px-6 tw-py-6">
                    <div className="">
                      {nairaSign}{" "}
                      {numberWithCommas(Number(item?.cablesTotal).toFixed(2))}
                      <span className="d-none d-md-inline ps-md-2 textTrunc">
                        ({numberWithCommas(item?.cablesCount)} time
                        {item?.cablesCount !== 1 ? "s" : ""})
                      </span>
                    </div>
                  </td>
                  <td className="tw-px-6 tw-py-6">
                    <div className="">
                      {nairaSign}{" "}
                      {numberWithCommas(
                        Number(item?.electricityTotal).toFixed(2)
                      )}
                      <span className="d-none d-md-inline ps-md-2 textTrunc">
                        ({numberWithCommas(item?.electricityCount)} time
                        {item?.electricityCount !== 1 ? "s" : ""})
                      </span>
                    </div>
                  </td>
                  <td className="tw-px-6 tw-py-6">
                    <div className="">
                      {nairaSign}{" "}
                      {numberWithCommas(Number(item?.bizTotal).toFixed(2))}
                      <span className="d-none d-md-inline ps-md-2 textTrunc">
                        ({numberWithCommas(item?.bizCount)} time
                        {item?.bizCount !== 1 ? "s" : ""})
                      </span>
                    </div>
                  </td>
                  <td className="tw-px-6 tw-py-6">
                    <div className="">
                      {nairaSign}{" "}
                      {numberWithCommas(
                        Number(item?.educationTotal).toFixed(2)
                      )}
                      <span className="d-none d-md-inline ps-md-2 textTrunc">
                        ({numberWithCommas(item?.educationCount)} time
                        {item?.educationCount !== 1 ? "s" : ""})
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
              <tr className={`bland tw-border-b tw-text-xs px-1 py-3 myCursor`}>
                <td className="tw-px-6 tw-py-6"></td>
                <td className="tw-px-6 tw-py-6">
                  <div className="col textTrunc fontReduce fw-bold Lexend">
                    {nairaSign}{" "}
                    {numberWithCommas(
                      Number(
                        state?.reduce(
                          (ac, i) => (ac += Number(i?.dataTotal)),
                          0
                        )
                      ).toFixed(2)
                    )}
                    <span className="d-none d-md-inline ps-md-2 textTrunc">
                      (
                      {numberWithCommas(
                        state?.reduce(
                          (ac, i) => (ac += Number(i?.dataCount)),
                          0
                        )
                      )}{" "}
                      time
                      {state?.reduce(
                        (ac, i) => (ac += Number(i?.dataCount)),
                        0
                      ) !== 1
                        ? "s"
                        : ""}
                      )
                    </span>
                  </div>
                </td>
                <td className="tw-px-6 tw-py-6">
                  <div className="col textTrunc fontReduce fw-bold Lexend">
                    {nairaSign}{" "}
                    {numberWithCommas(
                      Number(
                        state?.reduce(
                          (ac, i) => (ac += Number(i?.airtimeTotal)),
                          0
                        )
                      ).toFixed(2)
                    )}
                    <span className="d-none d-md-inline ps-md-2 textTrunc">
                      (
                      {numberWithCommas(
                        state?.reduce(
                          (ac, i) => (ac += Number(i?.airtimeCount)),
                          0
                        )
                      )}{" "}
                      time
                      {state?.reduce(
                        (ac, i) => (ac += Number(i?.airtimeCount)),
                        0
                      ) !== 1
                        ? "s"
                        : ""}
                      )
                    </span>
                  </div>
                </td>
                <td className="tw-px-6 tw-py-6">
                  <div className="col textTrunc fontReduce fw-bold Lexend">
                    {nairaSign}{" "}
                    {numberWithCommas(
                      Number(
                        state?.reduce(
                          (ac, i) => (ac += Number(i?.cablesTotal)),
                          0
                        )
                      ).toFixed(2)
                    )}
                    <span className="d-none d-md-inline ps-md-2 textTrunc">
                      (
                      {numberWithCommas(
                        state?.reduce(
                          (ac, i) => (ac += Number(i?.cablesCount)),
                          0
                        )
                      )}{" "}
                      time
                      {state?.reduce(
                        (ac, i) => (ac += Number(i?.cablesCount)),
                        0
                      ) !== 1
                        ? "s"
                        : ""}
                      )
                    </span>
                  </div>
                </td>
                <td className="tw-px-6 tw-py-6">
                  <div className="col textTrunc fontReduce fw-bold Lexend">
                    {nairaSign}{" "}
                    {numberWithCommas(
                      Number(
                        state?.reduce(
                          (ac, i) => (ac += Number(i?.electricityTotal)),
                          0
                        )
                      ).toFixed(2)
                    )}
                    <span className="d-none d-md-inline ps-md-2 textTrunc">
                      (
                      {numberWithCommas(
                        state?.reduce(
                          (ac, i) => (ac += Number(i?.electricityCount)),
                          0
                        )
                      )}{" "}
                      time
                      {state?.reduce(
                        (ac, i) => (ac += Number(i?.electricityCount)),
                        0
                      ) !== 1
                        ? "s"
                        : ""}
                      )
                    </span>
                  </div>
                </td>
                <td className="tw-px-6 tw-py-6">
                  <div className="col textTrunc fontReduce fw-bold Lexend">
                    {nairaSign}{" "}
                    {numberWithCommas(
                      Number(
                        state?.reduce((ac, i) => (ac += Number(i?.bizTotal)), 0)
                      ).toFixed(2)
                    )}
                    <span className="d-none d-md-inline ps-md-2 textTrunc">
                      (
                      {numberWithCommas(
                        state?.reduce((ac, i) => (ac += Number(i?.bizCount)), 0)
                      )}{" "}
                      time
                      {state?.reduce(
                        (ac, i) => (ac += Number(i?.bizCount)),
                        0
                      ) !== 1
                        ? "s"
                        : ""}
                      )
                    </span>
                  </div>
                </td>
                <td className="tw-px-6 tw-py-6">
                  <div className="col textTrunc fontReduce fw-bold Lexend">
                    {nairaSign}{" "}
                    {numberWithCommas(
                      Number(
                        state?.reduce(
                          (ac, i) => (ac += Number(i?.educationTotal)),
                          0
                        )
                      ).toFixed(2)
                    )}
                    <span className="d-none d-md-inline ps-md-2 textTrunc">
                      (
                      {numberWithCommas(
                        state?.reduce(
                          (ac, i) => (ac += Number(i?.educationCount)),
                          0
                        )
                      )}{" "}
                      time
                      {state?.reduce(
                        (ac, i) => (ac += Number(i?.educationCount)),
                        0
                      ) !== 1
                        ? "s"
                        : ""}
                      )
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <MainPaginate
            handlePageClick={handlePageClick}
            pageCount={pageCount}
          />
          <div className="py-3">
            <h2 className="Lexend">
              Total: {nairaSign} {numberWithCommas(Number(total).toFixed(2))}
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};
