import React, { useContext, useState, useEffect } from "react";
import { Container } from "reactstrap";
import { GlobalState } from "../../../Data/Context";
import LoadMore, { BottomTab } from "../../../Components/LoadMore";
import { MainPaginate, MainRanger } from "../../../Components/Transactions";
import { EmptyComponent } from "../../../Utils";
import avatar from "../../../Assets/avatar3.png";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { ExportButtons2 } from "../../../Utils/Export Functions/ExportButtons";

const WalletTracking = () => {
  let [search, setSearch] = useState(""),
    {
      trackingWallet,
      getReload,
      getUserWalletTracking,
      nairaSign,
      numberWithCommas,
      setStateName,
      auth,
      permission,
    } = useContext(GlobalState),
    [state, setState] = useState(null),
    [loading, setLoading] = useState(false),
    navigate = useNavigate();

  useEffect(() => {
    if (auth?.user?.privilege !== "superadmin") {
      let findTitle = "viewmanagewallettracking";

      let findPermit = permission?.data?.find(
        (item) =>
          item?.slug === findTitle && item?.user?.includes(auth?.user?._id)
      );
      if (!findPermit) {
        navigate(-1);
      }
    }
  }, [auth?.user, permission?.data, navigate]);

  useEffect(() => {
    setStateName("Manage Wallet Tracking");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await getUserWalletTracking(null, { search });
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    getUserWalletTracking();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let handleLoadMore = async () => {
    setLoading(true);

    await getUserWalletTracking({
      limit: search
        ? Number(
            trackingWallet?.paginate?.nextPage * trackingWallet?.paginate?.limit
          )
        : Number(
            trackingWallet?.paginate?.nextPage * trackingWallet?.paginate?.limit
          ),
      search,
    });
    setLoading(false);
  };

  useEffect(() => {
    let newState;
    if (trackingWallet.isFound) {
      newState = trackingWallet.mainSearch;
    } else newState = trackingWallet?.data;
    newState = newState?.sort(
      (a, b) => b?.wallet?.available - a?.wallet?.available
    );
    setState(newState);
  }, [
    trackingWallet?.data,
    trackingWallet?.isFound,
    trackingWallet?.mainSearch,
  ]);

  let [range, setRange] = useState(10),
    [page, setPage] = useState(1);

  const params = useParams();

  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + range;

  if (!state) return;

  const currentItems = state.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(state.length / range);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * range) % state.length;
    setItemOffset(newOffset);
    setPage(1 + event?.selected);
  };

  const headers = [
    { title: "NAME", field: "userName" },
    { title: "PRIVILEGE", field: "privilege" },
    { title: "NUMBER", field: "number" },
    { title: "EMAIL", field: "email" },
    { title: "BALANCE", field: "balance" },
    { title: "WALLET ID", field: "wallet_id" },
    { title: "DATE & TIME", field: "createdAt" },
  ];

  const data = currentItems.map((item, index) => ({
    userName:
      item?.firstName || item?.lastName
        ? `${item?.firstName} ${item?.lastName}`
        : "",
    privilege: item?.privilege ? `${item?.privilege}` : "",
    number: item?.telephone ? `${item?.telephone}` : "",
    email: item?.email ? `${item?.email}` : "",
    balance: item?.wallet?.available
      ? `₦ ${
          item?.wallet?.available
            ? numberWithCommas(Number(item?.wallet?.available).toFixed(2))
            : 0
        }`
      : "",
    wallet_id: item?.wallet?.wallet_id ? `${item?.wallet?.wallet_id}` : "",
    createdAt: moment(item?.createdAt).format("DD/MM/YYYY hh:mm A"),
  }));

  return (
    <div className="py-4 bg-white aboutScreen">
      <Container className="py-5">
        <div className="w-50 w50 mb-3">
          <input
            type="search"
            name="search"
            id="Search"
            className="form-control w-100 py-3 borderColor2"
            placeholder="Type here to search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="tw-flex tw-justify-between tw-items-center">
          <div className="tw-flex-1">
            <MainRanger range={range} setRange={setRange} />
          </div>
          <ExportButtons2
            headers={headers}
            data={data}
            fileName={`${params?.page} ${params?.id && `(${params?.id})`}`}
          />
        </div>
        ;
        <div className="bland row mx-0 py-3 text-capitalize">
          <div className="col textTrunc text-uppercase fontReduce fw-bold Lexend">
            Name
          </div>
          <div className="col textTrunc text-uppercase fontReduce fw-bold Lexend">
            Privilege
          </div>
          <div className="col textTrunc text-uppercase fontReduce fw-bold Lexend">
            number
          </div>
          <div className="col textTrunc text-uppercase fontReduce fw-bold Lexend d-none d-md-flex">
            Email
          </div>
          <div className="col textTrunc text-uppercase fontReduce fw-bold Lexend">
            balance
          </div>
          <div className="col textTrunc text-uppercase fontReduce fw-bold Lexend d-none d-md-flex">
            Wallet ID
          </div>
          <div className="col textTrunc text-uppercase fontReduce fw-bold Lexend d-none d-md-flex">
            date&time
          </div>
        </div>
        <div className="bland2 row mx-0">
          {currentItems?.length === 0 ? (
            <EmptyComponent subtitle={`Wallet Tracking list is empty`} />
          ) : (
            currentItems?.map((item, index) => (
              <div key={index} className="row mx-0 p-3 border-bottom">
                <div className="col fontReduce2 textTrunc my-auto">
                  <div className="d-flex align-items-center w-100">
                    <img
                      src={item?.avatar ? item?.avatar?.url : avatar}
                      alt="User"
                      className="img-fluid rounded-circle d-none d-md-flex imgFluid"
                      style={{
                        height: "3rem",
                        width: "3rem",
                      }}
                    />
                    <span className="fontInherit my-0 ps-0 ps-md-1 textTrunc textTrunc2 fontReduce w-100">
                      {item?.firstName} {item?.lastName}
                    </span>
                  </div>
                </div>
                <div className="col fontReduce textTrunc my-auto text-capitalize">
                  {item?.privilege}
                </div>
                <div className="col fontReduce textTrunc my-auto">
                  {item?.telephone}
                </div>
                <div className="col fontReduce2 textTrunc my-auto d-none d-md-flex">
                  {item?.email}
                </div>
                <div className="col fontReduce2 textTrunc my-auto d-flex w-100">
                  <span className="fontInherit d-none d-md-flex me-md-1">
                    {nairaSign}
                  </span>{" "}
                  <span>
                    {item?.wallet?.available
                      ? numberWithCommas(
                          Number(item?.wallet?.available).toFixed(2)
                        )
                      : 0}
                  </span>
                </div>
                <div className="col fontReduce2 textTrunc my-auto d-none d-md-flex">
                  {item?.wallet?.wallet_id}
                </div>
                <div className="col fontReduce2 textTrunc my-auto d-none d-md-flex">
                  {moment(item?.createdAt).format("DD/MM/YYYY hh:mm A")}
                </div>
              </div>
            ))
          )}
        </div>
        <MainPaginate handlePageClick={handlePageClick} pageCount={pageCount} />
        <BottomTab
          state={state}
          paginate={
            trackingWallet?.isFound
              ? trackingWallet?.search_paginate
              : trackingWallet?.paginate
          }
        />
        {page === pageCount && (
          <LoadMore
            next={
              trackingWallet?.isFound
                ? trackingWallet?.search_paginate?.next
                : trackingWallet?.paginate?.next
            }
            handleLoadMore={handleLoadMore}
            loading={loading}
          />
        )}
      </Container>
    </div>
  );
};

export default WalletTracking;
