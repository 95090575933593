import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { BiCog } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { GlobalState } from "../../Data/Context";
import { AvatarImg, ModalComponents } from "../DefaultHeader";
import { Buttons, EmptyComponent } from "../../Utils";
import { AddNotification } from "../Notification";
import LoadMore, { BottomTab } from "../LoadMore";
import avatar from "../../Assets/avatar3.png";
import icon1 from "../../Assets/Analythics.png";
import icon2 from "../../Assets/Сoding.png";
import icon3 from "../../Assets/Money.png";
import { ClipLoader } from "react-spinners";
import { HiThumbDown, HiThumbUp } from "react-icons/hi";
import { MakeBonus } from "../../Pages/wallets/give-bonus";
import { MainPaginate, MainRanger } from "../Transactions";
import { MakeWallet } from "../../Pages/wallets/manual";
// import { NetworkList } from "../Products/airtime";
import { CgBalanceComponent } from "../Products/CgWallet";
import { DataNetworkList } from "../Products/airtime";
import { FindDetails } from "../../Pages/wallets/find-transactions";
import { downloadExcel } from "react-export-table-to-excel";
import ExportButtons from "../../Utils/Export Functions/ExportButtons";

const Users = () => {
  return <div>Users</div>;
};

export default Users;

export const ThreeBoxBar = ({ list, setSubActive }) => {
  let navigate = useNavigate();
  return (
    <>
      <div className="lg:tw-w-3/4 tw-mx-auto tw-mb-16  tw-py-6 tw-gap-8 tw-grid lg:tw-grid-cols-3">
        {list?.map((item, index) => (
          <div
            style={{
              background: item?.color || "white",
            }}
            key={index}
            onClick={
              setSubActive
                ? () => {
                    setSubActive(index);
                  }
                : item?.link
                ? () => {
                    navigate(item?.link);
                  }
                : () => {}
            }
            className="tw-flex tw-items-center tw-cursor-pointer hover:tw-shadow-inner tw-p-4 tw-justify-between tw-h-32 tw-shadow-xl"
          >
            <img src={item?.icon} alt="" className="tw-h-full" />
            <div className="">
              <h1 className="tw-text-3xl tw-font-bold tw-text-white tw-capitalize tw-text-right">
                {item.number}
              </h1>
              <p className="tw-text-xl tw-font-bold tw-text-white tw-text-right tw-capitalize">
                {item.name}
              </p>
            </div>
          </div>
        ))}
      </div>
      {/* <div className="tw-grid tw-grid-cols-3 tw-w-5/6 tw-mx-auto tw-my-10 tw-gap-8 tw-mt-8">
				{list?.map((item, index) => (
					<div
						onClick={
							setSubActive
								? () => {
										setSubActive(index);
								  }
								: () => {}
						}
						className="tw-py-4 tw-px-6 tw-bg-white tw-shadow-xl tw-rounded-xl"
						key={index}>
						<div
							className="tw-flex tw-items-center tw-justify-between"
							onClick={() => (item?.link ? navigate(item?.link) : {})}>
							<div className="">
								<img src={item?.icon} className="tw-h-16" alt="Icon" />
							</div>
							<div className="tw-text-right">
								<p className="tw-text-xl tw-text-[#1b1b1b] tw-font-medium">
									{item?.number}
								</p>
								<h6 className="tw-text-base tw-text-[#1b1b1b] tw-font-medium tw-capitalize">
									{item?.name}
								</h6>
							</div>
						</div>
					</div>
				))}
			</div> */}
    </>
  );
};

export const UserListOne = () => {
  const { loadAllUser, getReload, allUsers } = useContext(GlobalState);
  let [data, setData] = useState(null),
    [isOpen, setIsOpen] = useState(false),
    [loading, setLoading] = useState(false),
    [mainUser, setMainUser] = useState(null),
    toggle = () => {
      if (isOpen) {
        setMainUser(null);
      }
      setIsOpen(!isOpen);
    },
    { page } = useParams(),
    [isUser, setIsUser] = useState(null),
    toggleNotify = () => {
      setIsUser(null);
    },
    [isDisable, setIsDisable] = useState(null),
    toggleDisable = () => {
      setIsDisable(null);
    },
    [search, setSearch] = useState("");

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await loadAllUser({
          search,
        });
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    getReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let handleLoadMore = async () => {
    setLoading(true);
    if (search)
      await loadAllUser({
        page: Number(allUsers?.search_paginate?.nextPage),
        // limit: Number(
        //   allUsers?.search_paginate?.nextPage * allUsers?.search_paginate?.limit
        // ),
        search,
      });
    else
      await loadAllUser({
        page: Number(allUsers?.paginate?.nextPage),
        // limit: Number(allUsers?.paginate?.nextPage * allUsers?.paginate?.limit),
      });
    setLoading(false);
  };

  useEffect(() => {
    if (allUsers?.isFound)
      setData(
        page === "dashboard"
          ? allUsers?.mainSearch?.slice(0, 10)
          : allUsers?.mainSearch
      );
    else
      setData(
        page === "dashboard" ? allUsers?.users?.slice(0, 10) : allUsers?.users
      );
  }, [allUsers?.users, page, allUsers?.isFound, allUsers?.mainSearch]);

  if (!data) return;

  let actionArr = [
    {
      name: "Profile",
      type: "link",
      link: `/users/profile`,
    },
    {
      name: "wallet history",
      type: "link",
      link: `/users/wallet`,
    },
    {
      name: "purchase history",
      type: "link",
      link: `/users/purchase`,
    },
    {
      name: "notification",
      type: "button",
      link: `notification`,
    },
    { name: "disable user", type: "button", link: `disable` },
  ];

  return (
    <div className="pb-5 my-5">
      {page !== "dashboard" && (
        <>
          <div className="w-50 w50 mb-3">
            <input
              type="search"
              name="search"
              id="Search"
              className="form-control w-100 py-3 borderColor2"
              placeholder="Type here to search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </>
      )}
      <NewUserPaginate
        state={data}
        mainUser={mainUser}
        actionArr={actionArr}
        setMainUser={setMainUser}
        toggle={toggle}
        isOpen={isOpen}
        setIsUser={setIsUser}
        setIsDisable={setIsDisable}
      />
      {page !== "dashboard" && (
        <>
          <BottomTab
            state={data}
            paginate={
              allUsers?.isFound ? allUsers?.search_paginate : allUsers?.paginate
            }
          />
          <LoadMore
            next={
              allUsers?.isFound
                ? allUsers?.search_paginate?.next
                : allUsers?.paginate?.next
            }
            handleLoadMore={handleLoadMore}
            loading={loading}
          />
        </>
      )}
      <AddNotification isOpen={isUser} back={toggleNotify} />
      <DisableUser
        isOpen={isDisable}
        back={toggleDisable}
        toggleAll={() => {
          setMainUser(null);
          setIsOpen(false);
        }}
      />
    </div>
  );
};

export const DisableUser = ({ isOpen, back, toggleAll }) => {
  const { manageUserActiveness, allUsers } = useContext(GlobalState);
  let [loading, setLoading] = useState(false),
    [submit, setSubmit] = useState(false),
    handleSubmit = async (e) => {
      e?.preventDefault();
      setLoading(true);
      await manageUserActiveness(
        isOpen._id,
        isOpen?.status ? "suspend" : "activate"
      );
      setSubmit(true);
      setLoading(false);
    };

  useEffect(() => {
    if (submit && allUsers?.isUpdated) {
      setSubmit(false);
      toggleAll();
      back();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit, allUsers?.isUpdated]);
  // console.log({ isOpen });
  return (
    <>
      <ModalComponents
        title={`${isOpen?.status ? "Suspend" : "Activate"} User`}
        back={back}
        isOpen={isOpen}
      >
        <div className="downH2 d-flex">
          <div className="my-auto w-100">
            <form
              className="d-flex flex-column justify-content-center align-items-center h-100 w-100"
              onSubmit={handleSubmit}
            >
              <p className="text2p">
                Do you want to {isOpen?.status ? "Suspend" : "Activate"} this
                user?
              </p>
              <div className="btn-group mx-auto w-50">
                <Buttons
                  loading={loading}
                  onClick={handleSubmit}
                  width="w-50"
                  css="btn-success2 text-capitalize py-3 w-50"
                  title={"yes"}
                />
                <Buttons
                  onClick={back}
                  width="w-50"
                  type="button"
                  css="btn-danger text-capitalize py-3 w-50"
                  title={"no"}
                />
              </div>
            </form>
          </div>
        </div>
      </ModalComponents>
    </>
  );
};

export const DeleteUser = ({ isOpen, back, toggleAll }) => {
  const { manageUserPrivilege, allUsers } = useContext(GlobalState);
  let [loading, setLoading] = useState(false),
    [submit, setSubmit] = useState(false),
    handleSubmit = async (e) => {
      e?.preventDefault();
      setLoading(true);
      await manageUserPrivilege(
        isOpen,
        isOpen._id,
        isOpen?.privilege,
        "delete"
      );
      setSubmit(true);
      setLoading(false);
    };

  useEffect(() => {
    if (submit && allUsers?.isDeleted) {
      setSubmit(false);
      toggleAll();
      back();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit, allUsers?.isDeleted]);
  // console.log({ isOpen });
  return (
    <>
      <ModalComponents title={`Delete User`} back={back} isOpen={isOpen}>
        <div className="downH2 d-flex">
          <div className="my-auto w-100">
            <form
              className="d-flex flex-column justify-content-center align-items-center h-100 w-100"
              onSubmit={handleSubmit}
            >
              <p className="text2p">Do you want to delete this user?</p>
              <div className="btn-group mx-auto w-50">
                <Buttons
                  loading={loading}
                  onClick={handleSubmit}
                  width="w-50"
                  css="btn-success2 text-capitalize py-3 w-50"
                  title={"yes"}
                />
                <Buttons
                  onClick={back}
                  width="w-50"
                  type="button"
                  css="btn-danger text-capitalize py-3 w-50"
                  title={"no"}
                />
              </div>
            </form>
          </div>
        </div>
      </ModalComponents>
    </>
  );
};

export const AdminDetails = ({ privilege, noFetch }) => {
  let {
    getReload,
    getUserPrivileges,
    superadmin,
    manager,
    accountant,
    developer,
    marketer,
    topuser,
    reseller,
    user,
    auth,
    manageCGWallets,
    permission,
    getAllUserTransactionAmount,
    agent,
  } = useContext(GlobalState);

  let [data, setData] = useState(null),
    [isOpen, setIsOpen] = useState(false),
    [loading, setLoading] = useState(false),
    [load, setLoad] = useState({ isLoad: false, loadData: "" }),
    [mainUser, setMainUser] = useState(null),
    toggle = () => {
      if (isOpen) {
        setMainUser(null);
      }
      setIsOpen(!isOpen);
    },
    navigate = useNavigate(),
    { page } = useParams(),
    [isUser, setIsUser] = useState(null),
    [isCgWallet, setIsCgWallet] = useState(null),
    toggleNotify = () => {
      setIsUser(null);
    },
    [isDisable, setIsDisable] = useState(null),
    toggleDisable = () => {
      setIsDisable(null);
    },
    [isSwitch, setIsSwitch] = useState(null),
    toggleSwitch = () => {
      setIsSwitch(null);
    },
    [search, setSearch] = useState(""),
    [columnUse, setColumnUse] = useState(null),
    [stateFilter, setStateFilter] = useState(null),
    [loadingFilter, setLoadingFilter] = useState(false),
    [submit, setSubmit] = useState(false),
    [isFilter, setIsFilter] = useState(null),
    textChange =
      (name) =>
      ({ target: { value } }) => {
        setStateFilter({ ...stateFilter, [name]: value });
      },
    handleFind = async () => {
      setLoadingFilter(true);
      await getUserPrivileges(privilege, {
        search,
        filter: `${
          stateFilter?.statusText
            ? `&statusText=${stateFilter?.statusText}`
            : ""
        }${
          stateFilter?.activeStatus
            ? `&activeStatus=${stateFilter?.activeStatus}`
            : ""
        }${
          stateFilter?.startDate ? `&startDate=${stateFilter?.startDate}` : ""
        }${stateFilter?.endDate ? `&endDate=${stateFilter?.endDate}` : ""}${
          stateFilter?.available ? `&available=${stateFilter?.available}` : ""
        }${
          stateFilter?.ninNumber ? `&ninNumber=${stateFilter?.ninNumber}` : ""
        }${
          stateFilter?.bvnNumber ? `&bvnNumber=${stateFilter?.bvnNumber}` : ""
        }`,
      });
      setLoadingFilter(false);
      setSubmit(true);
    };

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await getUserPrivileges(privilege, {
          search,
          filter: `${
            stateFilter?.statusText
              ? `&statusText=${stateFilter?.statusText}`
              : ""
          }${
            stateFilter?.activeStatus
              ? `&activeStatus=${stateFilter?.activeStatus}`
              : ""
          }${
            stateFilter?.startDate ? `&startDate=${stateFilter?.startDate}` : ""
          }${stateFilter?.endDate ? `&endDate=${stateFilter?.endDate}` : ""}
					${stateFilter?.available ? `&available=${stateFilter?.available}` : ""}${
            stateFilter?.ninNumber ? `&ninNumber=${stateFilter?.ninNumber}` : ""
          }${
            stateFilter?.bvnNumber ? `&bvnNumber=${stateFilter?.bvnNumber}` : ""
          }`,
        });
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (submit && columnUse?.isFound) {
      setSubmit(false);
      setIsFilter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit, columnUse?.isFound]);

  useEffect(() => {
    if (!noFetch) {
      getUserPrivileges(privilege, { skipper: "skipper" });
      getAllUserTransactionAmount(privilege, "", "month");
      manageCGWallets("get");
      getReload();
    }
    setStateFilter(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privilege]);

  useEffect(() => {
    if (privilege === "superadmin") setColumnUse(superadmin);
    if (privilege === "manager") setColumnUse(manager);
    if (privilege === "marketer") setColumnUse(marketer);
    if (privilege === "accountant") setColumnUse(accountant);
    if (privilege === "developer") setColumnUse(developer);
    if (privilege === "topuser") setColumnUse(topuser);
    if (privilege === "reseller") setColumnUse(reseller);
    if (privilege === "agent") setColumnUse(agent);
    if (privilege === "user") setColumnUse(user);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    privilege,
    superadmin,
    manager,
    marketer,
    accountant,
    developer,
    topuser,
    reseller,
    user,
    agent,
  ]);

  useEffect(() => {
    if (auth?.user?.privilege !== "superadmin") {
      let findTitle = "";
      if (privilege === "user") {
        findTitle = "viewuserlist";
      } else if (privilege === "reseller") {
        findTitle = "viewresellerlist";
      } else if (privilege === "agent") {
        findTitle = "viewagentlist";
      } else if (privilege === "topuser") {
        findTitle = "viewtopuserlist";
      } else if (privilege === "manager") {
        findTitle = "viewmanagerlist";
      } else if (privilege === "marketer") {
        findTitle = "viewmarketerlist";
      } else if (privilege === "developer") {
        findTitle = "viewdeveloperlist";
      } else if (privilege === "accountant") {
        findTitle = "viewaccountantlist";
      }

      let findPermit = permission?.data?.find(
        (item) =>
          item?.slug === findTitle && item?.user?.includes(auth?.user?._id)
      );
      if (!findPermit) {
        navigate(-1);
      }
    }
  }, [auth?.user, permission?.data, privilege, navigate]);

  let [isBonus, setIsBonus] = useState(false),
    [bonus, setBonus] = useState(false),
    toggleBonus = () => {
      setIsBonus("");
      setBonus("");
    };

  let [isCredit, setIsCredit] = useState(false),
    [isCommission, setIsCommission] = useState(null),
    toggleCredit = () => {
      setIsCredit("");
      setIsCommission("");
    };

  let [isDebit, setIsDebit] = useState(false),
    toggleDebit = () => {
      setIsDebit("");
      setIsCommission("");
    };
  let [debit, setDebit] = useState(false);
  let [isDeleted, setIsDeleted] = useState(false),
    toggleDeleted = () => {
      setIsDeleted("");
    };

  let handleLoadMore = async () => {
    setLoading(true);
    if (search || stateFilter?.statusText || stateFilter?.activeStatus)
      await getUserPrivileges(privilege, {
        page: Number(columnUse?.search_paginate?.nextPage),
        // limit: Number(
        //   columnUse?.search_paginate?.nextPage *
        //     columnUse?.search_paginate?.limit
        // ),
        search,
        filter: `${
          stateFilter?.statusText
            ? `&statusText=${stateFilter?.statusText}`
            : ""
        }${
          stateFilter?.activeStatus
            ? `&activeStatus=${stateFilter?.activeStatus}`
            : ""
        }${
          stateFilter?.startDate ? `&startDate=${stateFilter?.startDate}` : ""
        }${stateFilter?.endDate ? `&endDate=${stateFilter?.endDate}` : ""}${
          stateFilter?.available ? `&available=${stateFilter?.available}` : ""
        }${
          stateFilter?.ninNumber ? `&ninNumber=${stateFilter?.ninNumber}` : ""
        }${
          stateFilter?.bvnNumber ? `&bvnNumber=${stateFilter?.bvnNumber}` : ""
        }`,
      });
    else
      await getUserPrivileges(privilege, {
        page: Number(columnUse?.paginate?.nextPage),
        // limit: Number(
        //   columnUse?.paginate?.nextPage * columnUse?.paginate?.limit
        // ),
      });
    setLoading(false);
  };

  useEffect(() => {
    if (columnUse?.isFound)
      setData(
        page === "dashboard"
          ? columnUse?.mainSearch?.slice(0, 10)
          : columnUse?.mainSearch
      );
    else
      setData(
        page === "dashboard" ? columnUse?.data?.slice(0, 10) : columnUse?.data
      );
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    columnUse?.data,
    page,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    columnUse?.isFound,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    columnUse?.mainSearch,
    privilege,
    manager?.isAdded,
    developer?.isAdded,
    superadmin?.isAdded,
    marketer?.isAdded,
    accountant?.isAdded,
    user?.isAdded,
    reseller?.isAdded,
    agent?.isAdded,
    topuser?.isAdded,
    manager?.isUpdated,
    developer?.isUpdated,
    superadmin?.isUpdated,
    marketer?.isUpdated,
    accountant?.isUpdated,
    user?.isUpdated,
    reseller?.isUpdated,
    agent?.isUpdated,
    topuser?.isUpdated,
  ]);
  let [range, setRange] = useState(10),
    [down, setDown] = useState(false),
    [dataset, setDataset] = useState([]);

  useEffect(() => {
    if (columnUse?.all?.length > 0 && privilege) {
      let userData = [];
      for (let i = 0; i < columnUse?.all?.length; i++) {
        if (columnUse?.all?.[i]?.privilege === privilege)
          userData?.push({
            index: i + 1,
            firstName: columnUse?.all?.[i]?.firstName,
            lastName: columnUse?.all?.[i]?.lastName,
            email: columnUse?.all?.[i]?.email,
            telephone: columnUse?.all?.[i]?.telephone,
            wallet_id: columnUse?.all?.[i]?.wallet?.wallet_id,
            accountUsageStatus: columnUse?.all?.[i]?.activeStatus,
            accountUsageStatusDate:
              columnUse?.all?.[i]?.activeStatus === "active"
                ? ""
                : moment(columnUse?.all?.[i]?.activeDate).isValid()
                ? moment(columnUse?.all?.[i]?.activeDate).format(
                    "Do MMMM, YYYY. hh:mm A"
                  )
                : "",
            createdAt: moment(columnUse?.all?.[i]?.createdAt).format(
              "Do MMMM, YYYY. hh:mm A"
            ),
          });
      }
      setDataset(userData);
    }
  }, [columnUse?.all, privilege]);

  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + range;

  if (!data) return;

  const currentItems = data.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(data.length / range);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * range) % data.length;
    setItemOffset(newOffset);
  };

  let actionArr = [
    {
      name: "Profile",
      type: "link",
      link: `/users/profile`,
    },
    {
      name: "notification",
      type: "button",
      link: `notification`,
    },
    {
      name: "wallet history",
      type: "link",
      link: `/users/wallet`,
    },
    {
      name: "purchase history",
      type: "link",
      link: `/users/purchase`,
    },
    // { name: "disable user", type: "button", link: `disable` },
    { name: "credit user", type: "button", link: `credit` },
    { name: "debit user", type: "button", link: `debit` },
    {
      name: "credit commission",
      type: "button",
      link: `credit`,
      title: "commission",
    },
    {
      name: "debit commission",
      type: "button",
      link: `debit`,
      title: "commission",
    },
    {
      name: "give bonus",
      type: "button",
      link: `bonus`,
    },
    {
      name: "Debit bonus",
      type: "button",
      link: `bonusdebit`,
    },
    {
      name: "Credit CG Wallet",
      type: "button",
      link: `cgwallet`,
    },
    {
      name: "Debit CG Wallet",
      type: "button",
      link: `cgwalletdebit`,
    },
    { name: "switch privilege", type: "button", link: `switch` },
    { name: "delete user", type: "button", link: `delete` },
  ];

  let handleDownloadExcel = () => {
    setDown(true);
    downloadExcel({
      fileName: `${
        process.env.REACT_APP_NAME
      } ${privilege?.toUpperCase()} Data ${moment().format("DDMMYYYYHHmmA")}`,
      sheet: `${privilege?.toUpperCase()} Data`,
      tablePayload: {
        header: [
          "S/N",
          "Firstname",
          "Lastname",
          "Email",
          "Telephone",
          "Wallet ID",
          "Account Usage Status",
          "Account Usage Since",
          "Registered on",
        ],
        //   // accept two different data structures
        body: dataset,
      },
    });
    setDown(false);
  };

  // console.log({ isCredit, isDebit });
  return (
    <>
      <div className="py-5">
        {page !== "dashboard" && (
          <>
            <div className="w-50 w50 mb-3">
              <input
                type="search"
                name="search"
                id="Search"
                className="form-control w-100 py-3 borderColor2"
                placeholder="Type here to search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="d-flex justify-content-between align-items-center w-100">
              <Buttons
                title={"filter"}
                css="btn-primary1 text-capitalize py-3 px-4 px-lg-5 mb-3"
                width={"w-25 w25"}
                onClick={() => setIsFilter(true)}
                style={{ borderRadius: "30px" }}
              />
              {columnUse?.all?.filter((item) => item?.privilege === privilege)
                ?.length > 0 && (
                <>
                  <div className="d-flex justify-content-end align-items-center w-100">
                    <Buttons
                      loading={down}
                      onClick={handleDownloadExcel}
                      title="download excel"
                      css="btn-primary1 text-capitalize py-2 py-md-3 px-3 pm-md-5"
                      width="btn"
                    />
                  </div>
                </>
              )}
            </div>
            <MainRanger range={range} setRange={setRange} />
          </>
        )}
        {/* <div className="bland row mx-0 py-3 text-capitalize">
					<div className="col textTrunc text-uppercase fontReduce fw-bold Lexend">
						Name
					</div>
					<div className="col textTrunc text-uppercase fontReduce fw-bold Lexend">
						number
					</div>
					<div className="col textTrunc text-uppercase fontReduce fw-bold Lexend d-none d-md-flex">
						Email
					</div>
					<div className="col textTrunc text-uppercase fontReduce fw-bold Lexend">
						balance
					</div>
					<div className="col textTrunc text-uppercase fontReduce fw-bold Lexend d-none d-md-flex">
						Wallet ID
					</div>
					<div className="col textTrunc text-uppercase fontReduce fw-bold Lexend d-none d-md-flex">
						date&time
					</div>
					<div className="col textTrunc text-uppercase fontReduce fw-bold Lexend text-center">
						Action
					</div>
				</div>
				<div className="bland2 row mx-0">
					{currentItems?.length === 0 ? (
						<EmptyComponent subtitle={`${privilege}' list is empty`} />
					) : (
						currentItems?.map((item, index) => (
							<div key={index} className="row mx-0 py-3 border-bottom">
								<div className="col fontReduce2 textTrunc my-auto">
									<div className="d-flex align-items-center w-100">
										
										<AvatarImg user={item} />

										<span className="fontInherit my-0 ps-0 ps-md-1 textTrunc textTrunc2 fontReduce w-100">
											{item?.firstName} {item?.lastName}
										</span>
									</div>
								</div>
								<div className="col fontReduce textTrunc my-auto">
									{item?.telephone}
								</div>
								<div className="col fontReduce2 textTrunc my-auto d-none d-md-flex">
									{item?.email}
								</div>
								<div className="col fontReduce2 textTrunc my-auto d-flex w-100">
									<span className="fontInherit d-none d-md-flex me-md-1">
										{nairaSign}
									</span>{" "}
									<span>
										{item?.wallet?.available
											? numberWithCommas(
													Number(item?.wallet?.available).toFixed(2)
											  )
											: 0}
									</span>
								</div>
								<div className="col fontReduce2 textTrunc my-auto d-none d-md-flex">
									{item?.wallet?.wallet_id}
								</div>
								<div className="col fontReduce2 textTrunc my-auto d-none d-md-flex">
									{moment(item?.createdAt).format("DD/MM/YYYY hh:mm A")}
								</div>
								<div className="col fontReduce2 textTrunc my-auto myCursor text-center d-flex align-items-center justify-content-end">
									{auth?.user?._id === item?._id ? null : (
										<>
											<span
												title={`${item?.status ? "Suspend" : "Activate"} ${
													item?.privilege
												}`}
												onClick={async () => {
													setLoad({ isLoad: true, loadData: item?._id });
													await manageUserActiveness(
														item._id,
														item?.status ? "suspend" : "activate"
													);
													setLoad({ isLoad: false, loadData: "" });
												}}
												className="text-capitalize p-1 p-md-2 fontReduce2">
												{load?.isLoad && load?.loadData === item?._id ? (
													<ClipLoader color="black" size={16} />
												) : item?.status ? (
													<HiThumbDown className="iconDash" color="green" />
												) : (
													<HiThumbUp className="iconDash" color="red" />
												)}
											</span>
											<span
												title="More actions"
												className="text-capitalize p-1 p-md-2 fontReduce2"
												onClick={
													auth?.user?._id === item?._id
														? () => {}
														: () => {
																setMainUser(item);
																toggle();
														  }
												}>
												<BiCog className="iconDash" />
											</span>
										</>
									)}
								</div>
							</div>
						))
					)}
				</div> */}
        <UserTable
          currentItems={currentItems}
          setLoad={setLoad}
          setThisData={setMainUser}
          toggle={toggle}
          load={load}
        />
        <>
          {page !== "dashboard" && (
            <>
              <MainPaginate
                handlePageClick={handlePageClick}
                pageCount={pageCount}
              />
              <BottomTab
                state={data}
                paginate={
                  columnUse?.isFound
                    ? columnUse?.search_paginate
                    : columnUse?.paginate
                }
              />
              <LoadMore
                next={
                  columnUse?.isFound
                    ? columnUse?.search_paginate?.next
                    : columnUse?.paginate?.next
                }
                handleLoadMore={handleLoadMore}
                loading={loading}
              />
            </>
          )}
        </>
      </div>
      <AddNotification isOpen={isUser} back={toggleNotify} />
      <MakeWallet
        isOpen={isCredit}
        back={toggleCredit}
        user={isCredit?.wallet?.wallet_id}
        amount={isCredit?.wallet}
        title={isCommission}
      />
      <MakeWallet
        isOpen={isDebit}
        back={toggleDebit}
        user={isDebit?.wallet?.wallet_id}
        debit
        amount={isDebit?.wallet}
        title={isCommission}
      />
      <MakeBonus
        isOpen={isBonus}
        back={toggleBonus}
        user={isBonus?._id}
        debit={bonus}
        amount={isBonus?.wallet}
      />
      <DisableUser
        isOpen={isDisable}
        back={toggleDisable}
        toggleAll={() => {
          setMainUser(null);
          setIsOpen(false);
        }}
      />
      <DeleteUser
        isOpen={isDeleted}
        back={toggleDeleted}
        toggleAll={() => {
          setMainUser(null);
          setIsOpen(false);
        }}
      />
      <SwitchUser
        isOpen={isSwitch}
        back={toggleSwitch}
        toggleAll={() => {
          setMainUser(null);
          setIsOpen(false);
        }}
      />
      <CgWallet
        isOpen={isCgWallet}
        setIsOpen={setIsCgWallet}
        user={isCgWallet?._id}
        debit={debit}
        setDebit={setDebit}
        amount={isCgWallet?.wallet}
      />
      <FindDetails
        isOpen={isFilter}
        back={() => setIsFilter(null)}
        state={stateFilter}
        textChange={textChange}
        handleFind={handleFind}
        loading={loadingFilter}
        filter
        user
      />
      <ModalComponents title={"Manage User"} isOpen={isOpen} back={toggle}>
        <div className="row mx-0 g-4 g-md-5">
          {actionArr
            ?.filter((item) =>
              mainUser?.isAdmin ? item?.link !== "switch" : item
            )
            ?.filter((item) =>
              !["agent"]?.includes(mainUser?.privilege)
                ? item?.link !== "cgwallet" && item?.link !== "cgwalletdebit"
                : item
            )
            ?.map((a, i) => (
              <div className="col-6 px-3" key={i}>
                <button
                  onClick={
                    a?.type === "button"
                      ? a?.link === "notification"
                        ? () => {
                            setIsUser(mainUser?._id);
                          }
                        : a?.link === "bonus"
                        ? () => {
                            setIsBonus(mainUser);
                          }
                        : a?.link === "bonusdebit"
                        ? () => {
                            setIsBonus(mainUser);
                            setBonus(mainUser);
                          }
                        : a?.link === "cgwallet"
                        ? () => {
                            setIsCgWallet(mainUser);
                          }
                        : a?.link === "cgwalletdebit"
                        ? () => {
                            setIsCgWallet(mainUser);
                            setDebit(mainUser);
                          }
                        : a?.link === "credit"
                        ? () => {
                            if (a?.title) setIsCommission(a?.title);
                            setIsCredit(mainUser);
                          }
                        : a?.link === "debit"
                        ? () => {
                            if (a?.title) setIsCommission(a?.title);
                            setIsDebit(mainUser);
                          }
                        : a?.link === "delete"
                        ? () => {
                            setIsDeleted(mainUser);
                          }
                        : a?.link === "disable"
                        ? () => {
                            setIsDisable(mainUser);
                          }
                        : a?.link === "switch"
                        ? () => {
                            setIsSwitch(mainUser);
                          }
                        : null
                      : () =>
                          navigate(
                            `${a?.link}/${mainUser?._id}?name=${mainUser?.firstName}_${mainUser?.lastName}`,
                            {
                              state: mainUser,
                            }
                          )
                  }
                  className="btn btn-outline-primary1 text-capitalize w-100 py-3"
                >
                  {a?.link === "disable"
                    ? `${mainUser?.status ? "Disable" : "Activate"} user`
                    : a?.name}
                </button>
              </div>
            ))}
        </div>
      </ModalComponents>
    </>
  );
};

export const UserTable = ({
  currentItems,
  setThisData,
  toggle,
  load,
  setLoad,
}) => {
  let { nairaSign, numberWithCommas, auth, manageUserActiveness } =
    useContext(GlobalState);
  const params = useParams();
  console.log(params);
  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      <div className="tw-flex tw-justify-end">
        <ExportButtons
          tableId="userTable"
          fileName={`${params?.page} ${params?.id && `(${params?.id})`}`}
        />
      </div>
      <div className="tw-relative tw-overflow-x-auto tw-overflow-y-visible tw-min-h-52">
        <table id="userTable" className="tw-w-full tw-text-sm tw-text-left">
          <thead className="tw-text-xs tw-text-gray-700 tw-captalize bland tw-px-1">
            <tr>
              <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-capitalize">
                Name
              </th>
              <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-capitalize">
                Number
              </th>
              <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-capitalize">
                Email
              </th>
              <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-capitalize">
                Balance
              </th>
              <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-capitalize">
                Wallet ID
              </th>
              <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-capitalize">
                Date & Time
              </th>
              <th scope="col" className="tw-px-6 tw-py-6 Lexend tw-capitalize">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {currentItems?.map((item, i) => (
              <tr
                className="bg-white tw-border-b tw-text-xs px-1 py-3 myCursor"
                key={i}
              >
                <td className="tw-w-4 tw-p-4">
                  <span className="d-flex align-items-center w-100">
                    {/* <img
											src={item?.avatar ? item?.avatar?.url : avatar}
											alt="User"
											className="img-fluid rounded-circle d-none d-md-flex imgFluid"
											style={{
												height: "3rem",
												width: "3rem",
											}}
										/> */}
                    <AvatarImg user={item} />

                    <span className="fontInherit my-0 ps-0 ps-md-1 textTrunc textTrunc2 fontReduce w-100">
                      {item?.firstName} {item?.lastName}
                    </span>
                  </span>
                </td>
                <td className="tw-px-6 tw-py-6">{item?.telephone}</td>
                <td className="tw-px-6 tw-py-6">{item?.email}</td>
                <td className="tw-px-6 tw-py-6">
                  <span className="flex items-center">
                    <span className="fontInherit">{nairaSign}</span>{" "}
                    <span>
                      {item?.wallet?.available
                        ? numberWithCommas(
                            Number(item?.wallet?.available).toFixed(2)
                          )
                        : 0}
                    </span>
                  </span>
                </td>
                <td className="tw-px-6 tw-py-6">{item?.wallet?.wallet_id}</td>
                <td className="tw-px-6 tw-py-6">
                  {moment(item?.createdAt).format("DD/MM/YYYY hh:mm A")}
                </td>
                <td className="tw-px-6 tw-py-6">
                  {auth?.user?._id === item?._id ? null : (
                    <span className="tw-flex items-center">
                      <span
                        title={`${item?.status ? "Suspend" : "Activate"} ${
                          item?.privilege
                        }`}
                        onClick={async () => {
                          setLoad({ isLoad: true, loadData: item?._id });
                          await manageUserActiveness(
                            item._id,
                            item?.status ? "suspend" : "activate"
                          );
                          setLoad({ isLoad: false, loadData: "" });
                        }}
                        className="text-capitalize px-1"
                      >
                        {load?.isLoad && load?.loadData === item?._id ? (
                          <ClipLoader color="black" size={16} />
                        ) : item?.status ? (
                          <HiThumbDown className="iconDash" color="green" />
                        ) : (
                          <HiThumbUp className="iconDash" color="red" />
                        )}
                      </span>
                      <span
                        title="More actions"
                        className="text-capitalize px-1"
                        onClick={
                          auth?.user?._id === item?._id
                            ? () => {}
                            : () => {
                                setThisData(item);
                                toggle();
                              }
                        }
                      >
                        <BiCog className="iconDash" />
                      </span>
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const MakeAdmin = ({ isOpen, back, datum, privilege }) => {
  let {
    manager,
    manageUserPrivilege,
    superadmin,
    accountant,
    developer,
    marketer,
    topuser,
    reseller,
    user,
    agent,
  } = useContext(GlobalState);
  let init = {
      firstName: "",
      lastName: "",
      email: "",
      status: "active",
      telephone: "",
      privilege,
    },
    [state, setState] = useState(init),
    textChange =
      (name) =>
      ({ target: { value } }) => {
        setState({ ...state, [name]: value });
      },
    [shouldEdit, setShouldEdit] = useState(false),
    [newLoad, setNewLoad] = useState(false),
    [submit, setSubmit] = useState(false),
    [columnUse, setColumnUse] = useState(null);

  useEffect(() => {
    if (datum) {
      setState(datum);
      setShouldEdit(true);
    }
  }, [datum]);

  let handleSubmit = async (e) => {
    e?.preventDefault();
    if (!state?.lastName || !state?.firstName || !state?.email) return;

    setNewLoad(true);
    await manageUserPrivilege(
      { ...state, privilege },
      datum ? datum?._id : false,
      privilege
    );
    setNewLoad(false);
    setSubmit(true);
  };

  useEffect(() => {
    if (privilege === "superadmin") setColumnUse(superadmin);
    if (privilege === "manager") setColumnUse(manager);
    if (privilege === "marketer") setColumnUse(marketer);
    if (privilege === "accountant") setColumnUse(accountant);
    if (privilege === "developer") setColumnUse(developer);
    if (privilege === "topuser") setColumnUse(topuser);
    if (privilege === "reseller") setColumnUse(reseller);
    if (privilege === "agent") setColumnUse(agent);
    if (privilege === "user") setColumnUse(user);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    privilege,
    superadmin,
    manager,
    marketer,
    accountant,
    developer,
    topuser,
    reseller,
    user,
    agent,
  ]);

  useEffect(() => {
    if (submit && columnUse?.isAdded) {
      setSubmit(false);
      setState(init);
      back();
    }
    if (submit && columnUse?.isUpdated) {
      setSubmit(false);
      setState(init);
      back();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit, columnUse?.isAdded, columnUse?.isUpdated]);

  return (
    <>
      <ModalComponents
        title={
          datum ? `update ${datum?.firstName} data` : `add ${privilege} data`
        }
        isOpen={isOpen}
        back={back}
      >
        <div className="downH2 d-flex">
          <form className="w-100" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="value">Firstname</label>
              <input
                type={"text"}
                placeholder="Name"
                className="form-control py-3"
                value={state?.firstName}
                onChange={textChange("firstName")}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="value">Lastname</label>
              <input
                type={"text"}
                placeholder="Name"
                className="form-control py-3"
                value={state?.lastName}
                onChange={textChange("lastName")}
              />
            </div>
            {!shouldEdit && (
              <div className="mb-4">
                <label htmlFor="value">Email</label>
                <input
                  type={"email"}
                  placeholder="example@mail.com"
                  className="form-control py-3"
                  value={state?.email}
                  onChange={textChange("email")}
                />
              </div>
            )}
            <div className="mb-4">
              <label htmlFor="value">Telephone</label>
              <input
                type={"tel"}
                placeholder="080 0000 000"
                className="form-control py-3"
                value={state?.telephone}
                onChange={textChange("telephone")}
                maxLength={11}
              />
            </div>
            {shouldEdit && (
              <div className="mb-4">
                <label htmlFor="value">Status</label>
                <select
                  name="Status"
                  className="form-control py-3"
                  value={state?.status}
                  onChange={textChange("status")}
                  id=""
                >
                  <option value="">Select status</option>
                  <option value="enable">Active</option>
                  <option value="disable">Inactive</option>
                </select>
              </div>
            )}
            <Buttons
              title={datum ? `update ${privilege}` : `add ${privilege}`}
              css="btn-primary1 text-capitalize py-3 px-4 px-lg-5 mx-auto my-4"
              width={"w-50 w50"}
              onClick={handleSubmit}
              style={{ borderRadius: "30px" }}
              loading={newLoad}
            />
          </form>
        </div>
      </ModalComponents>
    </>
  );
};

export const PrivilegeTopper = ({ privilege }) => {
  let {
      numberWithCommas,
      superadmin,
      manager,
      accountant,
      developer,
      marketer,
      topuser,
      reseller,
      user,
      nairaSignNeutral,
      allUsers,
      agent,
    } = useContext(GlobalState),
    [columnUse, setColumnUse] = useState(null);

  useEffect(() => {
    if (privilege === "superadmin") setColumnUse(superadmin);
    if (privilege === "manager") setColumnUse(manager);
    if (privilege === "marketer") setColumnUse(marketer);
    if (privilege === "accountant") setColumnUse(accountant);
    if (privilege === "developer") setColumnUse(developer);
    if (privilege === "topuser") setColumnUse(topuser);
    if (privilege === "reseller") setColumnUse(reseller);
    if (privilege === "agent") setColumnUse(agent);
    if (privilege === "user") setColumnUse(user);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    privilege,
    superadmin,
    manager,
    marketer,
    accountant,
    developer,
    topuser,
    reseller,
    user,
    agent,
  ]);

  let usersArr = [
    {
      icon: icon1,
      name: `total ${privilege ? `${privilege}s'` : "users"}`,
      number: columnUse?.paginate?.total
        ? numberWithCommas(columnUse?.paginate?.total)
        : 0,
      color: "linear-gradient(90.18deg, #84C7DB -52.19%, #377FB6 81.92%)",
    },
    {
      icon: icon2,
      name: "total transactions",
      number: `${nairaSignNeutral}${numberWithCommas(
        Number(
          allUsers?.details?.[privilege]?.transactions ||
            columnUse?.transactions ||
            0
        ).toFixed(2)
      )}`,
      color: "linear-gradient(90deg, #D88ADA 16.14%, #CA64FB 101.45%)",
      link: "/transactions",
    },
    {
      icon: icon3,
      name: "wallet balance",
      number: `${nairaSignNeutral}${numberWithCommas(
        Number(
          allUsers?.details?.[privilege]?.wallet || columnUse?.wallet || 0
        ).toFixed(2)
      )}`,
      color: "linear-gradient(96.86deg, #F4EA75 18.88%, #F7BA5E 125.77%)",
      link: "/wallets/manage-wallet",
    },
  ];
  return (
    <>
      <ThreeBoxBar list={usersArr} />
    </>
  );
};

const SwitchUser = ({ isOpen, back, toggleAll }) => {
  const { manageUserActiveness, user, reseller, topuser, agent } =
    useContext(GlobalState);
  let [loading, setLoading] = useState(false),
    [submit, setSubmit] = useState(false),
    [privilege, setPrivilege] = useState(""),
    [columnUse, setColumnUse] = useState(null),
    handleSubmit = async (e) => {
      e?.preventDefault();
      if (privilege === isOpen?.privilege) return;
      setLoading(true);
      await manageUserActiveness(isOpen._id, privilege, "put");
      setSubmit(true);
      setLoading(false);
    };

  useEffect(() => {
    setPrivilege(isOpen?.privilege);
  }, [isOpen?.privilege]);

  useEffect(() => {
    if (privilege === "topuser") setColumnUse(topuser);
    if (privilege === "reseller") setColumnUse(reseller);
    if (privilege === "agent") setColumnUse(agent);
    if (privilege === "user") setColumnUse(user);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privilege, topuser, reseller, user, agent]);

  useEffect(() => {
    if (submit && columnUse?.isUpdated) {
      setSubmit(false);
      toggleAll();
      back();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit, columnUse?.isUpdated]);
  // console.log({ isOpen });

  let switchcase = [
    // {
    // 	name: "Top user",
    // 	case: "topuser",
    // },
    {
      name: "Reseller",
      case: "reseller",
    },
    {
      name: "Agent",
      case: "agent",
    },
    {
      name: "User",
      case: "user",
    },
  ];

  return (
    <>
      <ModalComponents
        title={`Switch User Privilege`}
        back={back}
        isOpen={isOpen}
      >
        <div className="downH2 d-flex">
          <div className="my-auto w-100">
            <form
              className="d-flex flex-column justify-content-center align-items-center h-100 w-100"
              onSubmit={handleSubmit}
            >
              <div className="w-100 d-block py-5">
                <label htmlFor="Privilege" className="Lexend">
                  Switch privilege from {isOpen?.privilege}
                </label>
                <div className="row mx-0 g-3">
                  {switchcase?.map((item, i) => (
                    <div className="col p-2" key={i}>
                      <div
                        className={`btn ${
                          privilege === item?.case
                            ? "btn-primary1"
                            : "btn-outline-primary1"
                        } w-100 h-100 text-capitalize py-3 py-md-5 rounded20`}
                        onClick={() => setPrivilege(item?.case)}
                      >
                        {item?.name}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="btn-group mx-auto w-50">
                <Buttons
                  loading={loading}
                  onClick={handleSubmit}
                  width="w-50"
                  css="btn-success2 text-capitalize py-3 w-50"
                  title={"yes"}
                />
                <Buttons
                  onClick={back}
                  width="w-50"
                  type="button"
                  css="btn-danger2 text-capitalize py-3 w-50"
                  title={"no"}
                />
              </div>
            </form>
          </div>
        </div>
      </ModalComponents>
    </>
  );
};

let UserData = ({
  data,
  mainUser,
  actionArr,
  setMainUser,
  toggle,
  isOpen,
  setIsUser,
  setIsDisable,
}) => {
  let { numberWithCommas, nairaSign } = useContext(GlobalState),
    navigate = useNavigate();
  return (
    <>
      <div className="bland row mx-0 py-3 text-capitalize">
        <div className="col textTrunc text-uppercase fontReduce fw-bold Lexend">
          Name
        </div>
        <div className="col textTrunc text-uppercase fontReduce fw-bold Lexend">
          number
        </div>
        <div className="col textTrunc text-uppercase fontReduce fw-bold Lexend d-none d-md-flex">
          date&time
        </div>
        <div className="col textTrunc text-uppercase fontReduce fw-bold Lexend">
          balance
        </div>
        <div className="col textTrunc text-uppercase fontReduce fw-bold Lexend d-none d-md-flex">
          Email
        </div>
        <div className="col textTrunc text-uppercase fontReduce fw-bold Lexend d-none d-md-flex">
          Wallet ID
        </div>
        <div className="col textTrunc text-uppercase fontReduce fw-bold Lexend text-center">
          Action
        </div>
      </div>
      <div className="bland2 row mx-0">
        {data?.length === 0 ? (
          <EmptyComponent subtitle={"Users' list is empty"} />
        ) : (
          data?.map((item, index) => (
            <div key={index} className="row mx-0 p-3 border-bottom">
              <div className="col fontReduce2 textTrunc my-auto">
                <div className="d-flex align-items-center w-100">
                  <img
                    src={item?.avatar ? item?.avatar?.url : avatar}
                    alt="User"
                    className="img-fluid rounded-circle d-none d-md-flex imgFluid"
                    style={{
                      height: "3rem",
                      width: "3rem",
                    }}
                  />
                  <span className="fontInherit my-0 ps-0 ps-md-1 textTrunc textTrunc2 fontReduce w-100">
                    {item?.lastName} {item?.firstName}
                  </span>
                </div>
              </div>
              <div className="col fontReduce textTrunc my-auto">
                {item?.telephone}
              </div>
              <div className="col fontReduce2 textTrunc my-auto d-none d-md-flex">
                {moment(item?.createdAt).format("DD/MM/YYYY hh:mm A")}
              </div>
              <div className="col fontReduce2 textTrunc my-auto d-flex w-100">
                <span className="fontInherit d-none d-md-flex me-md-1">
                  {nairaSign}
                </span>{" "}
                <span>
                  {numberWithCommas(Number(item?.wallet?.available).toFixed(2))}
                </span>
              </div>
              <div className="col fontReduce2 textTrunc my-auto d-none d-md-flex">
                {item?.email}
              </div>
              <div className="col fontReduce2 textTrunc my-auto d-none d-md-flex">
                {item?.wallet?.wallet_id}
              </div>
              <div
                className="col fontReduce2 textTrunc my-auto myCursor text-center"
                onClick={() => {
                  setMainUser(item);
                  toggle();
                }}
              >
                <BiCog className="iconDash" />
              </div>
              <ModalComponents
                title={"Manage User"}
                isOpen={isOpen}
                back={toggle}
              >
                <div className="row mx-0 g-4 g-md-5">
                  {actionArr?.map((a, i) => (
                    <div className="col-6 px-3" key={i}>
                      <button
                        onClick={
                          a?.type === "button"
                            ? a?.link === "notification"
                              ? () => {
                                  setIsUser(mainUser?._id);
                                }
                              : a?.link === "disable"
                              ? () => {
                                  setIsDisable(mainUser);
                                }
                              : null
                            : () =>
                                navigate(
                                  `${a?.link}/${mainUser?._id}?name=${mainUser?.lastName}_${mainUser?.firstName}`
                                )
                        }
                        className="btn btn-outline-primary1 text-capitalize w-100 py-3"
                      >
                        {a?.link === "disable"
                          ? `${mainUser?.status ? "Disable" : "Activate"} user`
                          : a?.name}
                      </button>
                    </div>
                  ))}
                </div>
              </ModalComponents>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export let NewUserPaginate = ({
  state,
  mainUser,
  actionArr,
  setMainUser,
  toggle,
  isOpen,
  setIsUser,
  setIsDisable,
}) => {
  let [range, setRange] = useState(10);

  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + range;

  if (!state) return;

  const currentItems = state.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(state.length / range);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * range) % state.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <MainRanger range={range} setRange={setRange} />
      <UserData
        data={currentItems}
        mainUser={mainUser}
        actionArr={actionArr}
        setMainUser={setMainUser}
        toggle={toggle}
        isOpen={isOpen}
        setIsUser={setIsUser}
        setIsDisable={setIsDisable}
      />
      <MainPaginate handlePageClick={handlePageClick} pageCount={pageCount} />
    </>
  );
};

export const CgWallet = ({
  isOpen,
  setIsOpen,
  user,
  debit,
  setDebit,
  amount,
}) => {
  let { manageCGWalletHistory, cgwalletHistory, category } =
    useContext(GlobalState);

  let init = {
      category: "",
      volume: "",
      size: "",
    },
    [state, setState] = useState(init),
    [loading, setLoading] = useState(false),
    [submit, setSubmit] = useState(false),
    handleSubmit = async (e) => {
      e?.preventDefault();
      setLoading(true);
      console.log({
        state,
        newData: {
          ...state,
          user,
          category: category?.data?.find(
            (item) => item?.categoryId === state?.category
          )?._id,
        },
      });
      await manageCGWalletHistory(debit && user ? "put" : "post", {
        ...state,
        user,
        category: category?.data?.find(
          (item) => item?.categoryId === state?.category
        )?._id,
      });
      setLoading(false);
      setSubmit(true);
    };

  useEffect(() => {
    if (submit && cgwalletHistory?.isAdded) {
      setState(init);
      setSubmit(false);
      setIsOpen(false);
      if (setDebit) setDebit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit, cgwalletHistory?.isAdded]);

  return (
    <>
      <ModalComponents
        title={`
				${debit ? "Debit" : "Credit"}
				 CG Wallet`}
        isOpen={isOpen ? true : false}
        toggle={() => {
          setIsOpen(false);
          setState(init);
        }}
      >
        <h2 className="my-3 text-center Lexend">
          {debit ? "Debit" : "Credit"} Stock
        </h2>
        {amount ? (
          <>
            <CgBalanceComponent cgBalance={amount?.cgBalance} col={"col-6"} />
          </>
        ) : null}
        <form className="w-100" onSubmit={handleSubmit}>
          {/* <div className="mb-4">
						<label htmlFor="Category">Category</label>
						<select
							className="form-control py-3 py-md-4 text-capitalize form-select"
							name="category"
							placeholder="Category"
							value={data?.category}
							onChange={e => setState({ ...state, category: e.target.value })}
							id="category">
							<option value="">select category</option>
							{category?.data
								?.filter(item => item?.product?.name === "data")
								?.map((item, i) => (
									<option value={item?._id} key={i}>
										{item?.name}
									</option>
								))}
						</select>
					</div> */}
          <div className="mb-4">
            <label htmlFor="Category">Category</label>

            <DataNetworkList
              state={state?.category}
              setState={(i) => {
                setState({ ...state, category: i });
              }}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="Name">Volume</label>
            <input
              type="number"
              className="form-control py-3"
              value={state?.volume}
              onChange={(e) => setState({ ...state, volume: e.target.value })}
              placeholder="Volume"
              min={0}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="Size">Size</label>
            <select
              className="form-control py-3 py-md-4 text-capitalize form-select"
              name="size"
              placeholder="Size"
              value={state?.size}
              onChange={(e) => setState({ ...state, size: e.target.value })}
              id="size"
            >
              <option value="">select size</option>
              <option value="MB">MB</option>
              <option value="GB">GB</option>
              <option value="TB">TB</option>
            </select>
          </div>
          {/* <div className="mb-4">
						<label htmlFor="Newtwork">Network</label>
						<NetworkList
							state={state?.network}
							setState={i => {
								setState({ ...state, network: i });
							}}
						/>
					</div>
					<div className="mb-4">
						<label htmlFor="Education">{state?.network} Cgwallet</label>
						<select
							className="form-control py-3 py-md-4 text-capitalize form-select"
							name="cgwallet"
							placeholder="Education"
							value={state?.cgwallet}
							onChange={e => setState({ ...state, cgwallet: e.target.value })}
							id="cgwallet">
							<option value="">select cgwallet</option>
							{data?.map((it, i) => (
								<option value={it?._id} key={i}>
									{it?.volume} {it?.size} {it?.category?.name} / NGN{" "}
									{numberWithCommas(
										it?.amount ? Number(it?.amount).toFixed(2) : 0
									)}
								</option>
							))}
						</select>
					</div> */}
          <Buttons
            title={debit ? "debit" : "purchase"}
            css="btn-primary1 text-capitalize py-3 w-50 my-4 mx-auto"
            width={"w-50"}
            style={{ borderRadius: "30px" }}
            loading={loading}
            onClick={handleSubmit}
          />
        </form>
      </ModalComponents>
    </>
  );
};
