import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import { useParams } from "react-router-dom";
import { GlobalState } from "../../Data/Context";
import Icon1 from "../../Assets/users.svg";
import { Icon } from "@iconify/react";

const colors = ["#f1c40f", "#fd7e14", "#0d6efd", "#d63384", "#198754"];
const Administration = () => {
  let {
    setStateName,
    user,
    reseller,
    numberWithCommas,
    upgrade,
    agent,
    trackingWallet,
    getUserKeyPatrons,
    manageUpgrade,
    getUserPrivileges,
    getDataTransactionStat,
  } = useContext(GlobalState);
  useEffect(() => {
    getUserKeyPatrons("day");
    getUserKeyPatrons("month");
    getUserKeyPatrons("week");
    manageUpgrade();
    getUserPrivileges("agent");
    getUserPrivileges("reseller");
    getUserPrivileges("user");
    setStateName("manage general users");
    getDataTransactionStat(null, "registration");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let productArr = [
    {
      name: "Manage User",
      link: "/users",
      color: "linear-gradient(90.18deg, #3199B7 -52.19%, #144468 81.92%)",
      textColor: "white",
      number: user?.paginate?.total,
      icon2: "fa6-solid:users",
      icon: Icon1,
    },
    {
      name: "Manage Reseller",
      link: "/reseller",
      color: "linear-gradient(96.86deg, #F2E553 18.88%, #FF9900 125.77%)",
      number: reseller?.paginate?.total,
      icon: Icon1,
      icon2: "fa6-solid:users-rays",
    },
    {
      name: "Manage Agent",
      link: "/agent",
      color: "linear-gradient(90deg, #E43369 16.14%, #C20E19 101.45%)",
      // color: "linear-gradient(96.86deg, #F2E553 18.88%, #FF9900 125.77%)",
      number: agent?.paginate?.total,
      icon: Icon1,
      icon2: "icon-park-outline:market",
    },
    // {
    // 	name: "Manage Topuser",
    // 	link: "/topuser",
    // 	color: "linear-gradient(90deg, #E43369 16.14%, #C20E19 101.45%)",
    // 	textColor: "white",
    // 	number: topuser?.paginate?.total,
    // },
    {
      name: "Manage Upgrade Request",
      link: "/upgrade",
      color: "linear-gradient(90.18deg, #6CB731 -52.19%, #0F5A16 81.92%)",
      textColor: "white",
      number: upgrade?.paginate?.total,
      icon: Icon1,
      icon2: "carbon:intent-request-scale-in",
    },
    {
      name: "Manage Wallet Tracking",
      link: "/wallet",
      color: "linear-gradient(90deg, #DE0DE2 16.14%, #0E102D 101.45%)",
      textColor: "white",
      number: trackingWallet?.paginate?.total,
      icon: Icon1,
      icon2: "tdesign:undertake-transaction",
    },
    {
      name: "Manage Key Patrons",
      link: "/patrons",
      color: "linear-gradient(90deg, #DE0DE2 16.14%, #0E102D 101.45%)",
      textColor: "white",
      icon: Icon1,
      icon2: "game-icons:key-card",
    },
    {
      name: "User Registration Stat",
      link: "/registration",
      color: "linear-gradient(90deg, #DE0DE2 16.14%, #0E102D 101.45%)",
      textColor: "white",
      icon: Icon1,
      icon2: "gridicons:stats-alt",
    },
  ];

  let params = useParams();

  return (
    <Container>
      <div className="tw-w-5/6 tw-mx-auto tw-grid tw-mt-16 tw-grid-cols-3 tw-gap-8">
        {productArr?.map((item, i) => (
          <div
            className="tw-h-28 tw-flex tw-px-6 tw-justify-between tw-bg-white tw-shadow-lg tw-cursor-pointer tw-rounded-lg tw-items-center"
            key={i}
          >
            <div className="">
              <div>
                <Icon
                  color={colors[i % colors.length]}
                  style={{
                    fontSize: "36px",
                  }}
                  icon={item?.icon2}
                />
                {/* <img src={item.icon2} alt="" className="tw-h-16" /> */}
              </div>
              {/* <img src={item.icon} alt="" className="tw-h-16" /> */}
            </div>
            <Link
              to={
                item?.type === "button" ? "#" : `/${params?.page}${item?.link}`
              }
              className="tw-flex tw-flex-col text-decoration-none"
            >
              <span className="tw-text-[#1b1b1b] tw-text-xl tw-font-medium">
                {item?.name}
              </span>
              <span className="tw-pt-4 tw-text-base tw-text-right tw-text-[#1b1b1b] tw-font-medium Lexend">
                {item?.number ? numberWithCommas(item?.number) : ""}
              </span>
            </Link>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default Administration;
