import axios from "axios";
import {
	ADD_BIZ,
	ADD_BIZ_FAIL,
	ADD_BIZ_SOCKET,
	DELETE_TRANSACTION,
	DELETE_TRANSACTION_FAIL,
	GET_ACTIVITY,
	GET_ACTIVITY_FAIL,
	GET_ALL_BIZ,
	GET_BIZ,
	GET_BIZ_FAIL,
	GET_BIZ_LOADING,
	LOGOUT,
	SEARCH_ACTIVITY,
	SEARCH_ACTIVITY_FAIL,
	SEARCH_ACTIVITY_LOADING,
	SEARCH_ALL_BIZ,
	SEARCH_ALL_BIZ_FAIL,
	SEARCH_ALL_BIZ_LOADING,
	SEARCH_RELOAD,
	SEARCH_BIZ,
	SEARCH_BIZ_FAIL,
	SEARCH_BIZ_LOADING,
	GET_ACTIVITY_OTHERS,
	GET_ALL_MY_BIZ,
	GET_ALL_ALL_BIZ,
	SEARCH_ALL_VERIFY,
	SEARCH_ALL_VERIFY_FAIL,
	SEARCH_ALL_VERIFY_LOADING,
	SEARCH_VERIFY,
	SEARCH_VERIFY_FAIL,
	SEARCH_VERIFY_LOADING,
	ADD_VERIFY,
	ADD_VERIFY_SOCKET,
	ADD_VERIFY_FAIL,
	GET_VERIFY,
	GET_ALL_MY_VERIFY,
	GET_ALL_ALL_VERIFY,
	GET_ALL_VERIFY,
	GET_VERIFY_FAIL,
	GET_VERIFY_LOADING,
} from "../Actions/ActionTypes";
import { DeleteData } from "./AuthReducer";
import { returnErrors } from "./ErrorReducer";

const initialState = {
	isLoading: false,
	biz: [],
	isAdded: false,
	isDeleted: false,
	paginate: null,
	isFound: null,
	searchLoading: null,
	mainSearch: [],
	search: "",
	search_paginate: null,
	all_biz: [],
	all_paginate: null,
	all_isFound: null,
	all_searchLoading: null,
	all_mainSearch: [],
	all_search: "",
	all_search_paginate: null,
};

const BizReducer = (state = initialState, action) => {
	const { type, payload } = action;
	let data = payload?.data ? payload?.data : payload;
	switch (type) {
		case SEARCH_ALL_BIZ:
			return {
				...state,
				all_isFound: true,
				all_searchLoading: false,
				all_mainSearch:
					action?.search === state?.all_search ? data : state?.all_mainSearch,
				all_search_paginate:
					action?.search === state?.all_search
						? payload?.paginate
						: state?.all_search_paginate,
			};
		case SEARCH_ALL_BIZ_FAIL:
			return {
				...state,
				all_searchLoading: false,
				all_mainSearch: state.mainSearch,
				all_search_paginate: state.search_paginate,
				all_isFound: false,
			};
		case SEARCH_ALL_BIZ_LOADING:
			return {
				...state,
				all_search: action.search,
				all_searchLoading: true,
			};
		case SEARCH_RELOAD:
			return {
				...state,
				all_isFound: false,
				all_searchLoading: false,
				isFound: false,
				searchLoading: false,
			};
		case SEARCH_BIZ:
			return {
				...state,
				isFound: true,
				searchLoading: false,
				mainSearch: action?.search === state?.search ? data : state?.mainSearch,
				search_paginate:
					action?.search === state?.search
						? payload?.paginate
						: state?.search_paginate,
			};
		case SEARCH_BIZ_FAIL:
			return {
				...state,
				searchLoading: false,
				isFound: false,
				mainSearch: state.mainSearch,
				search_paginate: state.search_paginate,
			};
		case SEARCH_BIZ_LOADING:
			return {
				...state,
				search: action.search,
				searchLoading: true,
			};
		case ADD_BIZ:
			return {
				...state,
				isAdded: true,
				biz: [data, ...state.biz],
				all_biz: [data, ...state.all_biz],
				paginate: {
					...state?.paginate,
					result: state?.paginate?.result + 1,
					total: state?.paginate?.total + 1,
				},
			};
		case ADD_BIZ_SOCKET:
			return {
				...state,
				all_biz: [data, ...state.all_biz],
			};
		case ADD_BIZ_FAIL:
			return {
				...state,
				isAdded: false,
				isDeleted: false,
			};
		case GET_BIZ:
			return {
				...state,
				isLoading: false,
				biz: payload?.data,
				paginate: payload?.paginate,
			};
		case GET_ALL_MY_BIZ:
			return {
				...state,
				myall: payload?.data,
			};
		case GET_ALL_ALL_BIZ:
			return {
				...state,
				all: payload?.data,
			};
		case GET_ALL_BIZ:
			return {
				...state,
				isLoading: false,
				all_biz: payload?.data,
				all_paginate: payload?.paginate,
			};
		case DELETE_TRANSACTION:
			return {
				...state,
				mainSearch: DeleteData(state.mainSearch, payload),
				biz: DeleteData(state.biz, payload),
				all_mainSearch: DeleteData(state.all_mainSearch, payload),
				all_biz: DeleteData(state.all_biz, payload),
				isDeleted: true,
			};
		case DELETE_TRANSACTION_FAIL:
			return {
				...state,
				isDeleted: false,
			};
		case GET_BIZ_FAIL:
			return {
				...state,
				isLoading: false,
			};
		case GET_BIZ_LOADING:
			return {
				...state,
				isLoading: true,
			};
		case LOGOUT:
			return initialState;
		default:
			return state;
	}
};

export default BizReducer;

let initAct = {
	data: [],
	paginate: null,
	general_data: [],
	general_paginate: null,
	isFound: null,
	searchLoading: null,
	mainSearch: [],
	search: "",
	search_paginate: null,
};

export const ActivityReducer = (state = initAct, action) => {
	const { type, payload } = action;
	let data = payload?.data ? payload?.data : payload;
	switch (type) {
		case SEARCH_ACTIVITY:
			return {
				...state,
				isFound: true,
				searchLoading: false,
				mainSearch: action?.search === state?.search ? data : state?.mainSearch,
				search_paginate:
					action?.search === state?.search
						? payload?.paginate
						: state?.search_paginate,
			};
		case SEARCH_ACTIVITY_FAIL:
			return {
				...state,
				searchLoading: false,
				mainSearch: state.mainSearch,
				search_paginate: state.search_paginate,
			};
		case SEARCH_ACTIVITY_LOADING:
			return {
				...state,
				search: action.search,
				searchLoading: true,
			};
		case SEARCH_RELOAD:
			return {
				...state,
				isFound: false,
				searchLoading: false,
			};
		case GET_ACTIVITY:
			return {
				...state,
				isLoading: false,
				data: payload?.data,
				paginate: payload?.paginate,
			};
		case GET_ACTIVITY_OTHERS:
			return {
				...state,
				isLoading: false,
				general_data: payload?.data,
				general_paginate: payload?.paginate,
			};
		case GET_ACTIVITY_FAIL:
			return {
				...state,
				isLoading: false,
			};
		case LOGOUT:
			return initAct;
		default:
			return state;
	}
};

export const getActivity = data => async dispatch => {
	try {
		if (data?.search)
			dispatch({ type: SEARCH_ACTIVITY_LOADING, search: data?.search });
		let res = await axios.get(
			`/api/v2/activity?type=all
			${!data?.limit ? "" : `&limit=${data.limit}`}
			${!data?.general ? "" : `&general=${data.general}`}
			${!data?.search ? "" : `&search=${data?.search}`}
			`
		);
		dispatch({
			type: data?.search
				? SEARCH_ACTIVITY
				: data?.general
				? GET_ACTIVITY_OTHERS
				: GET_ACTIVITY,
			payload: res.data,
			search: data?.search
				? res?.data?.search
					? res?.data?.search
					: data?.search
				: "",
		});
	} catch (err) {
		if (err) console.log({ err });
		let error = err.response?.data?.error;
		if (data?.search) {
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			}
		}
		dispatch({
			type: data?.search ? SEARCH_ACTIVITY_FAIL : GET_ACTIVITY_FAIL,
		});
	}
};

const initialVerify = {
	isLoading: false,
	verify: [],
	isAdded: false,
	isDeleted: false,
	paginate: null,
	isFound: null,
	searchLoading: null,
	mainSearch: [],
	search: "",
	search_paginate: null,
	all_verify: [],
	all_paginate: null,
	all_isFound: null,
	all_searchLoading: null,
	all_mainSearch: [],
	all_search: "",
	all_search_paginate: null,
};

export const VerifyReducer = (state = initialVerify, action) => {
	const { type, payload } = action;
	let data = payload?.data ? payload?.data : payload;
	switch (type) {
		case SEARCH_ALL_VERIFY:
			return {
				...state,
				all_isFound: true,
				all_searchLoading: false,
				all_mainSearch:
					action?.search === state?.all_search ? data : state?.all_mainSearch,
				all_search_paginate:
					action?.search === state?.all_search
						? payload?.paginate
						: state?.all_search_paginate,
			};
		case SEARCH_ALL_VERIFY_FAIL:
			return {
				...state,
				all_searchLoading: false,
				all_mainSearch: state.mainSearch,
				all_search_paginate: state.search_paginate,
				all_isFound: false,
			};
		case SEARCH_ALL_VERIFY_LOADING:
			return {
				...state,
				all_search: action.search,
				all_searchLoading: true,
			};
		case SEARCH_RELOAD:
			return {
				...state,
				all_isFound: false,
				all_searchLoading: false,
				isFound: false,
				searchLoading: false,
			};
		case SEARCH_VERIFY:
			return {
				...state,
				isFound: true,
				searchLoading: false,
				mainSearch: action?.search === state?.search ? data : state?.mainSearch,
				search_paginate:
					action?.search === state?.search
						? payload?.paginate
						: state?.search_paginate,
			};
		case SEARCH_VERIFY_FAIL:
			return {
				...state,
				searchLoading: false,
				isFound: false,
				mainSearch: state.mainSearch,
				search_paginate: state.search_paginate,
			};
		case SEARCH_VERIFY_LOADING:
			return {
				...state,
				search: action.search,
				searchLoading: true,
			};
		case ADD_VERIFY:
			return {
				...state,
				isAdded: true,
				verify: [data, ...state.verify],
				all_verify: [data, ...state.all_verify],
				paginate: {
					...state?.paginate,
					result: state?.paginate?.result + 1,
					total: state?.paginate?.total + 1,
				},
			};
		case ADD_VERIFY_SOCKET:
			return {
				...state,
				all_verify: [data, ...state.all_verify],
			};
		case ADD_VERIFY_FAIL:
			return {
				...state,
				isAdded: false,
				isDeleted: false,
			};
		case GET_VERIFY:
			return {
				...state,
				isLoading: false,
				verify: payload?.data,
				paginate: payload?.paginate,
			};
		case GET_ALL_MY_VERIFY:
			return {
				...state,
				myall: payload?.data,
			};
		case GET_ALL_ALL_VERIFY:
			return {
				...state,
				all: payload?.data,
			};
		case GET_ALL_VERIFY:
			return {
				...state,
				isLoading: false,
				all_verify: payload?.data,
				all_paginate: payload?.paginate,
			};
		case DELETE_TRANSACTION:
			return {
				...state,
				mainSearch: DeleteData(state.mainSearch, payload),
				verify: DeleteData(state.verify, payload),
				all_mainSearch: DeleteData(state.all_mainSearch, payload),
				all_verify: DeleteData(state.all_verify, payload),
				isDeleted: true,
			};
		case DELETE_TRANSACTION_FAIL:
			return {
				...state,
				isDeleted: false,
			};
		case GET_VERIFY_FAIL:
			return {
				...state,
				isLoading: false,
			};
		case GET_VERIFY_LOADING:
			return {
				...state,
				isLoading: true,
			};
		case LOGOUT:
			return initialVerify;
		default:
			return state;
	}
};