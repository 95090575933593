import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Container } from "reactstrap";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { GlobalState } from "../Data/Context";
import { Buttons } from "../Utils";
import bg1 from "../Assets/Group3610.png";
import image from "../Assets/Group 42903.png";
import { VerifyMail } from "./forget-password";

const Login = () => {
	const {
		loginUser,
		// getSetTempUser,
		auth,
		loginUser2FA,
	} = useContext(GlobalState);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	let [typePass, setTypePass] = useState(false),
		init = {
			email: "",
			password: "",
		},
		[stateData, setStateData] = useState(init),
		[loading, setLoading] = useState(false),
		[submit, setSubmit] = useState(false),
		[step, setStep] = useState(1),
		[code, setCode] = useState(""),
		navigate = useNavigate(),
		textChange =
			name =>
			({ target: { value } }) => {
				setStateData({ ...stateData, [name]: value });
			};

	let handleSubmit = async e => {
			e?.preventDefault();
			if (!stateData?.password || !stateData?.email) return;
			setLoading(true);
			await loginUser(stateData);
			// getSetTempUser("auth");
			setLoading(false);
			setSubmit(true);
		},
		handleSubmit2 = async e => {
			e?.preventDefault();
			if (!code) return;
			setLoading(true);
			await loginUser2FA({ token: code });
			// getSetTempUser("auth");
			setLoading(false);
			setSubmit(true);
		};

	useEffect(() => {
		if (submit && auth?.isLoggedIn) {
			setSubmit(false);
			navigate("/");
		}
		if (submit && auth?.is2FA) {
			setSubmit(false);
			setStep(2);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [submit, auth?.isLoggedIn]);

	return (
		<>
			<div className="tw-w-full tw-h-screen">
				<div className="container tw-flex tw-justify-center tw-h-full tw-items-center">
					<div className="tw-bg-white lg:tw-w-[50%] tw-w-5/6 tw-shadow-xl tw-p-10">
						<h3 className="text-capitalize tw-font-bold tw-text-2xl tw-text-[#1b1b1b] Lexend">
							Welcome back
						</h3>
						<small className="mb-4 tw-text-base tw-font-medium d-block">
							{step !== 1
								? `Please input the token in your Authenticator App`
								: `Welcome back! Please enter your details.`}
						</small>
						{step !== 1 ? (
							<Enable2FAComponent
								handleSubmit={handleSubmit2}
								loading={loading}
								code={code}
								setCode={setCode}
								subtext={"Enter code generated from your Authenticator App"}
							/>
						) : (
							<>
								<form onSubmit={handleSubmit}>
									<label htmlFor="email">Email</label>
									<div className="mb-3">
										<input
											type="email"
											required
											name="email"
											className="form-control py-3"
											value={stateData.email}
											onChange={textChange("email")}
											// onPaste={e => e?.preventDefault()}
										/>
									</div>
									<label htmlFor="Password">Password</label>
									<div className="mb-5 show-hide position-relative">
										<input
											type={typePass ? "text" : "password"}
											required
											name="password"
											className="form-control py-3"
											value={stateData.password}
											onChange={textChange("password")}
										/>
										<span className="" onClick={() => setTypePass(!typePass)}>
											{!typePass ? <BsEye /> : <BsEyeSlash />}
										</span>
									</div>
									<p className="my-4 Lexend">
										Forgot my login details{" "}
										<Link
											to={`/forget-password`}
											className="text-decoration-none textColor2 fw-600">
											Get help signing in
										</Link>{" "}
									</p>
									<Buttons
										onClick={handleSubmit}
										loading={loading}
										title={"sign in"}
										css="btn btn-primary1 text-capitalize py-3 w-100 my-4"
									/>
								</form>
								<div className="my-3 d-flex align-items-center justify-content-between">
									<Link
										to={`/activate`}
										className="btn text-capitalize Lexend fw-600">
										verify mail
									</Link>{" "}
								</div>
							</>
						)}
					</div>
					{/* <div className="tw-h-full bg-select-blue tw-w-[50%]">
            <img src={image} alt="Banner" className="" />
          </div> */}
				</div>
			</div>
		</>
	);
};

export default Login;

export const DefaultAuthComponent = ({ children }) => {
	return (
		<>
			<div className="bg-white">
				<div className="row mx-0">
					<Container
						className="heroBg col-md-7"
						style={{
							background: `url(${bg1})`,
						}}>
						<section className="d-flex justify-content-center align-items-center fullscreen2 py-3 py-md-5">
							<div
								className="m-auto px-3 py-5 rounded w-100"
								style={{
									maxWidth: "550px",
								}}>
								<Container className="px-lg-5 px-3">{children}</Container>
							</div>
						</section>
					</Container>
					<div className="col-lg-5 d-none d-lg-flex fullscreen2 ">
						<img
							src={image}
							alt="Banner"
							className="img-fluid m-auto h-100 imgFluid"
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export const Enable2FAComponent = ({
	handleSubmit,
	code,
	setCode,
	loading,
	subtext,
}) => {
	return (
		<>
			<h3 className="text-capitalize">OTP</h3>
			<form onSubmit={handleSubmit}>
				<VerifyMail
					code={code}
					setCode={setCode}
					text="confirm Token"
					numInputs={6}
					subtext={subtext}
					isInputSecure
				/>
				<Buttons
					onClick={handleSubmit}
					loading={loading}
					css="btn btn-primary1 text-capitalize py-3 w-100 my-4"
					title="confirm Token"
					type="button"
				/>
			</form>
		</>
	);
};
