import axios from "axios";

export const SetAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = token;
    axios.defaults.headers.common["frontend-source"] = "admin";
  } else {
    delete axios.defaults.headers.common["Authorization"];
    delete axios.defaults.headers.common["frontend-source"];
  }
};

const env = process.env.REACT_APP_ENV;

export const useURL =
  env === "PROD"
    ? process.env.REACT_APP_PROD_BASE_URL
    : env === "TEST"
    ? process.env.REACT_APP_TEST_BASE_URL
    : process.env.REACT_APP_DEV_BASE_URL;

// export const useURL = process.env.REACT_APP_BASE_URL;
// // export const useURL =
// // 	process.env.NODE_ENV === "development"
// // 		? "http://localhost:9090"
// // 		: process.env.REACT_APP_BASE_URL;

export const useURL2 =
  env === "PROD"
    ? process.env.REACT_APP_PROD_SUPPORT_BASE_URL
    : env === "TEST"
    ? process.env.REACT_APP_TEST_SUPPORT_BASE_URL
    : process.env.REACT_APP_DEV_SUPPORT_BASE_URL;

// export const useURL2 =
//   // process.env.NODE_ENV === "development"
//   // 	? "http://localhost:9090"
//   // 	: // : process.env.REACT_APP_BASE_URL;
//   process.env.REACT_APP_SUPPORT_BASE_URL;

export const useURL3 =
  env === "PROD"
    ? process.env.REACT_APP_PROD_SUPPORT_BASE_URL_TWO
    : env === "TEST"
    ? process.env.REACT_APP_TEST_SUPPORT_BASE_URL_TWO
    : process.env.REACT_APP_DEV_SUPPORT_BASE_URL_TWO;
// export const useURL3 =
//   // process.env.NODE_ENV === "development"
//   // 	? "http://localhost:9090"
//   // 	: // : process.env.REACT_APP_BASE_URL;
//   process.env.REACT_APP_SUPPORT_BASE_URL_TWO;

export const useURL4 =
  env === "PROD"
    ? process.env.REACT_APP_PROD_SUPPORT_BASE_URL_THREE
    : env === "TEST"
    ? process.env.REACT_APP_TEST_SUPPORT_BASE_URL_THREE
    : process.env.REACT_APP_DEV_SUPPORT_BASE_URL_THREE;
// export const useURL4 =
//   // process.env.NODE_ENV === "development"
//   // 	? "http://localhost:9090"
//   // 	: // : process.env.REACT_APP_BASE_URL;
//   process.env.REACT_APP_SUPPORT_BASE_URL_THREE;

export const useURL5 =
  env === "PROD"
    ? process.env.REACT_APP_PROD_SUPPORT_BASE_URL_FOUR
    : env === "TEST"
    ? process.env.REACT_APP_TEST_SUPPORT_BASE_URL_FOUR
    : process.env.REACT_APP_DEV_SUPPORT_BASE_URL_FOUR;
// export const useURL5 =
//   // process.env.NODE_ENV === "development"
//   // 	? "http://localhost:9090"
//   // 	: // : process.env.REACT_APP_BASE_URL;
//   process.env.REACT_APP_SUPPORT_BASE_URL_FOUR;

export const useURL6 =
  env === "PROD"
    ? process.env.REACT_APP_PROD_SUPPORT_BASE_URL_FIVE
    : env === "TEST"
    ? process.env.REACT_APP_TEST_SUPPORT_BASE_URL_FIVE
    : process.env.REACT_APP_DEV_SUPPORT_BASE_URL_FIVE;
// export const useURL6 =
//   // process.env.NODE_ENV === "development"
//   // 	? "http://localhost:9090"
//   // 	: // : process.env.REACT_APP_BASE_URL;
//   process.env.REACT_APP_SUPPORT_BASE_URL_FIVE;

export const useURL7 =
  env === "PROD"
    ? process.env.REACT_APP_PROD_SUPPORT_BASE_URL_SIX
    : env === "TEST"
    ? process.env.REACT_APP_TEST_SUPPORT_BASE_URL_SIX
    : process.env.REACT_APP_DEV_SUPPORT_BASE_URL_SIX;
// export const useURL7 =
//   // process.env.NODE_ENV === "development"
//   // 	? "http://localhost:9090"
//   // 	: // : process.env.REACT_APP_BASE_URL;
//   process.env.REACT_APP_SUPPORT_BASE_URL_SIX;

export const useURL8 =
  env === "PROD"
    ? process.env.REACT_APP_PROD_SUPPORT_BASE_URL_SEVEN
    : env === "TEST"
    ? process.env.REACT_APP_TEST_SUPPORT_BASE_URL_SEVEN
    : process.env.REACT_APP_DEV_SUPPORT_BASE_URL_SEVEN;
// export const useURL8 =
//   // process.env.NODE_ENV === "development"
//   // 	? "http://localhost:9090"
//   // 	: // : process.env.REACT_APP_BASE_URL;
//   process.env.REACT_APP_SUPPORT_BASE_URL_SEVEN;

export const useURL9 =
  env === "PROD"
    ? process.env.REACT_APP_PROD_SUPPORT_BASE_URL_EIGHT
    : env === "TEST"
    ? process.env.REACT_APP_TEST_SUPPORT_BASE_URL_EIGHT
    : process.env.REACT_APP_DEV_SUPPORT_BASE_URL_EIGHT;
// export const useURL9 =
//   // process.env.NODE_ENV === "development"
//   // 	? "http://localhost:9090"
//   // 	: // : process.env.REACT_APP_BASE_URL;
//   process.env.REACT_APP_SUPPORT_BASE_URL_EIGHT;

export const SetDefaultHeaders = () => {
  axios.defaults.baseURL = useURL;
  axios.defaults.headers.common["frontend-source"] = "admin";
};
