import React, { useContext, useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { GlobalState } from "../../../Data/Context";
import { Container } from "reactstrap";
import { EmptyComponent, Loader } from "../../../Utils";
import moment from "moment";
import { WalletDetails } from "../../../Components/Wallets";
import { MainPaginate, MainRanger } from "../../../Components/Transactions";
import { ExportButtons2 } from "../../../Utils/Export Functions/ExportButtons";

const UserWallet = () => {
  const { allUsers, setStateName, manageUserActiveness, getReload } =
      useContext(GlobalState),
    [state, setState] = useState(null),
    params = useParams(),
    [getSearch] = useSearchParams(),
    [search, setSearch] = useState("");

  useEffect(() => {
    setStateName(
      `${
        getSearch?.get("name")
          ? getSearch?.get("name")?.split("_")?.join(" ")
          : ""
      }'s wallet history`
    );
    getReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let getUserDetails = async () => {
      await manageUserActiveness(params?.step, "wallet", "", "get");
    };
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.step]);

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await manageUserActiveness(
          params?.step,
          "wallet",
          "",
          "get",
          null,
          search
        );
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (allUsers.user_isFound) {
      setState(allUsers.user_mainSearch);
    } else if (allUsers?.isFoundWallet) setState(allUsers?.walletHistory);
  }, [
    allUsers?.walletHistory,
    params?.step,
    allUsers?.isFoundWallet,
    allUsers?.user_isFound,
    allUsers?.user_mainSearch,
  ]);

  if (!state) return;

  return (
    <div className="py-4 bg-white aboutScreen">
      {allUsers?.isFoundWalletLoading ? (
        <Loader />
      ) : (
        <Container className="py-5">
          <div className="w-50 w50 mb-3">
            <input
              type="search"
              name="search"
              id="Search"
              className="form-control w-100 py-3 borderColor2"
              placeholder="Type here to search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <WalletHistoryList state={state} />
        </Container>
      )}
    </div>
  );
};

export default UserWallet;

export const WalletHistoryList = ({ state }) => {
  let [thisData, setThisData] = useState(null);
  return (
    <>
      <div className="row mx-0 my-2 py-3 bland">
        <div className="col my-auto text-uppercase fw-bold Lexend d-none d-md-flex fontReduce textTrunc">
          ID
        </div>
        <div className="col my-auto text-uppercase fw-bold Lexend fontReduce textTrunc">
          description
        </div>
        <div className="col my-auto text-uppercase fw-bold Lexend fontReduce textTrunc">
          amount
        </div>
        <div className="col my-auto text-uppercase fw-bold Lexend fontReduce textTrunc">
          previous amount
        </div>
        <div className="col my-auto text-uppercase fw-bold Lexend fontReduce textTrunc">
          balance
        </div>
        <div className="col my-auto text-uppercase fw-bold Lexend d-none d-md-flex fontReduce textTrunc">
          date&time
        </div>
      </div>
      <NewWalletPaginate state={state} setThisData={setThisData} />
      <WalletDetails setThisData={setThisData} thisData={thisData} />
    </>
  );
};

let WalletDeals = ({ state, setThisData }) => {
  let { nairaSign, numberWithCommas } = useContext(GlobalState);
  return (
    <>
      {state?.length === 0 ? (
        <EmptyComponent subtitle={"User wallet history is empty"} />
      ) : (
        state?.map((it, i) => (
          <div
            key={i}
            className="row mx-0 py-3 bland2 myCursor border-bottom"
            onClick={() => setThisData(it)}
          >
            <div className="col my-auto text-capitalize d-none d-md-flex fontReduce2 textTrunc">
              {it?.item_id}
            </div>
            <div className="col my-auto text-capitalize textTrunc textTrunc2 fontReduce2">
              {it?.description}
            </div>
            <div className="col my-auto fontReduce2 textTrunc">
              {nairaSign}{" "}
              {it?.amount ? numberWithCommas(Number(it?.amount).toFixed(2)) : 0}
            </div>
            <div className="col my-auto fontReduce2 textTrunc">
              {nairaSign}{" "}
              {it?.prevBalance
                ? numberWithCommas(Number(it?.prevBalance).toFixed(2))
                : 0}
            </div>
            <div className="col my-auto fontReduce2 textTrunc">
              {nairaSign}{" "}
              {it?.balance
                ? numberWithCommas(Number(it?.balance).toFixed(2))
                : 0}
            </div>
            <div className="col my-auto d-none d-md-flex fontReduce2 textTrunc">
              {moment(it?.createdAt).format("DD/MM/YYYY HH:mm A")}
            </div>
          </div>
        ))
      )}
    </>
  );
};

export let NewWalletPaginate = ({ state, setThisData }) => {
  let { numberWithCommas } = useContext(GlobalState);
  let [range, setRange] = useState(10);
  const params = useParams();

  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + range;

  const currentItems = state.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(state.length / range);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * range) % state.length;
    setItemOffset(newOffset);
  };

  const headers = [
    { title: "ID", field: "id" },
    { title: "DESCRIPTION", field: "description" },
    { title: "AMOUNT", field: "amount" },
    { title: "PREVIOUS AMOUNT", field: "previous_amount" },
    { title: "BALANCE", field: "balance" },
    { title: "DATE & TIME", field: "createdAt" },
  ];

  const data = currentItems.map((it, index) => ({
    id: `${it?.item_id}`,
    description: `${it?.description}`,
    amount: it?.amount
      ? `₦ ${numberWithCommas(Number(it?.amount).toFixed(2))}`
      : "₦ 0",
    previous_amount: it?.prevBalance
      ? `₦ ${numberWithCommas(Number(it?.prevBalance).toFixed(2))}`
      : "₦ 0",
    balance: it?.balance
      ? `₦ ${numberWithCommas(Number(it?.balance).toFixed(2))}`
      : "₦ 0",
    createdAt: moment(it?.createdAt).format("DD/MM/YYYY hh:mm A"),
  }));

  return (
    <>
      <div className="tw-flex tw-justify-between tw-items-center">
        <div className="tw-flex-1">
          <MainRanger range={range} setRange={setRange} />
        </div>
        <ExportButtons2
          headers={headers}
          data={data}
          fileName={`${params?.page} ${params?.id ? `(${params?.id})` : ""}`}
        />
      </div>
      <WalletDeals state={currentItems} setThisData={setThisData} />
      <MainPaginate handlePageClick={handlePageClick} pageCount={pageCount} />
    </>
  );
};
