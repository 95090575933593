import React, { useState, useContext, useEffect } from "react";
import { Container } from "reactstrap";
import { Buttons } from "../../Utils";
import { ModalComponents } from "..";
import { GlobalState } from "../../Data/Context";
import LoadMore, { BottomTab } from "../LoadMore";
import {
  TransactionDetails,
  TransactionToppers,
  NewPaginate,
} from "../Transactions";
import { AirtimeModalOption } from "./AutoBuy";

const Airtime = () => {
  let [isOpen, setIsOpen] = useState(false),
    toggle = () => {
      setIsOpen(!isOpen);
    };

  let { setStateName, airtimes } = useContext(GlobalState);
  useEffect(() => {
    setStateName("airtime history");
    setIsOpen(true);
    return () => setIsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let [submit, setSubmit] = useState(false),
    [active, setActive] = useState(0);

  useEffect(() => {
    if (airtimes?.isAdded && submit) {
      setIsOpen(false);
      setSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [airtimes?.isAdded, submit]);

  return (
    <div className="bg-white aboutScreen">
      <Container className="py-5">
        <Buttons
          title={"buy airtime"}
          css="btn-primary1 text-capitalize py-3 px-4 px-lg-5"
          width={"w-25 w25"}
          onClick={toggle}
          style={{ borderRadius: "30px" }}
        />
        <TransactionToppers
          title={"airtime"}
          active={active}
          setActive={setActive}
        />
        {active === 1 ? <AllAirtimeHistory /> : <AirtimeHistory />}
      </Container>
      <ModalComponents title="buy airtime" isOpen={isOpen} toggle={toggle}>
        <AirtimeModalOption setSubmit={setSubmit} />
      </ModalComponents>
    </div>
  );
};

export default Airtime;

const AirtimeHistory = () => {
  let { airtimes, getServicesHistory, getReload } = useContext(GlobalState);

  let [data, setData] = useState(null),
    [thisData, setThisData] = useState(null);
  let [loading, setLoading] = useState(false),
    [search, setSearch] = useState("");

  useEffect(() => {
    getServicesHistory("airtime");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await getServicesHistory("airtime", {
          search,
        });
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (airtimes.isFound) {
      setData(airtimes.mainSearch);
    } else setData(airtimes.airtime);
  }, [airtimes.airtime, airtimes.isFound, airtimes.mainSearch]);

  useEffect(() => {
    getReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let handleLoadMore = async () => {
    setLoading(true);

    await getServicesHistory("airtime", {
      page: Number(airtimes?.paginate?.nextPage),
      // limit: Number(airtimes?.paginate?.nextPage * airtimes?.paginate?.limit),
      search,
    });

    setLoading(false);
  };

  if (!data) return;
  // console.log({ data });

  return (
    <div className="pb-5 my-5">
      <div className="w-50 w50 mb-3">
        <input
          type="search"
          name="search"
          id="Search"
          className="form-control w-100 py-3 borderColor2"
          placeholder="Type here to search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <NewPaginate
        state={data}
        setState={setData}
        setThisData={setThisData}
        type={"airtime"}
        criteria={
          {
            // id: params?.step,
          }
        }
        my="myall"
      />
      <TransactionDetails
        thisData={thisData}
        setThisData={setThisData}
        type={"airtime"}
        criteria={
          {
            // id: params?.step,
          }
        }
      />
      <BottomTab
        state={data}
        paginate={search ? airtimes?.search_paginate : airtimes?.paginate}
      />
      <LoadMore
        next={
          search ? airtimes?.search_paginate?.next : airtimes?.paginate?.next
        }
        handleLoadMore={handleLoadMore}
        loading={loading}
      />
    </div>
  );
};

const AllAirtimeHistory = () => {
  let { airtimes, getAllServicesHistory, getReload } = useContext(GlobalState);

  let [data, setData] = useState(null),
    [thisData, setThisData] = useState(null);
  let [loading, setLoading] = useState(false),
    [search, setSearch] = useState("");

  useEffect(() => {
    getAllServicesHistory("airtime");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await getAllServicesHistory("airtime", {
          search,
        });
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (airtimes.all_isFound) {
      setData(airtimes.all_mainSearch);
    } else setData(airtimes.all_airtime);
  }, [airtimes.all_airtime, airtimes.all_isFound, airtimes.all_mainSearch]);

  useEffect(() => {
    getReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let handleLoadMore = async () => {
    setLoading(true);

    await getAllServicesHistory("airtime", {
      page: Number(airtimes?.all_paginate?.nextPage),
      // limit: Number(
      // 	airtimes?.all_paginate?.nextPage * airtimes?.all_paginate?.limit
      // ),
      search,
    });

    setLoading(false);
  };

  if (!data) return;
  // console.log({ data });

  return (
    <div className="pb-5 my-5">
      <div className="w-50 w50 mb-3">
        <input
          type="search"
          name="search"
          id="Search"
          className="form-control w-100 py-3 borderColor2"
          placeholder="Type here to search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <NewPaginate
        state={data}
        setState={setData}
        setThisData={setThisData}
        type={"airtime"}
        criteria={
          {
            // id: params?.step,
          }
        }
      />
      <TransactionDetails
        thisData={thisData}
        setThisData={setThisData}
        type={"airtime"}
        criteria={
          {
            // id: params?.step,
          }
        }
      />
      <BottomTab
        state={data}
        paginate={
          search ? airtimes?.all_search_paginate : airtimes?.all_paginate
        }
      />
      <LoadMore
        next={
          search
            ? airtimes?.all_search_paginate?.next
            : airtimes?.all_paginate?.next
        }
        handleLoadMore={handleLoadMore}
        loading={loading}
      />
    </div>
  );
};

export const NetworkList = ({ state, setState }) => {
  let { network } = useContext(GlobalState);
  return (
    <div className="row mx-0">
      {network?.data?.map((item, i) => (
        <div
          className="col p-2"
          onClick={setState ? () => setState(item?.name) : () => {}}
          key={i}
        >
          <div
            style={{
              height: "3rem",
              width: "3rem",
            }}
            className={`rounded d-flex myCursor ${
              state === item?.name
                ? "borderColor borderColor2 list-group-item-primary"
                : ""
            }`}
          >
            <img
              src={item?.image?.url}
              alt={item?.image?.name}
              className="img-fluid imgFluid h-75 m-auto"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export const NetworkList2 = ({ state, style }) => {
  let { network } = useContext(GlobalState);
  return (
    <div
      style={
        style || {
          height: "5rem",
          width: "5rem",
        }
      }
      className={`rounded d-flex`}
    >
      <img
        src={network?.data?.find?.((item) => state === item?.name)?.image?.url}
        alt={network?.data?.find?.((item) => state === item?.name)?.image?.name}
        className="img-fluid imgFluid h-75 w-75 m-auto"
      />
    </div>
  );
};

export const DataNetworkList = ({ state, setState, filter }) => {
  let { data } = useContext(GlobalState),
    [thisData, setThisData] = useState([
      ...new Set(data?.dataToBuy?.map((item) => item?.category?.categoryId)),
    ]);

  useEffect(() => {
    if (filter) {
      setThisData([
        ...new Set(
          data?.dataToBuy
            ?.filter((item) =>
              item?.category?.categoryId?.toLowerCase()?.includes(filter)
            )
            ?.map((item) => item?.category?.categoryId)
        ),
      ]);
    }
  }, [filter, data?.dataToBuy]);

  return (
    <div className="row mx-0">
      {thisData
        ?.sort()
        ?.reverse()
        ?.map((item, i) => (
          <DataLister setState={setState} state={state} item={item} key={i} />
        ))}
    </div>
  );
};

let DataLister = ({ item, setState, state }) => {
  let { network } = useContext(GlobalState),
    [list, setList] = useState(null);

  useEffect(() => {
    setList(
      network?.data?.find((i) =>
        item?.toLowerCase()?.includes(i?.name?.toLowerCase())
      )
    );
  }, [item, network]);

  if (!list) return;

  return (
    <div
      className="col-3 p-2 tw-flex tw-flex-col tw-justify-center"
      onClick={() => setState(item)}
    >
      <div
        style={{
          height: "3.5rem",
          width: "3.5rem",
        }}
        className={`rounded d-flex myCursor justify-content-center tw-mx-auto ${
          state === item
            ? "borderColor borderColor2 list-group-item-primary"
            : ""
        }`}
      >
        <img
          src={list?.image?.url}
          alt={list?.image?.name}
          className="img-fluid imgFluid h-75 w-75 m-auto"
        />
      </div>
      {item && (
        <small className="Lexend text-uppercase text-center">
          {item?.includes("_") ? item?.slice(item?.indexOf("_") + 1) : item}
        </small>
      )}
    </div>
  );
};

export let TransactionImage = ({ item, setState, state, value, type }) => {
  let { network, electricity, cables, education, usecase } =
      useContext(GlobalState),
    [list, setList] = useState(null);

  useEffect(() => {
    if (type && item) {
      let findOne;

      if (["data", "airtime"]?.includes(type)) {
        findOne = network?.data?.find((i) =>
          item?.toLowerCase()?.includes(i?.name?.toLowerCase())
        );
      }
      if (["cables"]?.includes(type)) {
        findOne = cables?.cable_direct?.find((i) =>
          item?.toLowerCase()?.includes(i?.name?.toLowerCase())
        );
      }
      if (["electricity"]?.includes(type)) {
        findOne = electricity?.electricityToBuy?.find(
          (i) => item?.toLowerCase() === i?.disco?.toLowerCase()
        );
      }
      if (["education"]?.includes(type)) {
        findOne = education?.educationToBuy?.find((i) =>
          item?.toLowerCase()?.includes(i?.type?.toLowerCase())
        );
      }
      if (["biz verification"]?.includes(type)) {
        findOne = usecase?.usecase?.bizLogo
          ? { image: usecase?.usecase?.bizLogo }
          : null;
      }
      if (["nin verification"]?.includes(type)) {
        findOne = usecase?.usecase?.verifyLogo
          ? { image: usecase?.usecase?.verifyLogo }
          : null;
      }

      setList(findOne);
    }
  }, [
    item,
    network?.data,
    electricity?.electricityToBuy,
    cables?.cable_direct,
    education?.educationToBuy,
    usecase?.usecase,
    type,
  ]);

  return (
    <div
      className="d-flex align-items-center p-2"
      onClick={setState ? () => setState(item) : null}
    >
      {list && (
        <div
          style={{
            height: "3.5rem",
            width: "3.5rem",
          }}
          className={`rounded d-flex ${
            state === item
              ? "borderColor borderColor2 list-group-item-primary"
              : ""
          }`}
        >
          <img
            src={list?.image?.url}
            alt={list?.image?.name}
            className="img-fluid imgFluid h-75 w-75 m-auto"
          />
        </div>
      )}
      {value || !list ? (
        <div className="Lexend text-uppercase">
          {value
            ? value?.includes("_")
              ? value?.slice(value?.indexOf("_") + 1)
              : value
            : item}
        </div>
      ) : null}
    </div>
  );
};
