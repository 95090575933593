import React, { createContext, useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { BiHomeAlt } from "react-icons/bi";
import { TbActivity } from "react-icons/tb";
import { TbUsers } from "react-icons/tb";
import { VscBellDot } from "react-icons/vsc";
import { AiOutlineSwap } from "react-icons/ai";
import { GiChart, GiWallet } from "react-icons/gi";
import { IoCardOutline } from "react-icons/io5";
import { FaQuestionCircle } from "react-icons/fa";
import { FiSliders } from "react-icons/fi";
import { MdContentPaste } from "react-icons/md";
import { CgController } from "react-icons/cg";
import { RiFindReplaceLine, RiAdminFill } from "react-icons/ri";
import moment from "moment";
import {
	loginUser,
	logoutUser,
	updatePassword,
	updateUser,
	loginUser2FA,
} from "./Actions/AuthActions";

import {
	clearErrors,
	restoreMsg,
	returnErrors,
	getPermission,
} from "./Reducer/ErrorReducer";

import {
	getDataBundle,
	manageDataBundle,
	manageElectricityBundle,
	getElectricityBundle,
	manageCables,
	getAutoBuy,
	manageAutoBuy,
	manageEducationBundle,
	getEducationBundle,
	getEducationToBuy,
} from "./Actions/DataActions";

import {
	getReload,
	manageUserActiveness,
	manageUserPrivilege,
	getUserPrivileges,
	getAllUserTransactionAmount,
	getUserWalletTracking,
	getUserKeyPatrons,
} from "./Actions/UserActions";

import { getUseCase, manageUpgrade } from "./Reducer/UseCaseReducer";
import { getSettings } from "./Reducer/SettingsReducer";
import { getActivity } from "./Reducer/BizReducer";
import {
	manageCGWallets,
	manageCGWalletHistory,
	manageCGWalletUserHistory,
} from "./Reducer/CgWalletReducer";

import { manageBlacklist } from "./Reducer/BlacklistReducer";

import {
	getNotificationsTo,
	getNotificationsFrom,
	readNotification,
	sendNotification,
	getNotificationsUser,
	manageSlider,
	manageBanks,
	manageWalletPin,
	manageWithdrawal,
	manageFaqs,
	manageStartimes,
	manageNotify,
	getSlider,
} from "./Actions/NotificationAction";

import {
	buyServices,
	getServicesHistory,
	getAllServicesHistory,
	manageFundWallet,
	getManualBonusHistory,
	getWalletHistory,
	manageWallet,
	manageFundWalletFlutterwave,
	generateVirtual,
	getCards,
	getDataHistory,
	converterServices,
	manageFundWalletPaystack,
	manageTransaction,
	manualTransactions,
	manualDirectDebit,
	getServicesHistoryPending,
	getReferrals,
	getWalletBalance,
	getWalletStat,
	getWalletStatMonthDay,
} from "./Actions/GeneralAction";
import {
	socketProfile,
	socketOnline,
	socketTransaction,
} from "./Reducer/SocketReducer";

import {
	getCategory,
	manageCategory,
	getBiller,
	manageBiller,
	getProducts,
	manageProducts,
	toggleAvailabilty,
	manageNetwork,
	findProviderStat,
	getBillerFunding,
	getChoosenServices,
	getRpiData,
	getOgdams,
	getRingo,
	getMcd,
	getHonourworld,
	getDataTransactionStat,
	getProviderStat,
	getUzobestData,
	findProviderFunding,
	getBillerData,
	getGateway,
	manageGateway,
} from "./Actions/ProviderAction";

export const GlobalState = createContext();

const DataProvider = ({
	children,
	clearErrors,
	restoreMsg,
	returnErrors,
	loginUser,
	loginUser2FA,
	logoutUser,
	manageUserActiveness,
	updatePassword,
	updateUser,
	getDataBundle,
	manageDataBundle,
	getCategory,
	manageCategory,
	getBiller,
	manageBiller,
	getProducts,
	manageProducts,
	toggleAvailabilty,
	manageSlider,
	getReload,
	manageUserPrivilege,
	getUserPrivileges,
	buyServices,
	socketProfile,
	socketOnline,
	manageElectricityBundle,
	getElectricityBundle,
	manageCables,
	manageNetwork,
	findProviderStat,
	getBillerFunding,
	converterServices,
	getUseCase,
	getSettings,
	getNotificationsTo,
	getNotificationsFrom,
	readNotification,
	sendNotification,
	getNotificationsUser,
	socketTransaction,
	getServicesHistory,
	getAllServicesHistory,
	manageFundWallet,
	getManualBonusHistory,
	getWalletHistory,
	manageWallet,
	manageFundWalletFlutterwave,
	generateVirtual,
	getCards,
	getDataHistory,
	manageFundWalletPaystack,
	getChoosenServices,
	getRpiData,
	getOgdams,
	getRingo,
	getMcd,
	getHonourworld,
	getDataTransactionStat,
	getProviderStat,
	manageTransaction,
	manualTransactions,
	manualDirectDebit,
	getAutoBuy,
	manageAutoBuy,
	getActivity,
	getServicesHistoryPending,
	getUzobestData,
	manageEducationBundle,
	getEducationBundle,
	getEducationToBuy,
	manageBanks,
	manageWalletPin,
	manageWithdrawal,
	getReferrals,
	manageFaqs,
	manageStartimes,
	findProviderFunding,
	manageUpgrade,
	manageNotify,
	getBillerData,
	manageCGWallets,
	manageCGWalletHistory,
	getSlider,
	getPermission,
	manageCGWalletUserHistory,
	getGateway,
	manageGateway,
	getAllUserTransactionAmount,
	getUserWalletTracking,
	getWalletBalance,
	getWalletStatMonthDay,
	getWalletStat,
	getUserKeyPatrons,
	manageBlacklist,
}) => {
	const {
		auth,
		errors,
		settings,
		notifications,
		success,
		sliders,
		employees,
		admins,
		activesuspended,
		chosen,
		rpi,
		ringo,
		ogdams,
		dataBundle,
		category,
		biller,
		products,
		data,
		usecase,
		upgrade,
		network,
		transactions,
		wallet,
		user,
		superadmin,
		manager,
		accountant,
		marketer,
		developer,
		reseller,
		agent,
		topuser,
		allUsers,
		bonus,
		commission,
		airtimes,
		converter,
		socket,
		electricity,
		electricityBundle,
		biz,
		mcd,
		cables,
		cablesBundle,
		activity,
		stat,
		auto_buy,
		uzobest,
		honourworld,
		pendings,
		educationBundle,
		education,
		withdrawal,
		referral,
		faqs,
		startimes,
		funding,
		cgwallet,
		cgwalletHistory,
		permission,
		cgwalletUser,
		gateway,
		trackingWallet,
		keyPatron,
		blacklist,
		verify,
	} = useSelector(state => state);

	let [stateName, setStateName] = useState("");

	let numberWithCommas = (x, a) => {
		return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, a ? a : ",") : x;
	};

	let nairaSign = <span className="fontInherit">&#8358;</span>;
	let nairaSignNeutral = "₦",
		[generalYears, setGeneralYears] = useState([2023]);

	useEffect(() => {
		let startYear = 2023,
			endYear = Number(moment().format("YYYY")),
			allYears = [];
		for (let y = startYear; y <= endYear; y++) {
			allYears?.push(y);
		}
		setGeneralYears(allYears);
	}, []);

	let sidebarList = [
		{
			name: "Dashboard",
			url: "/dashboard",
			icon: <BiHomeAlt className="icon" size={24} />,
		},
		{
			name: "Users",
			url: "/users",
			icon: <TbUsers className="icon" size={24} />,
		},
		{
			name: "Transactions",
			url: "/transactions",
			icon: <GiChart className="icon" size={24} />,
		},
		{
			name: "Products",
			url: "/products",
			icon: <IoCardOutline className="icon" size={24} />,
		},
		{
			name: "Converter",
			url: "/converter",
			icon: <AiOutlineSwap className="icon" size={24} />,
		},
		{
			name: "Notification",
			url: "/notifications",
			icon: <VscBellDot className="icon" size={24} />,
		},
		{
			name: "Wallet",
			url: "/wallets",
			icon: <GiWallet className="icon" size={24} />,
		},
		{
			name: "Employee",
			url: "/administration",
			icon: <RiAdminFill className="icon" size={24} />,
		},
		{
			name: "Switcher",
			url: "/switchers",
			icon: <RiFindReplaceLine className="icon" size={24} />,
		},
		// {
		// 	name: "Reports",
		// 	url: "/reports",
		// 	icon: <CiTimer className="icon" size={24} />,
		// },
		// {
		// 	name: "Bundles",
		// 	url: "/bundles",
		// 	icon: <BsBoxSeam className="icon" size={24} />,
		// },
		{
			name: "Slider",
			url: "/sliders",
			icon: <FiSliders className="icon" size={24} />,
		},
		{
			name: "Faqs",
			url: "/faqs",
			icon: <FaQuestionCircle className="icon" size={24} />,
		},
		{
			name: "Control",
			url: "/controls",
			icon: <CgController className="icon" size={24} />,
		},
		{
			name: "Inventory",
			url: "/inventory",
			icon: <MdContentPaste className="icon" size={24} />,
		},
		{
			name: "Activity",
			url: "/activities",
			icon: <TbActivity className="icon" size={24} />,
		},
	];

	const state = {
		numberWithCommas,
		sidebarList,
		stateName,
		setStateName,
		nairaSign,
		nairaSignNeutral,

		auth,
		loginUser,
		loginUser2FA,
		logoutUser,

		errors,
		clearErrors,

		settings,
		updateUser,

		notifications,

		success,
		restoreMsg,
		returnErrors,

		sliders,
		manageSlider,

		employees,
		admins,
		activesuspended,
		chosen,
		rpi,
		ringo,
		ogdams,
		updatePassword,
		dataBundle,
		getDataBundle,
		manageDataBundle,

		category,
		getCategory,
		manageCategory,

		biller,
		getBiller,
		manageBiller,

		products,
		getProducts,
		manageProducts,
		toggleAvailabilty,

		data,
		usecase,
		upgrade,
		network,
		transactions,
		wallet,

		user,
		superadmin,
		manager,
		accountant,
		marketer,
		developer,
		reseller,
		agent,
		topuser,

		getReload,
		allUsers,
		manageUserActiveness,
		manageUserPrivilege,
		getUserPrivileges,
		buyServices,

		bonus,
		commission,
		airtimes,
		converter,

		socket,
		socketProfile,
		socketOnline,

		electricity,
		electricityBundle,
		manageElectricityBundle,
		getElectricityBundle,
		manageCables,
		manageNetwork,
		findProviderStat,
		getBillerFunding,
		converterServices,
		biz,
		mcd,

		cables,
		getUseCase,
		getSettings,
		cablesBundle,
		activity,

		getNotificationsTo,
		getNotificationsFrom,
		readNotification,
		sendNotification,
		getNotificationsUser,
		socketTransaction,

		stat,
		getServicesHistory,
		getAllServicesHistory,
		manageFundWallet,
		getManualBonusHistory,
		getWalletHistory,
		manageWallet,
		manageFundWalletFlutterwave,
		generateVirtual,
		getCards,
		getDataHistory,
		manageFundWalletPaystack,

		getChoosenServices,
		getRpiData,
		getOgdams,
		getRingo,
		getMcd,
		getHonourworld,
		getDataTransactionStat,
		getProviderStat,
		manageTransaction,
		manualTransactions,
		manualDirectDebit,

		auto_buy,
		getAutoBuy,
		manageAutoBuy,
		uzobest,
		getActivity,

		honourworld,
		pendings,
		getServicesHistoryPending,
		getUzobestData,

		education,
		educationBundle,
		manageEducationBundle,
		getEducationBundle,
		getEducationToBuy,

		withdrawal,
		manageBanks,
		manageWalletPin,
		manageWithdrawal,
		referral,
		getReferrals,
		faqs,
		startimes,
		manageFaqs,
		manageStartimes,
		funding,
		findProviderFunding,
		manageUpgrade,
		manageNotify,
		getBillerData,
		manageCGWallets,
		cgwallet,
		manageCGWalletHistory,
		cgwalletHistory,
		getSlider,
		permission,
		getPermission,
		cgwalletUser,
		manageCGWalletUserHistory,
		gateway,
		getGateway,
		manageGateway,
		getAllUserTransactionAmount,
		trackingWallet,
		getUserWalletTracking,
		getWalletBalance,
		getWalletStat,
		getWalletStatMonthDay,
		generalYears,

		getUserKeyPatrons,
		keyPatron,
		manageBlacklist,
		blacklist,
		verify,
	};

	return <GlobalState.Provider value={state}>{children}</GlobalState.Provider>;
};

export default connect(null, {
	loginUser,
	loginUser2FA,
	clearErrors,
	logoutUser,
	updateUser,
	restoreMsg,
	returnErrors,
	updatePassword,
	getDataBundle,
	manageDataBundle,
	getCategory,
	manageCategory,
	getBiller,
	manageBiller,
	getProducts,
	manageProducts,
	toggleAvailabilty,
	manageSlider,
	getReload,
	manageUserActiveness,
	manageUserPrivilege,
	getUserPrivileges,
	buyServices,
	socketProfile,
	socketOnline,
	manageElectricityBundle,
	getElectricityBundle,
	manageCables,
	manageNetwork,
	findProviderStat,
	getBillerFunding,
	converterServices,
	getUseCase,
	getSettings,
	getNotificationsTo,
	getNotificationsFrom,
	readNotification,
	sendNotification,
	getNotificationsUser,
	socketTransaction,
	getServicesHistory,
	getAllServicesHistory,
	manageFundWallet,
	getManualBonusHistory,
	getWalletHistory,
	manageWallet,
	manageFundWalletFlutterwave,
	generateVirtual,
	getCards,
	getDataHistory,
	manageFundWalletPaystack,
	getChoosenServices,
	getRpiData,
	getOgdams,
	getRingo,
	getMcd,
	getHonourworld,
	getDataTransactionStat,
	getProviderStat,
	manageTransaction,
	manualTransactions,
	manualDirectDebit,
	getAutoBuy,
	manageAutoBuy,
	getActivity,
	getServicesHistoryPending,
	getUzobestData,
	manageEducationBundle,
	getEducationBundle,
	getEducationToBuy,
	manageBanks,
	manageWalletPin,
	manageWithdrawal,
	getReferrals,
	manageFaqs,
	manageStartimes,
	findProviderFunding,
	manageUpgrade,
	manageNotify,
	getBillerData,
	manageCGWallets,
	manageCGWalletHistory,
	getSlider,
	getPermission,
	manageCGWalletUserHistory,
	getGateway,
	manageGateway,
	getAllUserTransactionAmount,
	getUserWalletTracking,
	getWalletBalance,
	getWalletStat,
	getWalletStatMonthDay,
	getUserKeyPatrons,
	manageBlacklist,
})(DataProvider);
