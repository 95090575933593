import {
	ADD_CABLE,
	ADD_CABLES_TYPES,
	ADD_CABLES_TYPES_FAIL,
	ADD_CABLE_FAIL,
	ADD_CABLE_SOCKET,
	DELETE_CABLES_TYPES,
	DELETE_TRANSACTION,
	DELETE_TRANSACTION_FAIL,
	GET_ALL_CABLE,
	GET_CABLE,
	GET_CABLES_TYPES,
	GET_CABLE_DIRECT_PACKAGE,
	GET_CABLE_FAIL,
	GET_CABLE_LOADING,
	LOGOUT,
	SEARCH_ALL_CABLE,
	SEARCH_ALL_CABLE_FAIL,
	SEARCH_ALL_CABLE_LOADING,
	SEARCH_RELOAD,
	SEARCH_CABLE,
	SEARCH_CABLE_FAIL,
	SEARCH_CABLE_LOADING,
	UPDATE_CABLES_TYPES,
	GET_ALL_MY_CABLE,
	GET_ALL_ALL_CABLE,
} from "../Actions/ActionTypes";
import { DeleteData, EditData } from "./AuthReducer";

const initialState = {
	isLoading: false,
	cable: [],
	cable_direct: [],
	cable_package: null,
	isAdded: false,
	isDeleted: false,
	isFound: null,
	searchLoading: null,
	mainSearch: [],
	search: "",
	search_paginate: null,
	all_cable: [],
	all_paginate: null,
	all_isFound: null,
	all_searchLoading: null,
	all_mainSearch: [],
	all_search: "",
	all_search_paginate: null,
};

const CableReducer = (state = initialState, action) => {
	const { type, payload } = action;
	let data = payload?.data ? payload?.data : payload;
	switch (type) {
		case SEARCH_ALL_CABLE:
			return {
				...state,
				all_isFound: true,
				all_searchLoading: false,
				all_mainSearch:
					action?.search === state?.all_search ? data : state?.all_mainSearch,
				all_search_paginate:
					action?.search === state?.all_search
						? payload?.paginate
						: state?.all_search_paginate,
			};
		case SEARCH_ALL_CABLE_FAIL:
			return {
				...state,
				all_searchLoading: false,
				all_mainSearch: state.mainSearch,
				all_search_paginate: state.search_paginate,
				all_isFound: false,
			};
		case SEARCH_ALL_CABLE_LOADING:
			return {
				...state,
				all_search: action.search,
				all_searchLoading: true,
			};
		case SEARCH_RELOAD:
			return {
				...state,
				all_isFound: false,
				all_searchLoading: false,
				isFound: false,
				searchLoading: false,
			};
		case SEARCH_CABLE:
			return {
				...state,
				isFound: true,
				searchLoading: false,
				mainSearch: action?.search === state?.search ? data : state?.mainSearch,
				search_paginate:
					action?.search === state?.search
						? payload?.paginate
						: state?.search_paginate,
			};
		case SEARCH_CABLE_FAIL:
			return {
				...state,
				isFound: false,
				searchLoading: false,
				mainSearch: state.mainSearch,
				search_paginate: state.search_paginate,
			};
		case SEARCH_CABLE_LOADING:
			return {
				...state,
				search: action.search,
				searchLoading: true,
			};
		case ADD_CABLE:
			return {
				...state,
				isAdded: true,
				cable: [data, ...state.cable],
				all_cable: [data, ...state.all_cable],
				paginate: {
					...state?.paginate,
					result: state?.paginate?.result + 1,
					total: state?.paginate?.total + 1,
				},
			};
		case ADD_CABLE_SOCKET:
			return {
				...state,
				all_cable: [data, ...state.all_cable],
			};
		case ADD_CABLE_FAIL:
			return {
				...state,
				isAdded: false,
				isDeleted: false,
			};
		case GET_CABLE:
			return {
				...state,
				isLoading: false,
				cable: data,
				paginate: payload?.paginate,
			};
		case GET_ALL_MY_CABLE:
			return {
				...state,
				myall: payload?.data,
			};
		case GET_ALL_ALL_CABLE:
			return {
				...state,
				all: payload?.data,
			};
		case GET_ALL_CABLE:
			return {
				...state,
				isLoading: false,
				all_cable: data,
				all_paginate: payload?.paginate,
			};
		case DELETE_TRANSACTION:
			return {
				...state,
				mainSearch: DeleteData(state.mainSearch, payload),
				cable: DeleteData(state.cable, payload),
				all_mainSearch: DeleteData(state.all_mainSearch, payload),
				all_cable: DeleteData(state.all_cable, payload),
				isDeleted: true,
			};
		case DELETE_TRANSACTION_FAIL:
			return {
				...state,
				isDeleted: false,
			};
		case GET_CABLE_DIRECT_PACKAGE:
			return {
				...state,
				cable_package: data,
			};
		case GET_CABLES_TYPES:
			return {
				...state,
				cable_direct: data,
			};
		case ADD_CABLES_TYPES:
			return {
				...state,
				cable_direct: [data, ...state.cable_direct],
			};
		case UPDATE_CABLES_TYPES:
			return {
				...state,
				cable_direct: EditData(state?.cable_direct, data),
			};
		case DELETE_CABLES_TYPES:
			return {
				...state,
				cable_direct: DeleteData(state.cable_direct, data),
			};
		case GET_CABLE_FAIL:
			return {
				...state,
				isLoading: false,
			};
		case GET_CABLE_LOADING:
			return {
				...state,
				isLoading: true,
			};
		case LOGOUT:
			return initialState;
		default:
			return state;
	}
};

export default CableReducer;

let initialStateA = {
	data: null,
	isAdded: false,
	isUpdated: false,
	isDeleted: false,
};

export const CablesTypesReducer = (state = initialStateA, action) => {
	let { type, payload } = action;
	let data = payload?.data ? payload?.data : payload;

	switch (type) {
		case GET_CABLES_TYPES:
			return {
				data,
			};
		case ADD_CABLES_TYPES:
			return {
				data: [data, ...state.data],
				isAdded: true,
			};
		case UPDATE_CABLES_TYPES:
			return {
				data: EditData(state?.data, data),
				isUpdated: true,
			};
		case DELETE_CABLES_TYPES:
			return {
				data: DeleteData(state.data, data),
				isDeleted: true,
			};
		case ADD_CABLES_TYPES_FAIL:
			return {
				...state,
				isAdded: false,
				isUpdated: false,
				isDeleted: false,
			};
		case LOGOUT:
			return initialStateA;
		default:
			return state;
	}
};
