import React, { useContext, useEffect, useState } from "react";
import { Container } from "reactstrap";
import image1 from "../../Assets/Other 06.png";
import image2 from "../../Assets/Other 05.png";
import image3 from "../../Assets/Other 08.png";
import image4 from "../../Assets/Other 07.png";
import { GlobalState } from "../../Data/Context";
import { useNavigate, useParams } from "react-router-dom";
import { ModalComponents } from "../../Components";
import { Buttons, EmptyComponent } from "../../Utils";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";
import { NumericFormat } from "react-number-format";

const NavigateElseWhere = ({ uri }) => (
	<div
		className={`rounded10 mt-auto ${uri ? "py-1" : "py-3"}`}
		style={{
			background: "rgba(0,0,0,0.5)",
		}}>
		{uri ? (
			<a
				href={uri}
				target="_blank"
				rel="noopener noreferrer"
				className="text-decoration-none text-white text-capitalize Lexend w-100 h-100 d-block py-3 rounded10">
				view details
			</a>
		) : null}
	</div>
);

const Inventory = () => {
	let {
			setStateName,
			ogdams,
			rpi,
			chosen,
			numberWithCommas,
			ringo,
			mcd,
			nairaSign,
			uzobest,
			honourworld,
			getRingo,
			getOgdams,
			getHonourworld,
			getChoosenServices,
			getRpiData,
			getMcd,
			getUzobestData,
			auth,
			permission,
		} = useContext(GlobalState),
		{ page } = useParams(),
		navigate = useNavigate();

	useEffect(() => {
		if (auth?.user?.privilege !== "superadmin") {
			let findTitle = "viewinventory";

			let findPermit = permission?.data?.find(
				item =>
					item?.slug === findTitle && item?.user?.includes(auth?.user?._id)
			);
			if (!findPermit) {
				navigate(-1);
			}
		}
	}, [auth?.user, permission?.data, navigate]);

	useEffect(() => {
		setStateName("inventory");
		getRingo();
		getOgdams();
		if (process.env.REACT_APP_NAME !== "Honour World") {
			getHonourworld();
		}
		if (process.env.REACT_APP_NAME === "Honour World") {
			getChoosenServices();
			getRpiData();
			getMcd();
			getUzobestData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="bg-white aboutScreen py-5">
			<Container>
				<div className="row mx-0 g-3 g-md-4">
					<div className="px-3 col-12 col-md-4">
						<div
							className="myCursor rounded10 miniHeight d-flex flex-column px-0 text-center"
							style={{
								background: "#BB6BD9",
							}}>
							<div
								onClick={() => {
									navigate(`/${page}/ringo`);
								}}
								className="rounded10 py-3 py-md-4">
								<img
									src={image1}
									alt="Inventory"
									className="mx-auto img-fluid mb-3"
								/>
								<h3>
									<strong className="text-uppercase text2 text2New Lexend">
										Ringo
									</strong>
								</h3>
								<div>
									<p className="text-capitalize text2 text2New d-flex justify-content-between align-items-center px-3">
										<span>Balance: </span>
										<span className="fontInherit Lexend">
											{nairaSign}{" "}
											{ringo?.data?.balance
												? numberWithCommas(
														Number(ringo?.data?.balance)?.toFixed(2)
												  )
												: 0}
										</span>{" "}
									</p>
									<p className="text-capitalize text2 text2New d-flex justify-content-between align-items-center px-3">
										<span>Commission: </span>
										<span className="fontInherit Lexend">
											{nairaSign}{" "}
											{ringo?.data?.commission_balance
												? numberWithCommas(
														Number(ringo?.data?.commission_balance)?.toFixed(2)
												  )
												: 0}
										</span>{" "}
									</p>
									<p className="text-capitalize text2 text2New d-flex justify-content-between align-items-center px-3">
										<span>Bonus: </span>
										<span className="fontInherit Lexend">
											{nairaSign}{" "}
											{ringo?.data?.bonus_balance
												? numberWithCommas(
														Number(ringo?.data?.bonus_balance)?.toFixed(2)
												  )
												: 0}
										</span>{" "}
									</p>
								</div>
								<div>
									{/* <p className="textColor2">{item?.user}</p> */}
									{/* <p className="text2 text2New">{item?.balance}</p> */}
								</div>
							</div>
							<NavigateElseWhere uri={"https://b2b.ringo.ng"} />
						</div>
					</div>
					<div className="px-3 col-12 col-md-4">
						<div
							className="myCursor rounded10 miniHeight d-flex flex-column px-0 text-center"
							style={{
								background: "#66EDE5",
							}}>
							<div
								onClick={() => {
									navigate(`/${page}/ogdams`);
								}}
								className="rounded10 h-100 py-3 py-md-4">
								<img
									src={image4}
									alt="Inventory"
									className="mx-auto img-fluid mb-3"
								/>
								<h3>
									<strong className="text-uppercase text2 text2New Lexend">
										Ogdams
									</strong>
								</h3>
								<div>
									{ogdams?.data &&
										Object?.keys(ogdams?.data)?.map((it, i) => (
											<p
												key={i}
												className="text-capitalize text2 text2New d-flex justify-content-between align-items-center px-3">
												<span className="tw-uppercase">
													{`${it
														?.replace(/([a-z0-9])([A-Z])/g, "$1 $2")
														?.replace(/[_]/g, " ")}`}
													:{" "}
												</span>
												<span className="fontInherit Lexend">
													{it?.toLowerCase()?.includes("balance") ? (
														<>{nairaSign} </>
													) : (
														""
													)}
													{numberWithCommas(
														Number(ogdams?.data?.[it] || 0)?.toFixed(2)
													)}
													{!it?.toLowerCase()?.includes("balance") ? ` GB` : ""}
												</span>{" "}
											</p>
										))}
									{/* <p className="text-capitalize text2 text2New d-flex justify-content-between align-items-center px-3">
										<span>Balance: </span>
										<span className="fontInherit Lexend">
											{nairaSign}{" "}
											{ogdams?.data?.mainBalance
												? numberWithCommas(
														Number(ogdams?.data?.mainBalance)?.toFixed(2)
												  )
												: 0}
										</span>{" "}
									</p>
									<p className="text-capitalize text2 text2New d-flex justify-content-between align-items-center px-3">
										<span className="text-uppercase">mtn cg: </span>
										<span className="fontInherit Lexend">
											{ogdams?.data?.cgMtn
												? numberWithCommas(
														Number(ogdams?.data?.cgMtn)?.toFixed(0)
												  )
												: 0}
										</span>{" "}
									</p>
									<p className="text-capitalize text2 text2New d-flex justify-content-between align-items-center px-3">
										<span className="text-uppercase">mtn sme : </span>
										<span className="fontInherit Lexend">
											{ogdams?.data?.smeMtn
												? numberWithCommas(
														Number(ogdams?.data?.smeMtn)?.toFixed(0)
												  )
												: 0}
										</span>{" "}
									</p>
									<p className="text-capitalize text2 text2New d-flex justify-content-between align-items-center px-3">
										<span className="text-uppercase">9mobile cg: </span>
										<span className="fontInherit Lexend">
											{ogdams?.data?.cg9mobile
												? numberWithCommas(
														Number(ogdams?.data?.cg9mobile)?.toFixed(0)
												  )
												: 0}
										</span>{" "}
									</p>
									<p className="text-capitalize text2 text2New d-flex justify-content-between align-items-center px-3">
										<span className="text-uppercase">Glo cg: </span>
										<span className="fontInherit Lexend">
											{ogdams?.data?.cgGlo
												? numberWithCommas(
														Number(ogdams?.data?.cgGlo)?.toFixed(0)
												  )
												: 0}
										</span>{" "}
									</p>
									<p className="text-capitalize text2 text2New d-flex justify-content-between align-items-center px-3">
										<span className="text-uppercase">Airtel cg: </span>
										<span className="fontInherit Lexend">
											{ogdams?.data?.cgAirtel
												? numberWithCommas(
														Number(ogdams?.data?.cgAirtel)?.toFixed(0)
												  )
												: 0}
										</span>{" "}
									</p> */}
								</div>
								<div>
									{/* <p className="textColor2">{item?.user}</p> */}
									{/* <p className="text2 text2New">{item?.balance}</p> */}
								</div>
							</div>
							<NavigateElseWhere uri={"https://simhosting.ogdams.ng"} />
						</div>
					</div>
					{process.env.REACT_APP_NAME === "Honour World" ? (
						<>
							<div className="px-3 col-12 col-md-4">
								<div
									className="myCursor rounded10 miniHeight d-flex flex-column px-0 text-center"
									style={{
										background: "#7F61ED",
									}}>
									<div
										onClick={() => {
											navigate(`/${page}/rpi`);
										}}
										className="rounded10 h-100 py-3 py-md-4">
										<img
											src={image2}
											alt="Inventory"
											className="mx-auto img-fluid mb-3"
										/>
										<h3>
											<strong className="text-uppercase text2 text2New Lexend">
												RPI
											</strong>
										</h3>
										<div>
											<p className="text-capitalize text2 text2New d-flex justify-content-between align-items-center px-3">
												<span>Balance: </span>
												<span className="fontInherit Lexend">
													{nairaSign}{" "}
													{rpi?.data?.wallet_balance
														? numberWithCommas(
																Number(rpi?.data?.wallet_balance)?.toFixed(2)
														  )
														: 0}
												</span>{" "}
											</p>
											<p className="text-capitalize text2 text2New d-flex justify-content-between align-items-center px-3">
												<span>Bonus: </span>
												<span className="fontInherit Lexend">
													{nairaSign}{" "}
													{rpi?.data?.bonus_balance
														? numberWithCommas(
																Number(rpi?.data?.bonus_balance)?.toFixed(2)
														  )
														: 0}
												</span>{" "}
											</p>
										</div>
										<div>
											{/* <p className="textColor2">{item?.user}</p> */}
											{/* <p className="text2 text2New">{item?.balance}</p> */}
										</div>
									</div>
									<NavigateElseWhere uri={"https://www.rpidatang.com/login"} />
								</div>
							</div>
							<div className="px-3 col-12 col-md-4">
								<div
									className="myCursor rounded10 miniHeight d-flex flex-column px-0 text-center"
									style={{
										background: "#C20E9C",
									}}>
									<div
										onClick={() => {
											navigate(`/${page}/chosen`);
										}}
										className="rounded10 h-100 py-3 py-md-4">
										<img
											src={image3}
											alt="Inventory"
											className="mx-auto img-fluid mb-3"
										/>
										<h3>
											<strong className="text-uppercase text2 text2New Lexend">
												Chosen
											</strong>
										</h3>
										<div>
											{chosen?.data &&
												Object?.keys(chosen?.data)?.map((it, i) => (
													<p
														key={i}
														className="text-capitalize text2 text2New d-flex justify-content-between align-items-center px-3">
														<span className="tw-uppercase">
															{`${it?.replace(/[_]/g, " ")}`}:{" "}
														</span>
														<span className="fontInherit Lexend">
															{it?.toLowerCase()?.includes("balance") ? (
																<>{nairaSign} </>
															) : (
																""
															)}
															{numberWithCommas(
																Number(chosen?.data?.[it] || 0)?.toFixed(2)
															)}
															{!it?.toLowerCase()?.includes("balance")
																? ` GB`
																: ""}
														</span>{" "}
													</p>
												))}
										</div>
										<div>
											{/* <p className="textColor2">{item?.user}</p> */}
											{/* <p className="text2 text2New">{item?.balance}</p> */}
										</div>
									</div>
									<NavigateElseWhere uri={"https://thechosendata.com"} />
								</div>
							</div>
							<div className="px-3 col-12 col-md-4">
								<div
									className="myCursor rounded10 miniHeight d-flex flex-column px-0 text-center"
									style={{
										background: "#BB6BD9",
									}}>
									<div
										onClick={() => {
											navigate(`/${page}/mcd`);
										}}
										className="rounded10 h-100 py-3 py-md-4">
										<img
											src={image1}
											alt="Inventory"
											className="mx-auto img-fluid mb-3"
										/>
										<h3>
											<strong className="text-uppercase text2 text2New Lexend">
												MCD
											</strong>
										</h3>
										<div>
											<p className="text-capitalize text2 text2New d-flex justify-content-between align-items-center px-3">
												<span>Balance: </span>
												<span className="fontInherit Lexend">
													{nairaSign}{" "}
													{mcd?.data?.wallet
														? numberWithCommas(
																Number(mcd?.data?.wallet)?.toFixed(2)
														  )
														: 0}
												</span>{" "}
											</p>
											<p className="text-capitalize text2 text2New d-flex justify-content-between align-items-center px-3">
												<span>Commission: </span>
												<span className="fontInherit Lexend">
													{nairaSign}{" "}
													{mcd?.data?.commission
														? numberWithCommas(
																Number(mcd?.data?.commission)?.toFixed(2)
														  )
														: 0}
												</span>{" "}
											</p>
										</div>
										<div>
											{/* <p className="textColor2">{item?.user}</p> */}
											{/* <p className="text2 text2New">{item?.balance}</p> */}
										</div>
									</div>
									<NavigateElseWhere
										uri={"https://integration.mcd.5starcompany.com.ng"}
									/>
								</div>
							</div>
							<div className="px-3 col-12 col-md-4">
								<div
									className="myCursor rounded10 miniHeight d-flex flex-column px-0 text-center"
									style={{
										background: "#66EDE5",
									}}>
									<div
										onClick={() => {
											navigate(`/${page}/uzobest`);
										}}
										className="rounded10 h-100 py-3 py-md-4">
										<img
											src={image4}
											alt="Inventory"
											className="mx-auto img-fluid mb-3"
										/>
										<h3>
											<strong className="text-uppercase text2 text2New Lexend">
												Uzobest
											</strong>
										</h3>
										<div>
											<p className="text-capitalize text2 text2New d-flex justify-content-between align-items-center px-3">
												<span>Balance: </span>
												<span className="fontInherit Lexend">
													{nairaSign}{" "}
													{uzobest?.data?.balance
														? numberWithCommas(
																Number(uzobest?.data?.balance)?.toFixed(2)
														  )
														: 0}
												</span>{" "}
											</p>
										</div>
										<div>
											{/* <p className="textColor2">{item?.user}</p> */}
											{/* <p className="text2 text2New">{item?.balance}</p> */}
										</div>
									</div>
									<NavigateElseWhere uri={"https://uzobestgsm.ng"} />
								</div>
							</div>
						</>
					) : (
						<>
							<div className="px-3 col-12 col-md-4">
								<div
									className="myCursor rounded10 miniHeight d-flex flex-column px-0 text-center"
									style={{
										background: "#7F61ED",
									}}>
									<div
										onClick={() => {
											navigate(`/${page}/honourworld`);
										}}
										className="rounded10 h-100 py-3 py-md-4">
										<img
											src={image2}
											alt="Inventory"
											className="mx-auto img-fluid mb-3"
										/>
										<h3>
											<strong className="text-uppercase text2 text2New Lexend">
												Honour World
											</strong>
										</h3>
										<div>
											<p className="text-capitalize text2 text2New d-flex justify-content-between align-items-center px-3">
												<span>Balance: </span>
												<span className="fontInherit Lexend">
													{nairaSign}{" "}
													{honourworld?.data?.balance
														? numberWithCommas(
																Number(honourworld?.data?.balance)?.toFixed(2)
														  )
														: 0}
												</span>{" "}
											</p>
										</div>
										<div>
											{/* <p className="textColor2">{item?.user}</p> */}
											{/* <p className="text2 text2New">{item?.balance}</p> */}
										</div>
									</div>
									<NavigateElseWhere />
								</div>
							</div>
						</>
					)}
				</div>
			</Container>
		</div>
	);
};

export default Inventory;

export const AddNewFund = ({ isOpen, toggle }) => {
	let { returnErrors, biller, getBillerFunding, nairaSignNeutral } =
		useContext(GlobalState);
	let init = { amount: "" },
		{ id } = useParams(),
		[state, setState] = useState(init),
		[submit, setSubmit] = useState(false),
		[loading, setLoading] = useState(false);

	useEffect(() => {
		if (id && biller?.data) {
			biller?.data?.map(
				item =>
					item?.billerId === id && setState({ ...state, biller: item?._id })
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, biller?.data]);

	useEffect(() => {
		if (submit && biller?.isFunded) {
			toggle();
			setSubmit(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [biller?.isFunded, submit]);

	let handleSubmit = async () => {
		setLoading(true);
		console.log({ state });
		await getBillerFunding(state);
		setLoading(false);
		setSubmit(true);
	};

	return (
		<>
			<ModalComponents
				isOpen={isOpen}
				toggle={toggle}
				title={`Add amount funded on ${id}`}>
				<div>
					<div className="mb-4">
						<label htmlFor="value">Amount</label>
						{/* <input
							type={"number"}
							placeholder="500"
							className="form-control py-3"
							value={state?.amount}
							onChange={e => setState({ ...state, amount: e.target.value })}
							min={0}
						/> */}
						<NumericFormat
							prefix={`${nairaSignNeutral} `}
							className="form-control py-3"
							value={state?.amount}
							placeholder="500"
							displayType="input"
							thousandSeparator={true}
							onValueChange={val =>
								setState({ ...state, amount: val?.floatValue })
							}
							min={0}
							inputMode="decimal"
							renderText={(value, props) => <span {...props}>{value}</span>}
							allowNegative={false}
						/>
						<Buttons
							title={"proceed"}
							css="btn-primary1 text-capitalize py-3 w-50 my-4 mx-auto"
							width={"w-50"}
							loading={loading}
							style={{ borderRadius: "30px" }}
							onClick={async () => {
								if (Number(state?.amount) < 1)
									return returnErrors({
										error: [
											{
												msg: `Amount cannot be less than ${nairaSignNeutral} 1`,
												param: "amount",
											},
										],
									});
								await handleSubmit();
							}}
						/>
					</div>
				</div>
			</ModalComponents>
		</>
	);
};

export const BillerFundingHistory = () => {
	let { biller, numberWithCommas, returnErrors, nairaSign, getBillerFunding } =
		useContext(GlobalState);
	let { id } = useParams(),
		[state, setState] = useState(null),
		[total, setTotal] = useState(0),
		[total2, setTotal2] = useState(0);

	useEffect(() => {
		getBillerFunding();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let getTotal = async () => {
			try {
				let res = await axios.post(`/api/v2/transactions/manage-transactions`, {
					provider: id,
				});

				let total = res?.data?.data?.monthly?.reduce(
					(ac, i) => (ac += Number(i?.dataTotal)),
					0
				);
				setTotal(total);
			} catch (err) {
				if (err) console.log(err.response?.data?.error, { err });
				if (err?.response?.status === 429 || err?.response?.status === 405)
					toast.error(err?.response?.data ? err?.response?.data : err?.message);
				let error = err.response?.data?.error;
				if (error) {
					returnErrors({ error, status: err?.response?.status });
				}
			}
		};
		getTotal();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	useEffect(() => {
		if (id && biller?.funding) {
			let filterItem = biller?.funding?.filter(item => item?.billerId === id);
			setState(filterItem);

			setTotal2(filterItem?.reduce((ac, i) => (ac += Number(i?.amount)), 0));
		}
	}, [id, biller?.funding]);

	if (!state) return;
	return (
		<div className="bg-white aboutScreen">
			<div className="py-3 py-md-5">
				<div className="bland row mx-0 py-3 px-0 text-capitalize Lexend fw-bold">
					<div className="col textTrunc Lexend">S/N</div>
					<div className="col textTrunc Lexend">User</div>
					<div className="col textTrunc Lexend">Amount</div>
					<div className="col textTrunc Lexend">Date&time</div>
				</div>
				{state?.length === 0 ? (
					<EmptyComponent subtitle={`${id} funding is empty`} />
				) : (
					<>
						{state?.map((it, i) => (
							<div key={i} className="row mx-0 py-3 border-bottom myCursor">
								<div className="col my-auto text-capitalize fontReduce2 textTrunc py-3 py-md-4">
									{i + 1}
								</div>
								<div className="col my-auto text-capitalize fontReduce2 textTrunc py-3 py-md-4">
									{it?.user?.firstName} {it?.user?.lastName}
								</div>
								<div className="col my-autofontReduce2 textTrunc py-3 py-md-4 textTrunc4">
									{nairaSign} {numberWithCommas(Number(it?.amount).toFixed(2))}
								</div>
								<div className="col my-autofontReduce2 textTrunc py-3 py-md-4 textTrunc4">
									{moment(it?.createdAt).format("DD/MM/YYYY HH:mm A")}
								</div>
							</div>
						))}
						<div className="py-3">
							<h3 className="Lexend row mx-0 border-bottom py-3">
								<span className="col fontInherit Lexend">Total funding </span>
								<span className="col fontInherit Lexend">
									{nairaSign} {numberWithCommas(Number(total2).toFixed(2))}
								</span>
							</h3>
							{total && (
								<h3 className="Lexend row mx-0 border-bottom py-3">
									<span className="col fontInherit Lexend">
										Total expenses{" "}
									</span>
									<span className="col fontInherit Lexend">
										{" "}
										{nairaSign} {numberWithCommas(Number(total).toFixed(2))}
									</span>
								</h3>
							)}
							{total && total2 && (
								<h3 className="Lexend row mx-0 border-bottom py-3">
									<span className="col fontInherit Lexend">Balance </span>
									<span className="col fontInherit Lexend">
										{nairaSign}{" "}
										{numberWithCommas(Number(total2 - total).toFixed(2))}
									</span>
								</h3>
							)}
						</div>
					</>
				)}
			</div>
		</div>
	);
};
