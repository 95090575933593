import React, { useContext, useEffect, useState, useRef } from "react";
import { GlobalState } from "../Data/Context";
import { Buttons } from "../Utils";
import { useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { returnErrors } from "../Data/Reducer/ErrorReducer";
import { REARRANGE_SERVICEICON } from "../Data/Actions/ActionTypes";

const LoadMore = ({ handleLoadMore, next, loading }) => {
	return (
		<>
			{!next ? (
				""
			) : (
				<Buttons
					onClick={handleLoadMore}
					title={loading ? "loading..." : "load more"}
					css={"btn btn-primary1 text-uppercase my-4 mx-auto py-3"}
					width="w-50 w50"
					loading={loading}
				/>
			)}
		</>
	);
};

export default LoadMore;

export const BottomTab = ({ paginate, state }) => {
	return (
		<>
			{state?.length > 0 && (
				<div className="p-3 fontReduce">
					Showing 1 to {state?.length}
					{""}
					{state?.length !== paginate?.result
						? `[${paginate?.result}]`
						: ""} of {paginate?.total} entries
				</div>
			)}
		</>
	);
};

export const SearchComponent = ({ reload, handleSubmit }) => {
	const { getFullReload } = useContext(GlobalState);
	let [search, setSearch] = useState("");

	useEffect(() => {
		if (search) {
			document.getElementById("Search").addEventListener("search", () => {
				if (reload) reload();
				getFullReload();
			});
			let submit = async () => {
				if (!search) return;

				if (handleSubmit) await handleSubmit(null, search);
			};
			submit();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search]);

	return (
		<div className="w-50 w50 mb-3">
			<input
				type="search"
				name="search"
				id="Search"
				className="form-control w-100 py-3 borderColor2"
				placeholder="type here to search"
				value={search}
				onChange={e => setSearch(e.target.value)}
			/>
		</div>
	);
};

export const ReArrangeFiles = ({ state, setRearrange }) => {
	let [dataSet, setDataSet] = useState(state);

	const dragItem = useRef();
	const dragOverItem = useRef();

	const dragStart = (e, position) => {
		dragItem.current = position;
		console.log(e.target.innerHTML);
	};

	const dragEnter = (e, position) => {
		dragOverItem.current = position;
		console.log(e.target.innerHTML);
	};

	const drop = () => {
		const copyListItems = [...dataSet];
		const dragItemContent = copyListItems[dragItem.current];
		copyListItems.splice(dragItem.current, 1);
		copyListItems.splice(dragOverItem.current, 0, dragItemContent);
		dragItem.current = null;
		dragOverItem.current = null;
		setDataSet(copyListItems);
	};

	let dispatch = useDispatch(),
		[loading, setLoading] = useState(false);

	let handleRearrangement = async e => {
		e?.preventDefault();
		try {
			setLoading(true);
			var res = await axios.post(`/api/v2/serviceicon/rearrange`, {
				data: dataSet?.map(item => item?._id),
			});

			dispatch({
				type: REARRANGE_SERVICEICON,
				payload: res.data?.data,
			});
			setLoading(false);
			setRearrange(false);
			toast.success(res.data.msg, { autoClose: 5000 });
		} catch (err) {
			setLoading(false);
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
			let error = err.response?.data?.data;
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			}
		}
	};

	return (
		<>
			<p className="tw-text-2xl mb-4">
				Drag and Drop the list to rearrange them
			</p>
			<table className="table bg-white">
				<tbody>
					{dataSet?.map((item, index) => (
						<tr
							className="tw-align-middle py-3 myCursor"
							draggable
							onDragStart={dragStart ? e => dragStart(e, index) : () => {}}
							onDragEnter={dragEnter ? e => dragEnter(e, index) : () => {}}
							onDragEnd={drop ? drop : () => {}}>
							<td className="text-capitalize">{index + 1}</td>
							<td className="text-capitalize">{item?.title}</td>
						</tr>
					))}
				</tbody>
			</table>
			<div className="d-flex justify-content-end">
				<Buttons
					width={"auto"}
					onClick={handleRearrangement}
					loading={loading}
					title={`Update Rearrangement`}
					css="btn btn-success2 text-uppercase py-3"
				/>
			</div>
		</>
	);
};
