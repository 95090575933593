import React, { useContext, useEffect, useState } from "react";
import { Container } from "reactstrap";
import { GlobalState } from "../../Data/Context";
import { useNavigate } from "react-router-dom";

const ManageWallet = () => {
  let {
      setStateName,
      numberWithCommas,
      wallet,
      nairaSign,
      manager,
      marketer,
      developer,
      accountant,
      superadmin,
      user,
      reseller,
      // topuser,
      agent,
      getAllUserTransactionAmount,
      allUsers,
    } = useContext(GlobalState),
    navigate = useNavigate(),
    { auth, permission } = useContext(GlobalState),
    [total, setTotal] = useState({
      wallet: "",
      bonus: "",
      referral: "",
      commission: "",
    });

  useEffect(() => {
    if (auth?.user?.privilege !== "superadmin") {
      let findTitle = "viewgeneralwalletdetails";

      let findPermit = permission?.data?.find(
        (item) =>
          item?.slug === findTitle && item?.user?.includes(auth?.user?._id)
      );
      if (!findPermit) {
        navigate(-1);
      }
    }
  }, [auth?.user, permission?.data, navigate]);

  useEffect(() => {
    let userReferral = allUsers?.details?.user?.referral || user?.referral || 0;
    let resellerReferral =
      allUsers?.details?.reseller?.referral || reseller?.referral || 0;
    let agentReferral =
      allUsers?.details?.agent?.referral || agent?.referral || 0;
    let managerReferral =
      allUsers?.details?.manager?.referral || manager?.referral || 0;
    let marketerReferral =
      allUsers?.details?.marketer?.referral || marketer?.referral || 0;
    let developerReferral =
      allUsers?.details?.developer?.referral || developer?.referral || 0;
    let accountantReferral =
      allUsers?.details?.accountant?.referral || accountant?.referral || 0;
    let superadminReferral =
      allUsers?.details?.superadmin?.referral || superadmin?.referral || 0;
    // Referral
    let userAvailable = allUsers?.details?.user?.wallet || user?.wallet || 0;
    let resellerAvailable =
      allUsers?.details?.reseller?.wallet || reseller?.wallet || 0;
    let agentAvailable = allUsers?.details?.agent?.wallet || agent?.wallet || 0;
    let managerAvailable =
      allUsers?.details?.manager?.wallet || manager?.wallet || 0;
    let marketerAvailable =
      allUsers?.details?.marketer?.wallet || marketer?.wallet || 0;
    let developerAvailable =
      allUsers?.details?.developer?.wallet || developer?.wallet || 0;
    let accountantAvailable =
      allUsers?.details?.accountant?.wallet || accountant?.wallet || 0;
    let superadminAvailable =
      allUsers?.details?.superadmin?.wallet || superadmin?.wallet || 0;
    // Commission
    let userCommission =
      allUsers?.details?.user?.commission || user?.commission || 0;
    let resellerCommission =
      allUsers?.details?.reseller?.commission || reseller?.commission || 0;
    let agentCommission =
      allUsers?.details?.agent?.commission || agent?.commission || 0;
    let managerCommission =
      allUsers?.details?.manager?.commission || manager?.commission || 0;
    let marketerCommission =
      allUsers?.details?.marketer?.commission || marketer?.commission || 0;
    let developerCommission =
      allUsers?.details?.developer?.commission || developer?.commission || 0;
    let accountantCommission =
      allUsers?.details?.accountant?.commission || accountant?.commission || 0;
    let superadminCommission =
      allUsers?.details?.superadmin?.commission || superadmin?.commission || 0;
    // Bonus
    let userBonus = allUsers?.details?.user?.bonus || user?.bonus || 0;
    let resellerBonus =
      allUsers?.details?.reseller?.bonus || reseller?.bonus || 0;
    let agentBonus = allUsers?.details?.agent?.bonus || agent?.bonus || 0;
    let managerBonus = allUsers?.details?.manager?.bonus || manager?.bonus || 0;
    let marketerBonus =
      allUsers?.details?.marketer?.bonus || marketer?.bonus || 0;
    let developerBonus =
      allUsers?.details?.developer?.bonus || developer?.bonus || 0;
    let accountantBonus =
      allUsers?.details?.accountant?.bonus || accountant?.bonus || 0;
    let superadminBonus =
      allUsers?.details?.superadmin?.bonus || superadmin?.bonus || 0;
    setTotal({
      ...total,
      bonus:
        superadminBonus +
        accountantBonus +
        developerBonus +
        marketerBonus +
        agentBonus +
        resellerBonus +
        userBonus +
        managerBonus,
      commission:
        superadminCommission +
        accountantCommission +
        developerCommission +
        marketerCommission +
        agentCommission +
        resellerCommission +
        userCommission +
        managerCommission,
      wallet:
        superadminAvailable +
        accountantAvailable +
        developerAvailable +
        marketerAvailable +
        agentAvailable +
        resellerAvailable +
        userAvailable +
        managerAvailable,
      referral:
        superadminReferral +
        accountantReferral +
        developerReferral +
        marketerReferral +
        agentReferral +
        resellerReferral +
        userReferral +
        managerReferral,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allUsers,
    agent,
    user,
    manager,
    marketer,
    developer,
    accountant,
    superadmin,
    reseller,
  ]);

  useEffect(() => {
    setStateName("General Wallet");
    getAllUserTransactionAmount("user", "notransact");
    getAllUserTransactionAmount("reseller", "notransact");
    getAllUserTransactionAmount("agent", "notransact");
    getAllUserTransactionAmount("manager", "notransact");
    getAllUserTransactionAmount("superadmin", "notransact");
    getAllUserTransactionAmount("marketer", "notransact");
    getAllUserTransactionAmount("accountant", "notransact");
    getAllUserTransactionAmount("developer", "notransact");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log({ total });
  return (
    <div className="bg-white aboutScreen">
      <Container className="py-5">
        <div className="tw-w-11/12 tw-mx-auto tw-grid tw-grid-cols-3 tw-gap-4 tw-gap-y-8">
          <div className="">
            <div
              style={{
                border: "1px solid #A865B5",
              }}
              className="tw-bg-white tw-shadow-sm tw-px-4 tw-rounded-xl tw-py-8"
            >
              <div className="">
                <div className="">
                  <h3 className="tw-text-2xl tw-text-[#1b1b1b] tw-font-semibold">
                    Total Wallet Balance
                  </h3>
                  <h1 className={`tw-text-xl tw-text-black tw-font-medium`}>
                    {nairaSign}{" "}
                    {numberWithCommas(
                      Number(
                        wallet?.wallet_details?.general?.wallet ||
                          total?.wallet ||
                          0
                      ).toFixed(2)
                    )}
                  </h1>
                  <h3 className="tw-text-2xl tw-text-[#1b1b1b] tw-font-semibold">
                    Total Commission Balance
                  </h3>
                  <h1 className={`tw-text-xl tw-text-black tw-font-medium`}>
                    {nairaSign}{" "}
                    {numberWithCommas(
                      Number(
                        wallet?.wallet_details?.general?.commission ||
                          total?.commission ||
                          0
                      ).toFixed(2)
                    )}
                  </h1>
                  <h3 className="tw-text-2xl tw-text-[#1b1b1b] tw-font-semibold">
                    Total Bonus Balance
                  </h3>
                  <h1 className={`tw-text-xl tw-text-black tw-font-medium`}>
                    {nairaSign}{" "}
                    {numberWithCommas(
                      Number(
                        wallet?.wallet_details?.general?.bonus ||
                          total?.bonus ||
                          0
                      ).toFixed(2)
                    )}
                  </h1>
                  <h3 className="tw-text-2xl tw-text-[#1b1b1b] tw-font-semibold">
                    Total Referral Bonus Balance
                  </h3>
                  <h1 className={`tw-text-xl tw-text-black tw-font-medium`}>
                    {nairaSign}{" "}
                    {numberWithCommas(
                      Number(
                        wallet?.wallet_details?.general?.referral ||
                          total?.referral ||
                          0
                      ).toFixed(2)
                    )}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <EachSectorBalance
            border={"#5dbb63"}
            sector={user}
            sectorName={"User"}
          />
          <EachSectorBalance
            border={"#E97451"}
            sector={reseller}
            sectorName={"Reseller"}
          />
          <EachSectorBalance
            border={"#D70040"}
            sector={agent}
            sectorName={"Agent"}
          />
          {/* <EachSectorBalance sector={topuser} sectorName={"Topuser"} /> */}
          <EachSectorBalance
            border={"#ffef00"}
            sector={manager}
            sectorName={"Manager"}
          />
          <EachSectorBalance
            border={"#1e90ff"}
            sector={accountant}
            sectorName={"Accountant"}
          />
          <EachSectorBalance
            border={"#5dbb63"}
            sector={marketer}
            sectorName={"Marketer"}
          />
          <EachSectorBalance
            border={"#E97451"}
            sector={developer}
            sectorName={"Developer"}
          />
          <EachSectorBalance
            border={"#A865B5"}
            sector={superadmin}
            sectorName={"Superadmin"}
          />
        </div>
      </Container>
    </div>
  );
};

export default ManageWallet;

const EachSectorBalance = ({ sector, sectorName, border }) => {
  let { nairaSign, numberWithCommas, allUsers } = useContext(GlobalState);
  return (
    <div className="">
      <div
        style={{
          border: `0.5px solid ${border}`,
        }}
        className="tw-bg-white tw-rounded-xl tw-border-opacity-50 tw-shadow-sm tw-px-4 tw-py-8"
      >
        <div className="">
          <div className="">
            <h3 className="tw-text-2xl tw-text-[#1b1b1b] tw-font-semibold">
              {[sectorName]} Wallet Balance
            </h3>
            <h1 className={`tw-text-xl tw-text-black tw-font-medium`}>
              {nairaSign}{" "}
              {numberWithCommas(
                Number(
                  allUsers?.details?.[sectorName?.toLowerCase()]?.wallet ||
                    sector?.wallet ||
                    0
                ).toFixed(2)
              )}
            </h1>
            <h3 className="tw-text-2xl tw-text-[#1b1b1b] tw-font-semibold">
              {[sectorName]} Commission Balance
            </h3>
            <h1 className={`tw-text-xl tw-text-black tw-font-medium`}>
              {nairaSign}{" "}
              {numberWithCommas(
                Number(
                  allUsers?.details?.[sectorName?.toLowerCase()]?.commission ||
                    sector?.commission ||
                    0
                ).toFixed(2)
              )}
            </h1>
            <h3 className="tw-text-2xl tw-text-[#1b1b1b] tw-font-semibold">
              {[sectorName]} Bonus Balance
            </h3>
            <h1 className={`tw-text-xl tw-text-black tw-font-medium`}>
              {nairaSign}{" "}
              {numberWithCommas(
                Number(
                  allUsers?.details?.[sectorName?.toLowerCase()]?.bonus ||
                    sector?.bonus ||
                    0
                ).toFixed(2)
              )}
            </h1>
            <h3 className="tw-text-2xl tw-text-[#1b1b1b] tw-font-semibold">
              {[sectorName]} Referral Bonus Balance
            </h3>
            <h1 className={`tw-text-xl tw-text-black tw-font-medium`}>
              {nairaSign}{" "}
              {numberWithCommas(
                Number(
                  allUsers?.details?.[sectorName?.toLowerCase()]?.referral ||
                    sector?.referral ||
                    0
                ).toFixed(2)
              )}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};
