import * as XLSX from "xlsx";

const exportTableToExcel = (tableId, fileName) => {
  const table = document.getElementById(tableId);
  if (!table) return;

  // Clone the table to manipulate it without affecting the DOM
  const clonedTable = table.cloneNode(true);

  // Check and add the "S/N" column if missing
  const headerRow = clonedTable.querySelector("thead tr");
  const headerCells = Array.from(headerRow.children);
  let hasSerialNumber = headerCells.some((th) => th.innerText === "S/N");

  if (!hasSerialNumber) {
    // Add "S/N" as the first header
    const snHeader = document.createElement("th");
    snHeader.innerText = "S/N";
    headerRow.insertBefore(snHeader, headerRow.firstChild);

    // Add "S/N" data to each row in the tbody
    const bodyRows = clonedTable.querySelectorAll("tbody tr");
    bodyRows.forEach((row, index) => {
      const snCell = document.createElement("td");
      snCell.innerText = index + 1; // Add row index as the S/N value
      row.insertBefore(snCell, row.firstChild);
    });
  }

  // Remove "Action" column if present
  const actionIndex = headerCells.findIndex((th) => th.innerText === "Action");
  if (actionIndex !== -1) {
    // Remove the "Action" header
    headerRow.deleteCell(actionIndex + 1);

    // Remove the "Action" data from each row
    const bodyRows = clonedTable.querySelectorAll("tbody tr");
    bodyRows.forEach((row) => {
      row.deleteCell(actionIndex + 1);
    });
  }

  // Convert the modified table to an Excel workbook
  const workbook = XLSX.utils.table_to_book(clonedTable, { sheet: "Sheet1" });
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};

export default exportTableToExcel;

export const exportDivTableToExcel = (headers, data, fileName) => {
  // Check if "S/N" exists in the headers, if not, add it as the first header
  const hasSerialNumber = headers.some((header) => header.title === "S/N");
  let updatedHeaders = [...headers];
  if (!hasSerialNumber) {
    updatedHeaders = [{ title: "S/N", field: "sn" }, ...headers];
  }

  // Check if "Action" exists, and remove it
  updatedHeaders = updatedHeaders.filter((header) => header.title !== "Action");

  // Prepare worksheet data
  const worksheetData = [updatedHeaders.map((header) => header.title)];

  // Add data rows, including serial numbers if necessary
  data.forEach((item, index) => {
    const row = updatedHeaders.map((header) => {
      if (header.field === "sn") return index + 1; // Add "S/N" value if needed
      return item[header.field];
    });
    worksheetData.push(row);
  });

  // Create the worksheet and workbook
  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Save the workbook as an Excel file
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};

export const exportDataListTableToExcel = (networkData) => {
  const workbook = XLSX.utils.book_new(); // Create a new workbook

  // Iterate through each network
  networkData.forEach((network) => {
    const sheetData = [];

    // Add network name as a header (h5 equivalent)
    sheetData.push([`Network: ${network.name}`]);

    network.subCategories.forEach((category) => {
      // Add subcategory name as a sub-header (h6 equivalent)
      sheetData.push([`Subcategory: ${category.name}`]);

      // Add table headers
      sheetData.push([
        "S/N",
        "Network",
        "Category",
        "Price",
        "Reseller",
        "Agent",
        "Validity",
        "Allowance",
      ]);

      category.items.forEach((item, index) => {
        sheetData.push([
          index + 1,
          item.network,
          item.category.name,
          item.price ? `₦ ${Number(item.price).toFixed(2)}` : "N/A",
          item.resellerPrice
            ? `₦ ${Number(item.resellerPrice).toFixed(2)}`
            : "0",
          item.agentPrice ? `₦ ${Number(item.agentPrice).toFixed(2)}` : "0",
          item.validity,
          `${item.allowance} ${item.size}`,
        ]);
      });

      // Add an empty row for spacing between subcategories
      sheetData.push([]);
    });

    // Create a new worksheet for the network
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, network.name);
  });

  // Export the workbook as an Excel file
  XLSX.writeFile(workbook, "dataList.xlsx");
};

export const exportCableListDataToExcel = (cables) => {
  const workbook = XLSX.utils.book_new();
  const worksheetData = [];

  // Cable List section
  worksheetData.push(["Cable List"]); // Section title
  worksheetData.push(["S/N", "Name"]); // Headers

  cables?.cable_direct?.forEach((item, i) => {
    worksheetData.push([i + 1, item?.name]);
  });

  // DStv List section
  worksheetData.push([]); // Empty row for spacing
  worksheetData.push(["DStv List"]); // Section title
  worksheetData.push(["S/N", "Name", "Price"]); // Headers

  cables?.cable_package?.dstv?.forEach((item, i) => {
    worksheetData.push([
      i + 1,
      item?.package || item?.name,
      item?.price || item?.plan_amount
        ? `₦ ${Number(item?.price || item?.plan_amount).toFixed(2)}`
        : "",
    ]);
  });

  // GOtv List section
  worksheetData.push([]); // Empty row for spacing
  worksheetData.push(["GOtv List"]); // Section title
  worksheetData.push(["S/N", "Name", "Price"]); // Headers

  cables?.cable_package?.gotv?.forEach((item, i) => {
    worksheetData.push([
      i + 1,
      item?.package || item?.name,
      item?.price || item?.plan_amount
        ? `₦ ${Number(item?.price || item?.plan_amount).toFixed(2)}`
        : "",
    ]);
  });

  // Startimes List section
  worksheetData.push([]); // Empty row for spacing
  worksheetData.push(["Startimes List"]); // Section title
  worksheetData.push(["S/N", "Name", "Price"]); // Headers

  cables?.cable_package?.startimes?.forEach((item, i) => {
    worksheetData.push([
      i + 1,
      item?.package || item?.name,
      item?.price || item?.plan_amount
        ? `₦ ${Number(item?.price || item?.plan_amount).toFixed(2)}`
        : "",
    ]);
  });

  // Create worksheet and export
  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  XLSX.utils.book_append_sheet(workbook, worksheet, "Cable Data");

  XLSX.writeFile(workbook, "cableList_data.xlsx");
};
