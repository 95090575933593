import React, { useContext, useEffect } from "react";
import { GlobalState } from "../../../Data/Context";
import { Container } from "reactstrap";
import { useNavigate } from "react-router-dom";

const Commissions = () => {
	let { setStateName } = useContext(GlobalState);
	useEffect(() => {
		setStateName("Manage Wallet");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	let navigate = useNavigate(),
		controlsTab = [
			{
				name: "Main Commision",
				type: "link",
				link: `general`,
				color: "linear-gradient(90deg, #DE0DE2 16.14%, #0E102D 101.45%)",
				textColor: "white",
			},
			{
				name: "Manual Commission",
				type: "link",
				link: `manual`,
				color: "linear-gradient(90deg, #F45F83 16.14%, #9E1A2A 101.45%)",
				textColor: "white",
			},
			{
				name: "Commission Stat",
				type: "link",
				link: `stat`,
				color: "linear-gradient(96.86deg, #F2E553 18.88%, #FF9900 125.77%)",
			},
		];

	return (
		<div className="bg-white aboutScreen tw-py-10">
			<Container>
				<div className="tw-grid tw-grid-cols-4 tw-w-5/6 tw-mx-auto tw-gap-8">
					{controlsTab?.map((item, i) => (
						<div
							className="tw-bg-white tw-flex tw-justify-center tw-items-center tw-px-3 tw-capitalize tw-shadow-xl tw-h-32"
							key={i}
							onClick={() => {
								if (item?.type === "link") {
									navigate(item?.link);
								} else if (item?.type === "button") {
									// if (item?.link === "debit") toggle();
								}
							}}>
							<span
								className=""
								// style={{
								//   background: item?.color,
								//   borderRadius: "30px",
								//   color: item?.textColor ? item?.textColor : "#000",
								// }}
							>
								<span className="tw-text-xl tw-text-[#1b1b1b] tw-cursor-pointer tw-font-semiboold">
									{item?.name}
								</span>
							</span>
						</div>
					))}
				</div>
			</Container>
		</div>
	);
};

export default Commissions;
