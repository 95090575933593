import {
	ADD_AUTO_BUY,
	ADD_AUTO_BUY_FAIL,
	DELETE_AUTO_BUY,
	GET_AUTO_BUY,
	GET_AUTO_BUY_FAIL,
	LOGOUT,
	SEARCH_AUTO_BUY,
	SEARCH_AUTO_BUY_FAIL,
	SEARCH_AUTO_BUY_LOADING,
	SEARCH_RELOAD,
	UPDATE_AUTO_BUY,
} from "../Actions/ActionTypes";
import { DeleteData, EditData } from "./AuthReducer";

const initialState3 = {
	isLoading: false,
	data: [],
	isAdded: false,
	isDeleted: false,
	paginate: null,
	isFound: null,
	searchLoading: null,
	mainSearch: [],
	search: "",
	search_paginate: null,
};

export const AutoBuyReducer = (state = initialState3, action) => {
	const { type, payload } = action;
	let data = payload?.data ? payload?.data : payload;
	switch (type) {
		case SEARCH_AUTO_BUY:
			return {
				...state,
				isFound: true,
				searchLoading: false,
				mainSearch: action?.search === state?.search ? data : state?.mainSearch,
				search_paginate:
					action?.search === state?.search
						? payload?.paginate
						: state?.search_paginate,
			};
		case SEARCH_AUTO_BUY_FAIL:
			return {
				...state,
				searchLoading: false,
				mainSearch: state.mainSearch,
				search_paginate: state.search_paginate,
			};
		case SEARCH_AUTO_BUY_LOADING:
			return {
				...state,
				search: action.search,
				searchLoading: true,
			};
		case SEARCH_RELOAD:
			return {
				...state,
				isFound: false,
				searchLoading: false,
			};
		case ADD_AUTO_BUY:
			return {
				...state,
				isAdded: true,
				data: [data, ...state.data],
				paginate: {
					...state?.paginate,
					result: state?.paginate?.result + 1,
					total: state?.paginate?.total + 1,
				},
			};
		case ADD_AUTO_BUY_FAIL:
			return {
				...state,
				isAdded: false,
				isDeleted: false,
				isUpdated: false,
			};
		case UPDATE_AUTO_BUY:
			return {
				...state,
				isUpdated: true,
				data: EditData(state?.data, data),
			};
		case GET_AUTO_BUY:
			return {
				...state,
				isLoading: false,
				data: payload?.data,
				paginate: payload?.paginate,
			};
		case GET_AUTO_BUY_FAIL:
			return {
				...state,
				isLoading: false,
			};
		case DELETE_AUTO_BUY:
			return {
				...state,
				isDeleted: true,
				data: DeleteData(state?.data, data),
			};
		case LOGOUT:
			return initialState3;
		default:
			return state;
	}
};
